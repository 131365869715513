import React, { useEffect, useReducer } from "react";
import axios from "axios";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import _ from "lodash";

import "./CreateNotification.scss";
import TFBDialog from "../../../../components/design-system/Dialog/TFBDialog";
import TFBSelect from "../../../../components/design-system/TFBSelect/TFBSelect";
import TFBButton from "../../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../../components/design-system/Icon/TFBIcon";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TFBTableCell } from "../../../../components/reusable/useStyles";
import TFBInput from "../../../../components/design-system/Input/TFBInput";
import TFBCheckbox from "../../../../components/design-system/Checkbox/TFBCheckbox";
import {
  showNotification,
  useAxiosPost,
} from "../../../../components/customHooks/useAxiosPost";
import useAxiosGet from "../../../../components/customHooks/useAxiosGet";
import CircularLoader from "../../../../components/reusable/Loader";
import TFBPlaceholder from "../../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";

const CreateNotification = ({
  token,
  currentUser,
  languagePack,
  refreshMobileUsers,
  openDialog,
  closePopup,
}) => {
  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      groupsOptions: null,
      selectedGroups: null,
      users: [],
      fullUsers: [],
      isAllChecked: false,
      step2: false,
      notificationMessage: "",
    }
  );

  const maxMessageLength = 120;

  const {
    data: teamsData,
    loading: isTeamsLoading,
    refetch: refetchTeams,
  } = useAxiosGet(`finances/get_teams?token=${token}`);

  console.log(state.selectedGroups);
  console.log(state.groupsOptions);
  const {
    data: usersData,
    loading: isUsersLoading,
    refetch: refetchUsers,
  } = useAxiosGet(
    `notification/get_external_users_by_teams?${
      state.selectedGroups?.length > 0
        ? _.some(state.selectedGroups, { value: "<SELECT_ALL>" })
          ? `id_teams=${_.map(
              _.filter(
                state.groupsOptionsCopy,
                (e) => e.value !== "<SELECT_ALL>"
              ),
              "value"
            ).join("_")}&`
          : `id_teams=${_.map(state.selectedGroups, "value").join("_")}&`
        : ``
    }id_club=${currentUser.id_club}&id_user=${
      currentUser.id_user
    }&token=${token}`,
    true
  );

  const { postData: sendNotificationPost } = useAxiosPost(
    "notification/generate_notification_from_admin"
  );

  useEffect(() => {
    if (teamsData?.teams) {
      updateState({
        groupsOptions: [
          { label: languagePack.all_groups, value: "<SELECT_ALL>" },
        ].concat(teamsData.teams),
        groupsOptionsCopy: [
          { label: languagePack.all_groups, value: "<SELECT_ALL>" },
        ].concat(teamsData.teams),
      });
      // if (_.size(teamsData.teams) > 0) {
      //   updateState({
      //     selectedGroups: [{ label: languagePack.all_groups, value: "00" }],
      //   });
      // }
    }
  }, [teamsData]);

  useEffect(() => {
    if (state.selectedGroups === null) {
      updateState({ users: [] });
    } else {
      refetchUsers();
    }
  }, [state.selectedGroups, refetchUsers]);

  useEffect(() => {
    if (_.some(state.selectedGroups, { value: "<SELECT_ALL>" })) {
      updateState({
        groupsOptions: [],
      });
    } else {
      updateState({
        groupsOptions: [
          { label: languagePack.all_groups, value: "<SELECT_ALL>" },
        ].concat(teamsData?.teams),
      });
    }
  }, [teamsData, state.selectedGroups, languagePack]);

  useEffect(() => {
    if (usersData?.list) {
      updateState({
        users: _.map(usersData.list, (e) => ({ ...e, checked: false })),
      });
    }
  }, [usersData]);

  useEffect(() => {
    let allUsers = [...state.users];
    const allChecked = allUsers.every((user) => user.checked);
    updateState({ isAllChecked: allChecked });
  }, [state.users]);

  // =========== HANDLERS =========================
  // ==============================================

  const handleHeaderCheck = (e) => {
    let allUsers = [...state.users];
    let allChecked = e.target.checked ? true : false;

    allUsers = allUsers.map((user) => {
      return {
        ...user,
        checked: allChecked,
      };
    });

    updateState({ users: allUsers });
    updateState({ isAllChecked: allChecked });
  };

  const handleRowCheck = (index) => {
    let allUsers = [...state.users];
    allUsers[index].checked = !allUsers[index].checked;

    const allChecked = allUsers.every((user) => user.checked);

    updateState({ isAllChecked: allChecked ? true : false });
    updateState({ users: allUsers });
  };

  const renderBtn = (position, onClick) => {
    return (
      <div className="action-buttons-add-notification">
        <TFBButton
          onClick={onClick}
          style={{ position: "sticky" }}
          endIcon={true}
          color={position == "left" ? "darkGray" : "green"}
          renderIcon={() => (
            <TFBIcon name={position == "left" ? "cancel" : "check"} />
          )}
          prettier-ignore
          disabled={
            position == "right" && !state.users.some((user) => user.checked)
          }
        >
          {position == "left" ? languagePack.Cancel : languagePack.next_step}
        </TFBButton>
      </div>
    );
  };

  const renderBtnSecondStep = (position, onClick) => {
    return (
      <div className="action-buttons-add-notification">
        <TFBButton
          onClick={onClick}
          endIcon={true}
          color={position == "left" ? "darkGray" : "green"}
          renderIcon={() => (
            <TFBIcon name={position == "left" ? "arrow-left" : "check"} />
          )}
          prettier-ignore
          disabled={
            position == "right" &&
            !state.users.some(
              (user) => user.checked && state.notificationMessage != ""
            )
          }
        >
          {position == "left"
            ? languagePack.previous_step
            : languagePack.send_notification}
        </TFBButton>
      </div>
    );
  };

  // =========== SEND NOTIFICATION (BACKEND CONNECTION) =========================
  // ============================================================================

  const sendNotification = () => {
    let usersToNotificate = [...state.users].filter((user) => {
      return user.checked;
    });

    usersToNotificate = usersToNotificate.map((user) => user.id_external_user);

    let payload = {
      notification_text: state.notificationMessage,
      token: token,
      id_club: currentUser.id_club,
      id_user: currentUser.id_user,
      users_external: usersToNotificate,
    };

    sendNotificationPost(payload, (response) => {
      if (response.status === 200) {
        refreshMobileUsers();
        showNotification(
          "success",
          languagePack.notification_title_message,
          languagePack.notification_sucess_message
        );
        closePopup();
      } else {
        showNotification(
          "danger",
          languagePack.notification_title_message,
          languagePack.notification_failed_message
        );
      }
    });
  };

  return (
    <div className="wrapper-parent-create-buttons">
      <TFBDialog
        open={openDialog}
        fullWidth={true}
        maxWidth="md"
        closePopup={closePopup}
        title={languagePack.create_notification}
        stickyBtns
        columnActionsMobile
        btnLeft={() =>
          !state.step2
            ? renderBtn("left", () => closePopup())
            : renderBtnSecondStep("left", () => updateState({ step2: false }))
        }
        btnRight={() =>
          !state.step2
            ? renderBtn("right", () => updateState({ step2: true }))
            : renderBtnSecondStep("right", () => sendNotification())
        }
      >
        <div className="add-users-notifications-container">
          {!state.step2 && (
            <>
              <div className="sub-header ds-headline-2">
                {languagePack.step_one_selecte_audience}
              </div>

              <div className="group-select-filter">
                <TFBSelect
                  options={state.groupsOptions}
                  label={languagePack.select_group}
                  placeholder={languagePack.select_group}
                  className="select-group"
                  value={state.selectedGroups}
                  onChange={(selected) => {
                    updateState({ selectedGroups: selected });
                  }}
                  isClearable
                  isMulti
                  isSearchable
                  isLoading={isTeamsLoading}
                />

                {/* <TFBButton
                  className="apply-filter"
                  renderIcon={() => <TFBIcon name="check" />}
                  onClick={() => {
                    setIsAllChecked(false);
                    getUsers();
                  }}
                >
                  {languagePack.apply_filters}
                </TFBButton>
                <TFBButton
                  className="cancel-filter"
                  renderIcon={() => <TFBIcon name="cancel" color="gray" />}
                  onClick={() => {
                    setSelectedGroup(null);
                  }}
                  color="lightGray"
                >
                  {languagePack.cancel_filters}
                </TFBButton> */}
              </div>

              {isUsersLoading ? (
                <CircularLoader />
              ) : state.users.length > 0 ? (
                <div className="users-table">
                  <TableContainer>
                    <Table
                      faria-label="simple table"
                      sx={{ minWidth: "370px" }}
                    >
                      <TableHead>
                        <TableRow>
                          <TFBTableCell>
                            <div className="header-checkbox">
                              <TFBCheckbox
                                checked={state.isAllChecked}
                                onChange={(e) => handleHeaderCheck(e)}
                                mode="light"
                              />
                              <div>{languagePack.User_name}</div>
                            </div>
                          </TFBTableCell>
                          <TFBTableCell align="center">
                            {languagePack.plyers_names}
                          </TFBTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {state.users.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              backgroundColor:
                                index % 2 == 0 ? "#fafafa" : "white",
                            }}
                          >
                            <TFBTableCell
                              style={{
                                backgroundColor:
                                  index % 2 == 0 ? "#fafafa" : "white",
                              }}
                            >
                              <div className="row-checkbox-player">
                                <TFBCheckbox
                                  onChange={() => handleRowCheck(index)}
                                  checked={row.checked}
                                  mode="light"
                                />

                                <div className="user-details">
                                  <div>{row.user_name}</div>
                                  <div className="relationship ds-secondary-text-auxiliary">
                                    {row.relationships}
                                  </div>
                                </div>
                              </div>
                            </TFBTableCell>
                            <TFBTableCell align="center">
                              {row.players_name}
                            </TFBTableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ) : (
                <TFBPlaceholder
                  text={
                    state.selectedGroups?.length > 0
                      ? languagePack.no_users
                      : languagePack.select_one_or_more_group
                  }
                />
              )}
            </>
          )}

          {state.step2 && (
            <>
              <div className="sub-header ds-headline-2">
                {languagePack.step_two_select_audience}
              </div>

              <div className="message">
                <TFBInput
                  label={`${
                    String(state.notificationMessage).length
                  }/${maxMessageLength}`}
                  placeholder={languagePack.your_message + "..."}
                  className="message-input"
                  value={state.notificationMessage}
                  onChange={(e) =>
                    updateState({ notificationMessage: e.target.value })
                  }
                  fullWidth
                  inputProps={{ maxLength: maxMessageLength }}
                />
              </div>
            </>
          )}
        </div>
      </TFBDialog>
    </div>
  );
};

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(CreateNotification);
