import React, { useContext } from "react";
import styles from "./AIChatPage.module.scss";

export default function AIChatHistory({
  languagePack,
  aiChatContext,
  onSendMessage,
  token,
}) {
  return (
    <div className={styles.chatHistoryColumn}>
      <div className={styles.historyTitle}>{languagePack.history}</div>
      {aiChatContext.userChatHistory.map((item, index) => (
        <div
          onClick={() => onSendMessage(item.question, token)}
          className={styles.historyItem}
          key={index}
        >
          {item.question}
        </div>
      ))}
    </div>
  );
}
