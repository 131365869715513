import React from "react";
import styles from "./AIChatPage.module.scss";
import CircularLoader from "../../components/reusable/Loader";
import { loadingMessages } from "../../utils/utilFunctions";

export default function AiLoadingMessage({ loadingMessageIndex }) {
  return (
    <div className={styles.aiMessage}>
      <div className={styles.aiMessageHeader}>
        <img
          style={{ height: "32px", marginRight: "4px" }}
          src={"/media/logos/logo-dark.png"}
          alt="aiChatIcon"
        />
      </div>
      <CircularLoader
        size={18}
        style={{ color: "#00d948", height: "12px", padding: "0px" }}
      />
      <div className={styles.aiLoadingText}>
        {loadingMessages[loadingMessageIndex]}
      </div>
    </div>
  );
}
