import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import styles from "./AIChatPage.module.scss";
import AIChat from "../../../_metronic/layout/components/subheader/AIChat";

import {
  sendMessage,
  setLoading,
  sendFeedbackMessage,
  sendFeedbackType,
  setFeedbackMessage,
  hideFeedbackInput,
  getRemoteState,
} from "../../../redux/aiChat/aiChat.actions";
import _ from "lodash";
import AIChatHistory from "./AIChatHistory";
import AiLoadingMessage from "./AiLoadingMessage";
import { loadingMessages } from "../../utils/utilFunctions";
import AiChatTitle from "./AiChatTitle";
import AiChatMessage from "./AiChatMessage";

function AIChatPage({
  token,
  aiChatContext,
  hideFeedbackInput,
  sendFeedbackType,
  languagePack,
  setFeedbackMessage,
  sendFeedbackMessage,
  getRemoteState,
  sendMessage,
}) {
  const [loadingMessageIndex, setLoadingMessageIndex] = React.useState(0);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    getRemoteState(token);
  }, []);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [aiChatContext.conversation, aiChatContext.isLoading]);

  useEffect(() => {
    let intervalId;
    if (aiChatContext.isLoading) {
      intervalId = setInterval(() => {
        setLoadingMessageIndex((prev) => (prev + 1) % loadingMessages.length);
      }, 3000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [aiChatContext.isLoading]);

  const thumbsUpClick = (id_message) => {
    if (
      Number(
        _.find(aiChatContext.feedbackTypeList, {
          id: id_message,
        })?.type
      ) === 1
    )
      return;
    sendFeedbackType(id_message, 1, token);
  };

  const handleHideFeedbackInput = (idMessage) => {
    setFeedbackMessage({ idMessage: idMessage, feedbackMessage: "" });
    hideFeedbackInput();
  };

  const thumbsDownClick = (id_message) => {
    if (
      Number(
        _.find(aiChatContext.feedbackTypeList, {
          id: id_message,
        })?.type
      ) === 0
    )
      return;
    sendFeedbackType(id_message, 0, token);
  };

  const handleChangeFeedbackMessage = (feedbackMessage, idMessage) => {
    setFeedbackMessage(idMessage, feedbackMessage);
  };
  const handleSendFeedbackMessage = (idMessage) => {
    sendFeedbackMessage(
      idMessage,
      _.find(aiChatContext.feedbackMessageList, {
        idMessage: idMessage,
      })?.feedbackMessage,
      token,
      languagePack
    );
  };
  const handleKeyDown = (e) => {};

  return (
    <div className={styles.wrapper}>
      <AIChatHistory
        languagePack={languagePack}
        aiChatContext={aiChatContext}
        onSendMessage={sendMessage}
        token={token}
      />
      <div
        className={styles.conversationColumn}
        style={{
          justifyContent:
            aiChatContext.conversation.length > 0 ? "flex-end" : "center",
        }}
      >
        <div className={styles.conversation}>
          {aiChatContext.conversation.map((item, index) => (
            <AiChatMessage
              key={index}
              item={item}
              index={index}
              token={token}
              onHideFeedbackInput={handleHideFeedbackInput}
              aiChatContext={aiChatContext}
              languagePack={languagePack}
              setFeedbackMessage={setFeedbackMessage}
              handleKeyDown={handleKeyDown}
              handleChangeFeedbackMessage={handleChangeFeedbackMessage}
              handleSendFeedbackMessage={handleSendFeedbackMessage}
              thumbsUpClick={thumbsUpClick}
              thumbsDownClick={thumbsDownClick}
            />
          ))}
          {aiChatContext.isLoading && (
            <AiLoadingMessage loadingMessageIndex={loadingMessageIndex} />
          )}
          {aiChatContext.conversation.length === 0 && (
            <AiChatTitle
              title="Ce vrei să afli azi despre clubul tău?"
              description="Întreabă-ne despre statistici, jucători, staff, evenimente, competiții sau date financiare"
            />
          )}
          <div ref={messagesEndRef} />
        </div>
        <AIChat
          onClickSend={() => {}}
          gridPosition="hidden"
          wrapperStyle={{
            position: "relative",
            padding: "0",
            border: "2px solid #E6E6E6",
            flex: 0,
            marginTop: "12px",
          }}
          textAreaContainerStyle={{
            backgroundColor: "#fff",
          }}
        />
      </div>
      <div className={styles.referenceColumn}></div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  token: state.auth?.user?.token,
  languagePack: state.layoutService.languagePack,
  conversation: state.aiChat.conversation,
  isLoading: state.aiChat.isLoading,
  aiChatContext: state.aiChat,
});

const mapDispatchToProps = {
  sendMessage,
  setLoading,
  sendFeedbackType,
  setFeedbackMessage,
  sendFeedbackMessage,
  hideFeedbackInput,
  getRemoteState,
};

export default connect(mapStateToProps, mapDispatchToProps)(AIChatPage);
