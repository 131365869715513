import React from "react";
import styles from "./AIChatPage.module.scss";
import ReactMarkdown from "react-markdown";
import TFBIcon from "../../components/design-system/Icon/TFBIcon";
import TFBIconButton from "../../components/design-system/IconButton/TFBIconButton";
import _ from "lodash";

export default function AiChatMessage({
  item,
  index,
  aiChatContext,
  languagePack,
  token,
  handleKeyDown,
  handleChangeFeedbackMessage,
  handleSendFeedbackMessage,
  thumbsUpClick,
  thumbsDownClick,
  onHideFeedbackInput,
}) {
  return (
    <div
      key={index}
      className={item.sender === "user" ? styles.userMessage : styles.aiMessage}
    >
      {item.sender === "ai" && (
        <div className={styles.aiMessageHeader}>
          <img
            style={{ height: "32px", marginRight: "4px" }}
            src={"/media/logos/logo-dark.png"}
            alt="aiChatIcon"
          />
        </div>
      )}
      {item?.type === "loading" && <div className={styles.greenSpot}></div>}
      <div className={styles.aiMessageContent}>
        <div>
          {item.sender === "ai" ? (
            <ReactMarkdown>{item.content}</ReactMarkdown>
          ) : (
            item.content
          )}
        </div>
        {item.sender === "ai" && (
          <div className={styles.aiMessageFeedback}>
            {Number(
              _.find(aiChatContext.feedbackTypeList, {
                id: item.id,
              })?.type
            ) !== 0 && (
              <TFBIcon
                name="thumbs-up"
                color={
                  Number(
                    _.find(aiChatContext.feedbackTypeList, {
                      id: item.id,
                    })?.type
                  ) === 1
                    ? "green"
                    : "lightGray"
                }
                className={styles.aiMessageFeedbackIcon}
                onClick={() => thumbsUpClick(item.id)}
              />
            )}
            {Number(
              _.find(aiChatContext.feedbackTypeList, {
                id: item.id,
              })?.type
            ) !== 1 && (
              <TFBIcon
                name="thumbs-down"
                color={
                  Number(
                    _.find(aiChatContext.feedbackTypeList, {
                      id: item.id,
                    })?.type
                  ) === 0
                    ? "red"
                    : "lightGray"
                }
                className={styles.aiMessageFeedbackIcon}
                onClick={() => thumbsDownClick(item.id)}
              />
            )}
            {_.find(aiChatContext.feedbackTypeList, {
              id: item.id,
              showInput: true,
            }) && (
              <div className={styles.aiMessageFeedbackInput}>
                <input
                  onKeyDown={handleKeyDown}
                  onChange={(e) =>
                    handleChangeFeedbackMessage(e.target.value, item.id)
                  }
                  value={
                    _.find(aiChatContext.feedbackMessageList, {
                      id: item.id,
                    })?.feedbackMessage
                  }
                  type="text"
                  placeholder={languagePack.feedback_placeholder}
                />
                {_.find(aiChatContext.feedbackMessageList, {
                  id: item.id,
                })?.feedbackMessage.trim() !== "" && (
                  <TFBIconButton
                    style={{ borderRadius: "50%" }}
                    name="arrow-up"
                    alt="send"
                    color="green"
                    onClick={() =>
                      handleSendFeedbackMessage(
                        item.id,
                        item.content,
                        token,
                        languagePack
                      )
                    }
                  />
                )}
                <TFBIcon
                  style={{
                    cursor: "pointer",
                    "&:hover": { color: "black" },
                  }}
                  name="x-large"
                  color="lightGray"
                  onClick={() => onHideFeedbackInput(item.id)}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
