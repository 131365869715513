import React from "react";
import { CircularProgress } from "@material-ui/core";

const CircularLoader = ({ style = {}, size = 40 }) => (
  // we need to add some padding to circular progress to keep it from activating our scrollbar
  <div
    style={{
      padding: "24px",
      display: "flex",
      justifyContent: "center",
      ...style,
    }}
  >
    <CircularProgress size={size} />
  </div>
);

export default CircularLoader;
