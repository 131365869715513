const AIChatActionTypes = {
  SET_CONVERSATION: "[AIChat] Set Conversation",
  ADD_MESSAGE: "[AIChat] Add Message",
  SET_LOADING: "[AIChat] Set Loading",
  CLEAR_CONVERSATION: "[AIChat] Clear Conversation",
  SET_FEEDBACK_MESSAGE: "[AIChat] Set Feedback Message",
  SET_FEEDBACK_TYPE: "[AIChat] Set Feedback Type",
  HIDE_FEEDBACK_INPUT: "[AIChat] Hide Feedback Input",
  SET_USER_CHAT_HISTORY: "[AIChat] Set User Chat History",
};

export default AIChatActionTypes;
