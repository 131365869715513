import useAxiosGet from "../../app/components/customHooks/useAxiosGet";
import { showNotification } from "../../app/components/customHooks/useAxiosPost";
import { baseUrl } from "../../app/utils/utilFunctions";
import AIChatActionTypes from "./aiChat.types";
import axios from "axios";

export const setConversation = (conversation) => ({
  type: AIChatActionTypes.SET_CONVERSATION,
  payload: conversation,
});

export const addMessage = (message) => ({
  type: AIChatActionTypes.ADD_MESSAGE,
  payload: message,
});

export const setLoading = (isLoading) => ({
  type: AIChatActionTypes.SET_LOADING,
  payload: isLoading,
});

export const clearConversation = () => ({
  type: AIChatActionTypes.CLEAR_CONVERSATION,
});

export const setFeedbackType = (idMessage, type) => ({
  type: AIChatActionTypes.SET_FEEDBACK_TYPE,
  payload: { idMessage, type },
});

export const setFeedbackMessage = (idMessage, feedbackMessage) => ({
  type: AIChatActionTypes.SET_FEEDBACK_MESSAGE,
  payload: { idMessage, feedbackMessage },
});

export const hideFeedbackInput = (idMessage) => ({
  type: AIChatActionTypes.HIDE_FEEDBACK_INPUT,
  payload: { idMessage },
});

export const setUserChatHistory = (userChatHistory) => ({
  type: AIChatActionTypes.SET_USER_CHAT_HISTORY,
  payload: userChatHistory,
});

export const sendFeedbackType = (idMessage, type, token) => async (
  dispatch
) => {
  const response = await axios.post(
    `https://api.thefootballbrain.app/ai/update_feedback_value?token=${token}`,
    { idMessage, type }
  );

  if (response.data.success) {
    dispatch(setFeedbackType(idMessage, type));
  }
};

export const getRemoteState = (token) => async (dispatch) => {
  const response = await axios.get(baseUrl(`ai/get_ai_calls?token=${token}`));

  const userHistory = response.data.data.map((item) => ({
    question: item.natural_language_query,
    idAiCall: item.idAiResponse,
    aiResponse: item.ai_response,
  }));

  dispatch(setUserChatHistory(userHistory));
};

export const sendFeedbackMessage = (
  idMessage,
  feedbackMessage,
  token,
  languagePack
) => async (dispatch) => {
  const response = await axios.post(
    baseUrl(`ai/update_feedback_text?token=${token}`),
    { idMessage, feedbackMessage }
  );

  if (Number(response.data.success) === 1) {
    dispatch(hideFeedbackInput(idMessage));
    showNotification(
      "success",
      languagePack.aiChat,
      languagePack.feedbackMessageSuccess
    );
  } else {
    showNotification(
      "danger",
      languagePack.aiChat,
      languagePack.feedbackMessageError
    );
  }
};

export const sendMessage = (message, token) => async (dispatch) => {
  // Create a local copy of the message value immediately
  const messageText = message?.trim();
  if (!messageText) return;

  const userMessage = {
    sender: "user",
    content: messageText,
  };

  dispatch(addMessage(userMessage));
  dispatch(setLoading(true));

  try {
    const response = await axios.post(
      baseUrl(`goutte/ai/index.php?token=${token}`),
      { message: messageText }
    );

    if (response.data.success) {
      const aiMessage = {
        type: "text",
        sender: "ai",
        content: response.data.response,
        id: response.data.id_ai_call,
      };
      dispatch(addMessage(aiMessage));
    } else {
      const errorMessage = {
        type: "text",
        sender: "ai",
        content:
          "S-a produs o eroare. Te rugăm încearcă din nou. Dacă eroarea persistă, folosește chatul din dreapta jos a ecranului pentru a solicita ajutor.",
      };
      dispatch(addMessage(errorMessage));
    }
  } catch (error) {
    const errorMessage = {
      type: "text",
      sender: "ai",
      content:
        "S-a produs o eroare. Te rugăm încearcă din nou. Dacă eroarea persistă, folosește chatul din dreapta jos a ecranului pentru a solicita ajutor.",
    };
    dispatch(addMessage(errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};
