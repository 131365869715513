export default [
  {
    language: "en",
    lang: "en",
    key: "en",
    value: "en",
    flag: "gb",
    text: "EN",
  },
  {
    language: "ro",
    lang: "ro",
    key: "ro",
    value: "ro",
    flag: "ro",
    text: "RO",
  },
  {
    language: "es",
    lang: "es",
    key: "es",
    value: "es",
    flag: "es",
    text: "ES",
  },
  {
    language: "it",
    lang: "it",
    key: "it",
    value: "it",
    flag: "it",
    text: "IT",
  },
];
