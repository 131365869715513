import React from "react";
import { Slider, styled, SliderProps } from "@mui/material";

const StyledSlider = styled(Slider)(function() {
  return {
    color: "black",
    width: window.innerWidth < 1024 ? "100%" : "200px",
    padding: "15px 0",
    "& .MuiSlider-thumb": {
      height: 20,
      width: 20,
      borderRadius: "3px",
      backgroundColor: "#666",
      boxShadow: "0 0 2px 0px rgba(0, 0, 0, 0.1)",
      "&:focus, &:hover, &.Mui-active": {
        boxShadow: "0px 0px 3px 1px rgba(0, 0, 0, 0.1)",
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {},
      },
      "&:before": {
        boxShadow:
          "0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)",
      },
    },
    "& .MuiSlider-valueLabel": {
      //   display: "none",
      fontSize: 13,
      top: 46,

      color: "#b2b2b2",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "700",
      backgroundColor: "unset",
      "&::before": {
        display: "none",
      },
      "& *": {
        background: "transparent",
        color: "#3F4254",
      },
    },
    "& .MuiSlider-areaLabel": {
      color: "#3F4254",
    },
    "& .MuiSlider-track": {
      border: "none",
      height: 7.5,
      backgroundColor: "#ccc",
    },
    "& .MuiSlider-rail": {
      height: 7.5,
      opacity: 1,
      backgroundColor: "#F9f9f9",
    },
  };
});

export const TFBRangeSlider: React.FC<SliderProps> = ({
  step = 1,
  ...props
}) => {
  return <StyledSlider step={step} {...props} />;
};
