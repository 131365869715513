export default {
  platform_update: "Update",
  platform_update_message: "The app is upgrading to version $version!",

  //alt title
  altTitle: {
    view_team: "View Team",
    edit_team: "Edit Team",
    costs: "Payments received",
    medical_certificate: "Medical Certificates",
    import_players: "Import players",
  },

  // QUICK SETUP
  quickSetup: {
    information_about_your_club: "Detailed information about your club",

    team: "Team",
    match_training: "Matches and training",
    matches: "Matches",
    competition: "Competitions",

    add_staff_min: "Add minimum x_number staff(s)",
    add_team_min: "Add minimum x_number team",
    add_player_min: "Add minimum x_number player(s)",

    add_training_min: "Add minimum x_number training",
    add_match_min: "Add minimum x_number match",
    add_competition_min: "Add minimum x_number competition",

    start_setup: "Start setup",
    continue_setup: "Continue setup",
    quick_action: "Quick action",
  },

  //no data placeholder
  no_data_activ_contract: "No active contract",
  no_data_active_license: "No active license",

  //TEAMS LIST
  active_season: "Active season",
  season: "Season",
  select_season: "Select season",
  modify_start_date: "Modify start date",
  modify_end_date: "Modify end date",
  set_season_as_active: "Set season as active",
  create_season: "Create season",
  select_start_date: "Select start date",
  select_end_date: "Select end date",
  season_start_date: "Start date",
  season_end_date: "End date",
  save_season: "Save season",
  team_data: "Team data",
  season_management: "Season management",
  success_save_team_details: "Team data was successfully saved!",
  fail_save_team_details: "Team data wasn't successfully saved!",
  start_end_season_error: "*End date lass than start one",
  empty_date_error: "*Select both date",
  success_update_season: "Season was successfully updated!",
  fail_update_season: "Season wasn't successfully updated!",
  success_delete_season: "Season was successfully deleted!",
  fail_delete_season: "Season wasn't successfully deleted!",
  delete_season: "Delete seasom",
  delete_season_message:
    "Are you sure you want to remove this team season and its related data? The action is irreversible.",
  mobile_app_details: "Mobile app details",
  general_details: "General details",
  mobile_app_users: "Mobile app users",

  success_update_player_instat_id:
    "The player's Instat ID has been successfully modified!",
  fail_update_player_instat_id:
    "The player's Instat ID hasn't been successfully modified!",
  migrate_all_active_players_to_new_season:
    "Migrate all active players in new season",

  too_short_season_error:
    "The date difference is within 30 days. Are you certain that you have set the season dates correctly?",

  // Video Gallery

  edit_video_title: "Edit video title",
  successfully_edited_video_title: "You successfully edited the video title",
  fail_edited_video_title: "An error occurred while editing",

  add_video: "Add video",
  video_description: "Video description",
  upload_video: "Upload video",
  video_title: "Video title",
  video_source: "Video source",
  video_date_hour_add: "Date and hour add",
  apply_filter: "Apply filter",
  cancel_filter: "Cancel filter",
  delete_video: "Delete video",
  delete_video_text:
    "Are you sure you want to remove this video? The action is irreversible.",
  video_upload_success: "You have added a new video!",
  video_upload_failed: "Your video haven't been uploaded!",
  video_delete_success: "Your video was successfully deleted!",
  video_delete_fail: "Your video wasn't successfully deleted!",
  video_operation: "Video operation",
  available_plural: "available",
  share_with_another_team: "Share with another team",
  share: "Share",
  send: "Send",
  share_video: "Share video",
  enter_club_name: "Enter club name",
  name_and_surname: "Name and surname",
  enter_name_and_surname_share_video:
    "Enter the name and surname of the person you are sending the video to",
  email: "Email",
  enter_email: "Enter email",
  club_type: "Club type",
  select_club: "Select club",
  phone_number: "Phone number",
  club_from_platform: "Club from the TFB platform",
  club_from_external: "Club outside the TFB platform",
  video_share_success: "Video was successfully shared!",
  video_share_failed: "Video wasn't successfully shared!",

  assist: "Assist",
  delete_tag: "Delete tag",
  sure_delete_tag: "Are you sure you want to delete this tag?",
  video_event: "Video event",
  select_all: "Select all",
  starting_moment: "Starting moment",
  ending_moment: "Ending moment",
  event_type: "Event type",
  tag_title: "Tag title",
  tag_details: "Tag details",
  enter_details: "Enter details...",
  search_video: "Search video",
  add_summary_title: "Add summary title",
  create_summary: "Create summary",
  show_filters: "Show filters",
  select_tags: "Select tags",
  select_players: "Select players",
  cancel_filters: "Cancel filters",
  no_options_for_selected_filters: "There are no options for selected filters",

  vide_time_input_error:
    "You entered a value greater than the duration of the video",
  date_time_difference_error:
    "The value at the Start Moment must be less than the value at the End Moment",

  success_video_added: "Video tag was successfully added!",
  failed_video_added: "Video tag adding has failed!",
  success_video_edited: "Video tag was successfully edited!",
  failed_video_edited: "Video tag editing has failed!",

  //staff members

  license_file: "License file",
  without_license: "Without license",
  uefa_b: "UEFA B",
  uefa_a: "UEFA A",
  uefa_a_youth: "UEFA A Youth",
  uefa_b_youth: "UEFA B Youth",
  uefa_a_gk: "UEFA A GK",
  uefa_b_gk: "UEFA B GK",
  uefa_pro: "UEFA Pro",
  uefa_non_uefa: "NON-UEFA",
  uefa_c: "UEFA C",
  uefa_coaching_license: "Coaching license",

  date_expire_license: "Licence expiry date",

  select_license: "Select license",
  staff_license: "License",
  add_certificate: "Add certificate",
  add_medical_tests: "Add medical tests",

  designation: "Name",
  subcategory_designation: "Subcategory name",
  type: "Type",
  dimension: "Dimension",
  added_at: "Added at",

  // Campaigns
  campaign_title: "Campaigns",
  campaign_name: "Campaign Name",
  campaign_type: "Type",
  campaign_status: "Status",
  campaign_audience: "Audience",
  campaign_last_edited: "Last edited",
  campaign_opens: "Opens",
  campaign_clicks: "Clicks",
  campaign_status_filter: "Filter by status",
  campaign_type_filter: "Filter by type",
  campaign_pagination: "Results per page",
  campaign_your_audience: "Your audience",
  campaign_choose_audience: "Choose audience",
  campaign_new_segment: "New segment",
  campaign_add_new_condition: "Add new condition",
  campaign_save_segment: "Save segment",
  campaign_sender: "Sender",
  campaign_subject: "Subject",
  campaign_enter_subject: "Enter subject",
  campaign_content: "Content",
  campaign_main_title: "Main title",
  campaign_preview_article: "Preview article",
  campaign_schedule_campaign: "Schedule campaign",
  campaign_send_later: "Send later",
  campaign_send_test_message: "Send test message",
  campaign_save_message: "Save message",
  campaign_save_as_template: "Save as template",
  campaign_cancel: "Cancel",
  campaign_is_before: "Is before",
  campaign_is_after: "Is after",
  campaign_specific_date: "A specific date",
  campaign_range: "A range",
  campaign_contact_must_match: "Contact must match",
  campaign_gender: "Gender",
  campaign_birthday: "Birthday",
  campaign_date_added: "Date added",
  campaign_view_email: "View email",
  campaign_download: "Download",
  campaign_print: "Print",
  campaign_top_links: "Top links clicked",
  campaign_view_all_links: "View all links",
  campaign_recipients: "Recipients",
  campaign_total_recipients: "Total recipients",
  campaign_total_opens: "Opened",
  campaign_total_clicked: "Clicked",
  campaign_total_bounced: "Bounced",
  campaign_total_unsubscribed: "Unsubscribed",
  campaign_delivered: "Delivered",
  email_campaign: "E-mail campaign",
  campaign_report: "Campaign report",
  campaign_view_all: "View all",
  campaign_subscribers_most_opens: "Subscribers with most opens",
  campaign_numbers_stats: "Numbers and stats",
  campaign_abuse_reports: "Abuse reports",
  campaign_last_clicked: "Last clicked",
  campaign_total_clicks: "Total clicks",
  campaign_clicks_per_unique_opens: "Clicks per unique opens",
  campaign_forwarded: "Forwarded",
  campaign_last_opened: "Last opened",
  campaign_successful_deliveries: "Successful deliveries",

  //Fans section
  tax_redirectors_title: "Profit tax redirects",
  tax_redirectors_total: "Total redirects",
  tax_redirector_name: "First and last name",
  tax_redirector_register_date: "Registration date",
  tax_redirector_email: "Email address",
  tax_redirector_phone_number: "Phone number",
  incorect_phone_number: "Incorrect phone number",

  //Site administration
  about_us_title: "About us",
  upload_main_image: "Upload main image",
  upload_another_main_image: "Upload another main image",
  sections: "Sections",
  section: "Section",
  admin_add: "Add",
  admin_cancel: "Cancel",
  admin_delete: "Delete",
  upload_image: "Upload image",
  upload_other_image: "Upload other image",
  admin_save: "Save",
  admin_save_changes: "Save changes",
  admin_cancel_modif: "Discard changes",
  admin_preview: "Preview page",
  admin_edit: "Edit",
  admin_clear_all: "Clear all",
  admin_toggle_grid: "Toggle grid",
  admin_rotate: "Rotate",
  admin_are_you_sure: "Are you sure?",
  gallery_title: "Preview gallery",
  gallery_photos_title: "Photos gallery",
  gallery_videos_title: "Videos gallery",
  gallery_bio_title: "Biography",
  gallery_add_button: "Add image / images",
  gallery_add_button_images: "Add image / images",
  gallery_add_button_videos: "Add video / videos",
  gallery_add_button_bio: "Add / edit biography",
  gallery_placeholder: "You have currently not uploaded any images.",
  gallery_videos_placeholder: "You have currently not uploaded any videos.",
  gallery_bio_placeholder: "You have currently not added any biography.",
  file_remove_success_title: "File removed successfully.",
  general_error_message: "An error occurred, please try again later.",

  partners_title: "Partners & sponsors",
  add_partner_button: "Add partner",
  sponsor_name_form: "Sponsor name",
  sponsor_site_form: "Site URL",
  sponsor_logo_form: "Upload logo (.png)",
  sponsor_site_notif_title: "Manage partners",
  sponsor_site_notif_success: "Partenerul a fost adaugat cu succes",
  delete_partner: "Delete partner",
  delete_partner_popup_text: "Are you sure you want to remove this partner?",
  admin_confirm: "Confirm",
  delete_sponsor_notif_text: "The partner has been removed successfully",

  admin_contact_title: "Contact data",
  admin_contact_phone: "Phone number",
  admin_contact_address: "Address",
  admin_contact_org_name: "Organization name",
  admin_contact_save_changes: "Save changes",
  admin_contact_notif_title: "Contact data management",
  admin_contact_notif_text_success: "Changes have been saved successfully!",

  gallery_delete_popup_title: "Delete image",
  gallery_delete_popup_title_video: "Delete video",
  gallery_delete_popup_text: "Are you sure you want to delete this image?",
  gallery_delete_popup_text_video:
    "Are you sure you want to delete this video?",
  upload_images_popup_title: "Upload images",
  upload_videos_popup_title: "Upload videos",
  upload_bio_popup_title: "Add / edit biography",
  gallery_upload_select_file: "Select file",
  gallery_upload_select_files: "Select files",
  gallery_upload_nr_singular: " image uploaded",
  gallery_upload_nr_plural: "images uploaded",
  gallery_notification_title: "Gallery",
  gallery_notification_success: "Files were uploaded successfully!",
  gallery_notification_fail: "Files weren't uploaded successfully!",
  gallery_notification_partial_upload:
    "Files were partially uploaded successfully!",
  gallery_notification_max_size_upload: "Files maximum accepted size is 2 GB!",

  player_gallery_no_images: "No photos found.",
  player_gallery_no_videos: "No videos found.",
  player_gallery_delete_image_success: "Photo was successfully deleted.",
  player_gallery_delete_image_fail: "Photo wasn't successfully deleted.",

  edit_biography: "Edit biography",

  news_page_title: "Latest news added",
  news_page_add_button: "Add news",
  news_page_header_title: "Title",
  news_page_header_tag: "Tag",
  news_page_header_date: "Date of publication",
  news_page_header_options: "Options",
  no_news: "There are no news.",

  news_page_delete_popup_title: "Delete article",
  news_page_delete_popup_text:
    "Are you sure you want to remove this news? The action is irreversible.",
  add_news_title: "Add news",
  add_news_article_title: "News title",
  add_news_publish_button: "Publish article",
  add_news_cancel_button: "Discard article",
  article_preview: "Preview article",
  edit_news_title: "Edit news",
  edit_news_delete_button: "Delete article",
  edit_section: "Edit section",

  article_sub_title: "Subtitle",
  article_paragraph: "Paragraph",
  article_image: "Image",

  success_add_news: "News was successfully added.",
  fail_add_news: "News wasn't successfully added.",
  success_delete_news: "News was successfully deleted.",
  fail_delete_news: "News wasn't successfully deleted.",
  success_edit_news: "News was successfully edited.",
  fail_edit_news: "News wasn't successfully edited.",

  select_audience: "Select audience",
  only_my_club: "Only my club",
  all_clubs: "All clubs",

  send_mobile_app_notification: "Send mobile app notification",

  //Medical

  expired: "Expired",
  medical_certificate: "Medical certificate",
  expires_in_days: "Expires in (days)",
  visa_expiration_date: "Visa expiration date",
  date_of_examination: "Date of examination",
  medical_certificates: "Medical certificates",
  no_medical_certificates: "No medical certificates found.",
  add_medical_certificate: "Add certificate",
  date_of_certificate_examination: "Date of certificate examination",
  expiry_date_examination: "Expiry date of certificate examination",
  proof_of_medical_certificate: "Proof of medical certificate",
  date_of_medical_tests_examination: "Date of medical tests",
  expiry_date_medical_tests: "Date of expiry medical tests",
  proof_of_medical_tests: "Proof of medical tests",

  select_iamge: "Select image",
  files: "Files",
  file: "File",

  active_injuries_title: "Current injuries",
  active_injuries_add_button: "Add injury",
  active_injuries_player_name: "Player name",
  active_injuries_type: "Type of injury",
  active_injuries_injury_date: "Date of injury",
  active_injuries_estimated_recovery: "Estimated recovery",
  injury_details: "Injury details",
  add_injury_popup_title: "Add new injury",
  injury_zone: "Zone",
  edit_details: "Edit details",
  treatment: "Treatment",
  injury_edit: "Edit",
  add_treatment: "Add treatment",
  end_injury: "End injury",

  select_team: "Select team",
  select_team_teams: "Select team/teams",
  select_player: "Select player",
  select_zone: "Select zone",
  select_evaluator: "Select reviewer",
  select_parameters_category: "Select parameters category",
  categories_list: "Categories list",
  add_new_subdivision: "Add new subdivison",
  add_new_subcategory: "Add new subcategory",
  selected_categories: "Selected categories",

  start_date: "Start date",
  end_date: "End date",
  return_date: "Date of return",
  treatment_details: "Treatment details",
  injuries_history: "Injuries history",
  absence_period: "Period of absence",
  no_treatment_found: "No treatment found",
  total_treatment_cost: "Total treatment cost",
  injury_date: "Date",
  injury_day: "day",
  injury_days: "days",
  total_cost: "Total cost",
  no_injuries_found: "No injury found during the selected period",
  injury_change_success: "The selected injury has been successfully modified!",
  injury_close_success: "The injury ended on the selected date",
  injury_delete_success: "The selected injury has been successfully removed!",
  treatment_delete_success:
    "The selected treatment has been successfully removed!",
  injury_management_title: "Manage injuries",
  injury_added_success: "The injury was successfully added",
  treatment_edit_success: "The selected treatment was successfully edited!",
  cost: "Cost",
  staff_members: "Staff members",
  staff_member: "Staff member",
  roles_list: "Roles list",
  add_member: "Add member",
  staff_member_name: "Name",
  staff_member_role: "Role",
  edit_profile: "Edit profile",
  staff_full_name: "First and last name",
  staff_description: "Description",
  edit_staff_member_popup_title: "Staff member profile",
  edit_staff_member_popup_message: "Details saved successfully!",
  delete_staff_member_message:
    "Selected profile has been successfully removed!",
  popup_delete_staff_member_title: "Delete profile",
  popup_delete_staff_member_message:
    "Are you sure you want to remove this profile? The action is irreversible.",
  add_new_role: "Add new role",
  add_role: "Add role",
  no_license: "No license",
  no_staff_members: "No staff members",

  edit_team_succes: "Team edited successfully",
  edit_team_fail: "Team was NOT edited",
  manage_role_notification_title: "Club management roles",
  manage_role_notification_succes: "Role added successfully!",
  manage_role_notification_fail: "Selected role has been successfully removed!",
  active_teams: "Active teams",
  add_team: "Add team",
  no_teams_yet: "The club does not currently have any team",
  team_type: "Type",
  team_name: "Team name",
  type_team_name: "Type team name",
  team_logo: "Team logo",
  number_players: "Number of players",
  team_coach: "Coach",
  team_coordinator: "Coordinator/Coach",
  add_new_team_coordinator: "Add new Coordinator/Coach",
  teams_notification_title: "Manage teams",
  teams_notification_success: "Team was successfully added!",
  teams_notification_fail: "Team wasn't successfully added!",
  success_delete_team: "Team was successfully deleted!",
  fail_delete_team: "Team wasn't successfully deleted!",
  delete_existing_instat_assoc: "Delete the existing association",
  team_color: "Team color",
  select_color: "Select color",
  change_color: "Change color",
  no_color: "No color",
  players_add_rpe_from_app: "Players RPE and weight from mobile app",
  advanced_settings: "Advanced settings",

  set_player_as_inactive: "Set player as inactive",
  set_player_as_active: "Activate player",
  inactive_from: "Inactive from date",
  inactive_reason: "Reason",
  insert_reason: "Insert reason...",
  yes_activate: "Yes, I want to activate him",
  activate_player_question: "Are you sure you want to activate this player?",
  success_activate_player: "Player was successfully activated!",
  fail_activate_player: "Player wasn't successfully activated!",
  success_inactivate_player: "Player was successfully inactivated!",
  fail_inactivate_player: "Player wasn't successfully inactivated!",
  select_date: "Select_date",

  team_players: "Players",
  team_performance: "Team performance",
  add_player: "Add player",
  PLAYER_NAME: "Player name",
  NATIONALITY: "Nationality",
  POSITION: "Position",
  SECONDARY_POSITION: "Secondary position",
  MAIN_FOOT: "Main foot",
  AGE: "Age",
  MARKET_VALUE: "Market value",
  average: "Average",
  age_years: "years",
  minutes_played: "Minutes played",
  no_players_placeholder: "This team has no players",
  edit_team_name: "Edit team name",
  birth_date: "Date of birth",
  on_loan: "On loan at ",
  on_loan_from: "On loan from ",
  loaned: "Loaned",
  loaned_at: "Loaned at ",
  loaned_from: "Loaned from ",
  until_loan: " until ",
  goals: "Goals",
  assists: "Assists",

  //Player profile

  player_profile: "Player profile",
  change_instat_id: "Change instat id for ",
  no_records: "No records",
  keep_it_in_previous_teams: "Keep player in previous teams",
  add_player_to_another_team: "Add player to another team",
  fifa_id: "Fifa ID",
  remarks: "Remarks",
  add_remarks: "Add remark",
  mark: "Mark",
  mark_for_all: "Mark for all",
  was_present: "Was present",
  presence: "Presence",
  training_statistics: "Training statistics",
  all_present: "All present",
  no_video_events: "No video events",
  player_video_clips: "Player video snippets",
  add_document: "Add document",
  delete_document: "Delete document",
  activity_type: "Activity type",
  age_group: "Age group",
  age_group_type: {
    U15: "U15",
    U16: "U15",
    U17: "U15",
    U18: "U15",
    U19: "U15",
    U20: "U15",
    U21: "U15",
    "Echipa nationala": "National team",
  },
  national_team: "National team",
  select_type_of_activity: "Select type of activity",
  call_up_date: "Call up date",
  find_competition: "Find competition",
  no_active_competitions_placeholder: "No active competitions to display",
  no_historic_competitions_placeholder: "No ended competitions to display",
  new_competition_name: "New competition name",
  select_national_team: "Select national team",
  add_national_team_call_up_popup: "Add national team call-up",
  no_national_team_call_up: "Has no national team call-ups",
  add_national_team_call_up: "Add call up",
  national_team_calls: "National team calls",
  delete_career_record: "Delete career record",
  delete_national_call_up: "Delete national call-up",
  delete_national_call_up_message:
    "The action is irreversible. Are you sure you want to delete the national callup?",
  success_add_national_team_call_up: "National callup was successfully added!",
  fail_add_national_team_call_up: "National callup wasn't successfully added!",
  success_edit_national_team_call_up:
    "National callup was successfully modified!",
  fail_edit_national_team_call_up:
    "National callup wasn't successfully modified!",
  success_delete_national_team_call_up:
    "National callup was successfully deleted!",
  fail_delete_national_team_call_up:
    "National callup wasn't successfully deleted!",

  for_competition: " for competition ",
  no_data_evaluation: "Player has no ratings",
  birthplace: "Place of birth",
  player_age: "Age",
  weight: "Weight",
  height: "Height",
  select_year: "Select year",
  section_access_forbidden: "You do not have access to this section",
  delete_player: "Delete player",
  delete_player_popup_text:
    "Are you sure you want to remove this player from this team? The action is irreversible.",
  change_player_team: "Change the player team",
  select_new_team: "Select new team",
  player_transactions_history: "History of transactions performed",
  transaction_description: "Description",
  transaction_date: "Transaction date",
  transaction_type: "Type",
  trasnaction_amount: "Amount",
  total_expenses: "Total expenses",
  no_player_transactions: "No payments found.",
  monthly_tax_range: "Monthly tax range",

  contract_details: "Contract details",
  active_contract: "Active contract",
  active_bonuses: "Bonuses active",
  expiry_date: "Expiry date",
  monthly_wage: "Monthly wage",
  placeholder_bonuses: "No bonus added",
  starting_date: "Start date",
  shirt_number: "Shirt number",
  move_player: "Change team",
  edit_photo: "Edit photo",

  no_contract: "No contracts found",
  add_staff_contract: "Add staff member contract",
  edit_staff_contract: "Edit staff member contract",
  start_end_contract_error: "Signing date less than ending date",

  staff_contract: "Staff member contract",
  success_add_contract_staff_member: "Contract has been successfully added!",
  fail_add_contract_staff_member: "Contract hasn't been successfully added!",
  success_edit_contract_staff_member: "Contract has been successfully updated!",
  fail_edit_contract_staff_member: "Contract hasn't been successfully updated!",
  success_delete_contract_staff_member:
    "Contract has been successfully deleted!",
  fail_delete_contract_staff_member:
    "Contract hasn't been successfully deleted!",

  delete_player_from_team: "Delete player from team",
  delete_player_from_team_message:
    "Are you sure you want to remove this player from tthe selected team?",

  filter: "Filter",
  filters: "Filters",
  selected: "selected",
  selected_single: "selected",

  average_mark: "Average rating",
  attendance: "Presence",
  date_team: "Date and team",
  attended: "Present",
  absent: "Absent",
  no_training_stats: "No training stats.",

  download_player_profile: "Download player profile",

  //Aside main menu

  Club: "My club",
  dashboard: "Dashboard",
  trainings: "Training sessions",
  Reports: "Reports",
  finances: "Financial",
  medical: "Medical",
  documents: "Documents",
  fans_relation: "Fan engagement",
  site_management: "Site management",
  admin_parent_app: "Admin parents app",
  toggle_aside: "Toggle aside",
  Users: "Users",
  shop: "Shop",

  //Aside menu list

  financial_aside_title: "Financial",
  reports_aside_title: "Reports",

  account_suspension: "Account Suspended",
  suspended_account_text:
    "Your club's account has been suspended. All information is safe and will be available to you as soon as the issue is resolved ",
  back_to_login: "Înapoi la login",
  player_contracts: "Player contracts",
  staff_contracts: "Staff contracts",
  injuries: "Injuries",
  tax_redirectors: "Tax redirectors",
  news: "News",
  cashflow: "Cashflow",
  transactions: "Transactions",
  sponsors: "Sponsors",
  clients: "Clients",
  providers: "Providers",
  wages_costs: "Wages costs",
  match_costs: "Match costs",
  suspension_status: "Suspension status",
  goals_stats: "Goals stats",
  press: "Press",
  ab_list: "A/B List",
  bonuses_monitoring: "Bonuses monitoring",
  budget: "Budget",
  costs: "Costs",
  edit_costs: "Edit spendings",
  online_payment_list: "Online payment list",
  no_online_payments: "No online payments was found.",
  necessity_reports: "Necessity reports",
  upload_bank_account_statements: "Upload bank account statements",
  upload_bank_account_statements_amount_column:
    "Select the column you want to associate with payment amounts:",
  upload_bank_account_statements_date_column:
    "Select the column you want to associate with the transaction date:",
  upload_bank_account_statements_payer_column:
    "Select the column containing the name of the payer:",
  select_column: "Select column",

  justifying_documents: "Justifying documents",
  title: "Title",
  add_justifying_document: "Add justifying document",
  no_justifying_documents: "No justifying documents.",
  provider: "Provider",
  document_details: "Document details",
  accommodation_chart: "Accommodation chart",
  transportation_table: "Transportation table",
  meal_time: "Meal time",
  single_date: "Single date",
  period_of_time: "Period of time",
  success_add_justifying_document: "Document has been successfully added!",
  fail_add_justifying_document: "Document hasn't been successfully added!",
  success_edit_justifying_document: "Document has been successfully updated!",
  fail_edit_justifying_document: "Document hasn't been successfully updated!",
  success_delete_justifying_document: "Document has been successfully deleted!",
  fail_delete_justifying_document: "Document hasn't been successfully deleted!",
  edit_justifying_document: "Edit justifying document",
  delete_justifying_document_confirmation:
    "Are you sure you want to remove this document? The action is irreversible.",
  invalid_selected_period_of_time: "The selected period of time is invalid!",

  receipts: "Receipts",
  my_list: "My list",
  end_recurrent: "End of recurrent day",
  start_recurrent: "Start of recurrent day",
  day_of_the_week: "Day of the week",
  actions: "Actions",
  add_new_weight: "New measurement",
  duration_minutes: "Duration (min)",
  duration: "Duration",
  confirmed_presence: "Confirmed presence",
  presence_at_training: "Presence at training",
  add_recurrent_training: "Add recurrent training",
  table: "Table",
  chart: "Chart",
  plan_training: "Plan training",
  import_data_success: "Data imported successfully",
  import_data_fail: "Data NOT imported",
  delete_data_success: "Data deleted successfully",
  delete_data: "Delete data ",
  delete_data_fail: "Data was not deleted",
  trainings_history: "Training sessions history",
  weight_control: "Weight control",
  weight_added_success: "Weight measurement added successfully",
  weight_added_fail: "Failed to add weight measurement",
  optimal_weight_added_success: "Optimal weight added successfully",
  optimal_weight_added_fail: "Failed to add optimal weight",
  add_weight: "Add Weight",
  add_optimal_weight: "Add Optimal Weight",
  measure_date: "Measure Date",
  training_sections: "Training sections",
  attendance_report: "Attendance report",
  show_presence: "Show presence",
  average_grade: "Average grade",
  no_trainings: "No trainings",
  no_training_data: "No training data available",
  no_training_sections: "No training sections",
  have_no_presence: "Has no presence",
  find_training_section: "Find training sections... ",
  find_training_section_short: "Find sections...",
  delete_diagram_training_board_message:
    "Are you sure you want to clear all items from the diagram?",
  success_save_tactic_board: "Tactic board was successfully saved!",
  fail_save_tactic_board: "Tactic board wasn't successfully saved!",
  shapes: "Shapes",
  equipment: "Equipment",
  delete_board: "Delete board",
  download_board: "Download imagine",
  delete_file_diagram_training_board_message:
    "Are you sure you want to delete the diagram?",
  success_delete_tactic_board: "Tactic board was successfully deleted!",
  fail_delete_tactic_board: "Tactic board wasn't successfully deleted!",
  tactic_board_unsaved_changes:
    "You have unsaved changes. Are you sure you want to leave without saving?",

  add_training_section_success: "Training section added successfully",
  edit_training_section_success: "Training section edited successfully",
  edit_training_section_fail: "Training section was NOT edited ",
  add_training_section_fail: "Training section was NOT added",
  number_of_players: "Number of field players",
  number_of_goalkeepers: "Number of goalkeepers",

  physical_tests: "Physical tests",
  add_physical_tests: "Add physical test",

  add_event_success: "Event was  added successfully",
  add_event_fail: "Event was NOT added",
  add_event_type_success: "Event category list was successfully edited",
  add_event_type_fail: "Event category list was NOT edited",
  evaluation_reports: "Evaluations",
  teams: "Teams",
  calendar: "Calendar",
  matches_schedule: "Matches schedule",
  competitions: "Competitions",
  initial_points: "Initial points",
  search_logo: "Search logo ",
  continue_with_previous_logo: "Continue with the current logo",

  next_article: "Next article",
  previous_article: "Previous article",
  see_article: "See source article",
  type_keyword: "Type keyword...",

  select_venue: "Select venue/venues",
  is_recurrent: "Is recurring",
  event_name: "Event name",
  add_event_for_venue: "Add event for venue",
  has_subdivison: "Has subdivisions",
  subdivison_name: "Subdivison name",
  venue_name: "Venue name",
  venue_list: "Venue list",
  edit_training: "Edit training",
  recurring_until: "Recurring until",
  add_venue: "Add venue",
  delete_venue:
    "The action is irreversible. Are you sure you want to delete venue ",
  edit_category_list: "Edit category list",
  category_list: "Category list",
  venue_management: "Venue management",
  contact_data: "Contact data",
  social_media_connect: "Social media connect",
  fans_list: "Fans list",
  import_data: "Import data",
  campaigns: "Campaigns",
  categories: "Categories",
  users_list: "Users list",
  users_notifications: "Users notifications",
  edit_match_costs: "Edit match costs",
  total_cost_match: "Total costs",
  costs_categories: "Costs categories",
  add_cost_category: "Add category",
  add_new_category_name: "New category name",
  category_cost_sum: "Amount",
  save_edit_match_costs: "Save match costs",
  products: "Products",
  orders: "Orders",
  financial_report: "Financial report",
  select_period: "Select period",
  receipts_stats_club: "Statistică încasări club",
  receipts_stats_per_team: "Statistică încasări per echipa",
  incoming_payments: "Incoming payments",
  expected_incoming_payments: "Expected incoming payments",
  number_of_players_that_paid: "Number of players that paid",
  paid_sum: "Paid sum",
  percentage_of_total_paid: "Percentage of total paid",
  total_to_be_paid: "Total to be paid",
  debtors: "Debtors",
  annual_receipts_stats: "Annual incoming payments stats",

  //Quick user
  change_password: "Change password",
  your_currency: "Selected currency",
  no_club: "Without club",
  sign_out: "Logout",
  user_profile: "User profile",
  password_reset: "Reset password",
  account_settings: "Account settings",
  password_success_text: "The password has been changed succesfully",

  //Team players list

  add_player_to_training: "Add player to training",
  payment_history: "Payment history",
  medical_visa: "Medical visa",
  no_data_medical_visa: "No medical visa added in the selected season",
  key_pass: "Key pass",
  score: "Score",
  last_matches_stats: "Last matches statistics",
  add_cost: "Add cost",
  cost_history: "Cost history",
  change_career: "Change career",
  legitimation_period: "Legitimation period",
  club_name: "Club",
  add_in_career: "Add in career",
  career: "Career",
  has_professional_license_issued: "Has professional license issued",
  identity_card_id: "Identity card ID",
  vfm:
    "VFM shows whether a player deserves the salary he currently earns, comparing it with his performance on the field. A high index means that a player earns less than he actually deserves, comparing with the team level. The lower the index, the higher the contrast between performance and gain. ",
  pta:
    "PTA shows if there is a corelation between the number of minutes played during the season and the current performance on the pitch. The lower the index, the  higher the contrast between performance and number of minutes (a player that has played too much/too few).",
  instat_index:
    "InStat Index helps to determine the player's class based on an automatic algorithm that considers the player's contribution to the team's success, the significance of their actions, opponent's level and the level of the championship they play in.",
  instat_index_title: "Instat index",

  //Dashboard

  end_of_month_sold: "End of the month balance",
  weekly_report: "Weekly report",
  sum: "Amount",
  period: "Period",
  position: "Position",
  ranking_points: "Points",
  market_value_updates: "Market value updates",
  increase: "Increase",
  current_value: "Current value",
  calendar_title: "Events planner",
  label_color: "Label color",
  description: "Description",
  event_details: "Event details",
  location: "Location",
  hour: "Hour",
  select_match_evaluation: "Select match evaluation",
  pitch_condition: "Pitch condition",
  weather_condition: "Weather condition",
  game_play: "Game play (Weaknesses / Strengths)",
  opposing_player_mention: "Noted players on the opposing team",
  save_details: "Save details",

  //Matches list

  generate_refree_report: "Generate refree report",
  no_matches: "No matches found.",
  reserve_referee: "Reserve referee",
  obs_refree: "OBS Refree",
  obs_game: "OBS Game",
  assistant_referee: "Assistant referee",
  central_referee: "Central refree",
  refrees: "refrees",
  save_refrees: "Save refree and observers",
  no_data_documents: "No documents found",
  no_data_events: "No events found",
  first_half: "First half",
  second_half: "Second half",
  generate_match_report: "Generate match report",
  full_time_result: "Full time result",
  half_time_result: "Halft time result",
  cost_category_list: "Costs category list",
  edit_match: "Edit match",
  round: "Round",
  match: "Match",
  edit_anyway: "Edit anyway",

  edit_minutes_per_half: "Edit minutes per half",
  edit_minutes_per_half_confirmation:
    "Are you sure you want to edit the number of minutes per half? You will have to reintroduce the players in the game page for them to have the correct amount of minutes played!",
  no_selected_own_team: "Own team not selected",
  own_team_teams: "Own Team/Teams",
  select_competition: "Select competition",
  select_age_group: "Select age group",
  select_group: "Select group",
  select_match_venue: "Select location",
  add_new_match_venue: "Add new location",
  enter_new_location: "Enter new location",
  new_match_venue: "Match location",
  past_matches: "Past matches",
  future_matches: "Future matches",
  match_events: "Match events",
  starting_lineup: "Starting lineups",
  substitutes: "Substitutes",
  captain: "Captain",
  add_event: "Add event",
  yellow_card: "Yellow card",
  red_card: "Red card",
  substitution: "Substitution",
  goal: "Goal",
  event_successfully_added: "Event was successfully added!",
  event_fail_added: "Event wasn't successfully added!",
  event_minute: "Event minute",
  select_event_type: "Select event type",
  select_match_type: "Select match type",
  select_player_in: "Select player in",
  select_player_out: "Select player out",
  select_goal_type: "Select goal type",
  single_goal: "Single goal",
  goal_assist: "Goal + assist",
  autogoal: "Autogoal",
  continue_without_external_team_logo: "Continue without external team logo",
  continue_without_own_team_logo: "Continue without own team logo",
  select_scoring_player: "Select scoring player",
  select_assisting_player: "Select assisting player",
  player_lineup_successfully_added: "Lineup player added successfully!",
  player_sub_successfully_added: "Substitution player added successfully!",
  players_match_rating: "Players rating",
  no_players: "There are no players.",
  save_players_rating: "Save players rating",
  add_match_formation_first_half: "Add match formation first half",
  add_match_formation_second_half: "Add match formation second half",
  view_match_formation_first_half: "View match formation first half",
  view_match_formation_second_half: "View match formation second half",
  first_half_playing_formation: "First half playing formation",
  second_half_playing_formation: "Second half playing formation",
  choose_playing_formation: "Choose playing formation",
  select_position: "Select position",
  associate_player: "Associate player",
  change_playing_formation: "Change playing formation",
  change_playing_formation_message:
    "Are you sure you want to change the playing formation? The data related to the distribution of players will be lost.",
  yes_change: "Yes, change",
  success_save_playing_formation: "Playing formation was successfully saved!",
  fail_save_playing_formation: "Playing formation wasn't successfully saved!",
  import_lineups: "Import lineup",
  import_also_substitutes: "Import alse substitutes",
  success_import_lineup: "Lineup was successfully imported!",
  fail_import_lineup: "Lineup wasn't successfully imported!",
  delegate: "Delegate",
  delete_match_event: "Delete match event",
  delete_match_event_success: "Match event was successfully deleted!",
  delete_match_event_fail: "Match event wasn't successfully deleted!",
  update_record_event_success: "Record was successfully modified!",
  update_record_event_fail: "Record wasn't successfully modified!",

  related_videos: "Related videos",
  add_video_clip: "Add video clip",
  select_video: "Select video",
  success_map_match_video: "Video was successfully added!",
  fail_map_match_video: "Video wasn't successfully added!",
  success_unmap_match_video: "Video was successfully added!",
  fail_unmap_match_video: "Video wasn't successfully added!",
  unmap_video_message: "Are you sure that you want to unmap video from match?",

  //Providers components

  yellow: "Yellows",
  teams_from_competition: "Teams from competition",
  add_location_to_field_management: "Add location to field management",
  date_and_time_are_approximate: "Date and time are approximate",
  minutes_per_time: "Minutes per half",
  match_from_competition: "Competition match",
  frendly_match: "Frendly",
  current_season_stats: "Current season stats",
  match_sg: "Match",
  match_pl: "Matches",
  starting_lineup_matches_sg: "Match in starting 11",
  starting_lineup_matches_pl: "Matches in starting 11",
  goals_scored_sg: "Goal scored",
  goals_scored_pl: "Goals scored",
  clean_sheets_sg: "Clean sheet",
  clean_sheets_pl: "Clean sheets",
  yellow_cards_sg: "Yellow card",
  yellow_cards_pl: "Yellow cards",
  red_cards_sg: "Red card",
  red_cards_pl: "Red cards",
  player_match_rating: "Rating",
  assist_sg: "Assist",
  assist_pl: "Assists",
  season_total: "Season total",
  season_avg: "Season average",
  parameter_list: "Parameters list",
  parameter_name: "Parameter name",
  add_new_parameter: "Add new parameter",
  player_actions: "Player individual actions",
  select_match: "Select match",
  attack: "Attack",
  defence: "Defence",
  technical: "Technical",
  add_name: "Enter the name",
  select_existing_partners: "Select from existing partners",
  balance_at_end_month: "Balance at the end of the month",
  or_lowercase: "or",
  supplier_operations: "Supplier operations",
  supplier_success: "The supplier has been successfully added",
  add_supplier: "Add supplier",
  //Trainings

  generate_training_raport: "Generate training report",
  trainings_evolution: "Training sessions evolution",
  physical_tracking_results: "Physical tracking results",
  heart_rate: "Heart rate",
  distance_covered: "Distance covered",
  kcal_spent: "Calories",
  sprint_counter: "Sprint counter",
  speed: "Speed",
  medium: "avg",
  training_location: "Training location",
  start_hour: "Start hour",
  end_hour: "End hour",
  training: "Training session ",
  antrenament: "Training session ",
  training_schedule: "Training schedule",
  training_data: "Training data",
  hours_interval: "Time slot",
  note: "note",
  optimal_weight: "Optimal weight",
  weight_value: "Value",
  tactic_board: "Tactic board",
  Section_labels: "Section labels ",
  Section_description: "Section description",
  Necessary_equipment: " Necessary equipment",
  Media: "Media",
  Image: "Image",
  Video: "Video",
  Edit_labels: "Edit labels",
  Edit: "Edit",
  Create_new_tactic_board: "Create custom tactic board ",
  added_on_date: "on date",
  Category: "Category",
  Accessibility: "Accessibility",
  Section_type: "Section type",
  section_type: "Section type",
  Add_note: "Add note",
  total_duration: "Total duration",
  accessibility: "Accessibility",
  label_name: "Label Name",
  add_new_label: "Add new label",
  last_update: "Last update",
  Labels: "Labels",
  label: "Label",
  no_description_added: "No description added",
  no_labels_added: "No labels added",

  //Documents

  total_payment_at_present: "Total payment (at present)",
  edit_player_contract: "Edit player contract",
  file_details: "File details",
  upload_file: "Upload file",
  add_new_category: "Add new category",
  remove_contract: "End contract",
  remove_contract_date: "End of contract date",
  remove_contract_confirm: "Confirm",
  active_contracts: "Active contracts",
  bonuses_list: "Bonuses list",
  add_contract: "Add contract",
  occupation: "Role",
  choose_staff_member: "Choose staff member",
  contract_bonuses: "Contract bonuses",
  signing_date: "Signing date",
  add_contract_extra_bonuses_text:
    "To add additional bonuses and conditions see the bonuses section",
  select_condition: "Select condition",
  add_condition_value: "Insert condition value",
  add_condition: "Add condition",
  active_conditions: "Active conditions",
  bonus: "Bonus",
  bonus_progress: "Progress",
  bonus_value: "Bonus value",
  no_bonuses: "No bonuses found",

  competition_history: "Competition history",
  warning_comp_no_date_end:
    "The competition end date is not selected. The competition will be recorded in the 'Competition History' section.",
  warning_comp_before_current_date:
    "The selected date is before the current date. The competition will be recorded in the 'Competition History' section.",

  //Training plan

  create_training: "Create training session",
  add_training_sections: "Add sections",
  total_training_duration: "Total time",
  training_details: "Training details",
  hours_error_training: "The end hour must be greater than the start hour",
  save_training: "Save training session",
  create_new_section: "Create new section",
  add_new_section_name: "New section name",
  section_name: "Section name",
  add_training_session: "Add training session",
  training_add_title: "Training management",
  training_success: "Training was successfully added!",
  training_fail: "Training wasn't successfully added!",
  section_add_title: "Section management",
  section_added_success: "Section was successfully added!",
  section_added_fail: "Section wasn't successfully added!",
  training_date: "Date",
  training_date_team: "Date and team",
  training_description_details: "Description/Details",
  add_view_image: "Add/view image",
  add_view_video: "Add/view video",
  add_section: "Add section",
  add_section_name: "Add section name",
  add_section_description: "Add section description",

  // team settings
  team_management: "Team management",
  number_order: "Order number",
  tax_value: "Tax value",
  display_on_website: "Display on website",
  display_for_online_payments: "Display for online payments",
  save_settings: "Save settings",
  select_at_least_one_player: "Select at least one player!",

  // team settings
  staff_management: "Staff management",

  // Sponsors
  sponsor_name: "Sponsor name",

  //Matches

  match_date: "Match date",
  away_team: "Away team",
  home_team: "Home team",
  round_number: "Round number",
  round_number_name: "Round number/name",
  match_format: "Match format",
  add_match_format: "Add match format",
  other_format: "Other format",
  select_match_location: "Selected match location",
  add_match: "Add match",
  match_successfully_added: "Match added successfully!",
  competitions_actions: "Competitions operations",
  new_team: "New team",
  clubs_team: "Club's team",
  add_competition: "Add competition",
  competition_name: "Competition name",
  competition_has_more_cat: "Competition has more age categories",
  number_of_groups: "Number of groups",
  competition_type: {
    name: "Competition type",
    elements: {
      championship: "Championship",
      knockout: "Knockout",
      group_stage: "Groups + knockout",
    },
  },

  competition_edit_success: "Competition was edited successfully",
  competition_edit_fail: "Competition was NOT edited",
  county: "County",
  select_county: "Select county",
  in: "in",
  add: "Add",
  source: "Source",
  select_source: "Select source",
  series: "Series",
  select_series: "Select series",
  your_club: "Your club",
  select_your_club: "Select your club",
  club_team: "Team in your club",
  select_club_team: "Select the team in your club",
  import_competition: "Import competition",
  create_competition: "Create competition ",
  import_matches_and_competition: "Import matches and competition",
  game_day: "Game day",
  date_time: "Date and time",

  teams_number: "Number of teams",
  ranking: "Ranking",
  edit_team: "Edit team",
  points_short: "Pts.",
  manage_competitions: "Manage competitions",
  manage_competitions_success_changes: "Changes saved successfully",
  manage_competitions_failed_changes: "Changes has not been saved",
  active_competitions: "Active competitions",
  competition_deleted_success: "Competition removed successfully",
  round_deleted_success: "Round removed successfully",
  match_deleted_success: "Match removed successfully",
  match_delete_popup_title: "Delete match",
  match_delete_popup_text:
    "Are you sure you want to remove this match? The action is irreversible.",
  fixture_table: "Tournament bracket",
  delete_competition: "Delete competition",
  delete_round: "Delete round",
  edit_round: "Edit round",
  new_round_name: "New round name",
  delete_competition_text:
    "Are you sure you want to remove this competition? All the data will be lost.",
  edit_competition: "Edit competition",
  edit_score: "Edit score",
  no_matches_for_selected_options:
    "No match available for the selected options",

  player_tech_parameters: "Player technical parameters",
  parameters_list: "Parameters list",
  select_parameters: "Select parameters",
  teams_stats: "Team stats",
  administrate_teams: "Manage teams",
  administrate_teams_success: "Team name changed successfully!",
  administrate_teams_delete: "The team has been successfully deleted!",

  // Reports

  delete_evaluation: "Delete evaluation",
  delete_evalaution_message:
    "Are you sure you want to delete this evaluation? All related data will be lost.",
  delete_physical_test_message:
    "Are you sure you want to delete this physical test? All related data will be lost.",
  delete_category_message:
    "The action is irreversible. Are you sure you want to delete category ",
  delete_category: "Delete category",
  edit_evaluation: "Edit evaluation",
  evaluation_name: "Evaluation name",
  generate_evaluation_table: "Generate evaluation table",
  add_player_evaluation: "Add player to evaluation",
  evaluation_table: "Evaluation table",
  add_evaluation: "Add evaluation",
  players_evaluations: "Players evaluations",
  no_data_report_physical:
    "Add at least one parameter and one team, then add a player to see the rating list",
  value: "Value",
  add_report: "Add report",
  made_by: "Made by",
  player_comparison: "Player comparison",
  evaluated_params: "Evaluated parameters",
  parameter: "Parameter",
  unit_of_measure: "Unit of measure",
  evaluator_name: "Evaluator name",
  report_name: "Report name",
  parameters_added: "parameters added",
  select_parameter: "Select parameter",
  create_new_parameter: "Create new parameter",
  add_parameter: "Add parameter",
  evaluation_list: "Evaluation list",
  choose_player: "Choose a player",
  add_value: "Enter value",
  add_data: "Enter the data",
  evaluation: "Assessment",
  player_added: "Player added",
  save_report: "Save report",
  add_parameter_warning_notification_title: "Add report",
  add_parameter_warning_notification_text: "Please select a parameter.",
  add_player_warning_notification_title: "Add report",
  add_player_warning_notification_text:
    "Please select a player before adding data. If there are no players available on the list, make sure you have selected the team.",
  update_evaluation_success: "Evaluation was successfully updated!",
  update_evaluation_fail: "Evaluation wasn't successfully updated!",
  evaluation_info: "Evaluation information",
  select_evaluation_params: "Select evaluation parameters",
  select_category: "Select category",
  all_params: "All parameters",
  grade: "Grade",
  details: "Details",
  success_add_evaluation: "Evaluation was successfully added!",
  fail_add_evaluation: "Evaluation wasn't successfully added!",
  success_update_evaluation: "Evaluation was successfully updated!",
  fail_update_evaluation: "Evaluation wasn't successfully updated!",
  success_delete_evaluation: "Evaluation was successfully deleted!",
  fail_delete_evaluation: "Evaluation wasn't successfully deleted!",
  save_evaluation: "Save evaluation",
  no_evaluation: "There are no evaluation.",
  visible_in_mobile_app: "Visible in mobile app",

  // Financial
  transaction_history: "Transaction history",
  current_balance: "Current balance",
  select_interval: "Select interval",
  select_type: "Select type",
  items_on_page: "Items on page",
  total_income: "Total income",
  total_expense: "Total expense",
  receipts_stats: "Receipts stats",

  // Fan engagement
  combine_fan_profiles: "Combine fan profiles",
  filter_profiles: "Filter",
  firstname_lastname: "Name",
  phone_no: "Phone number",
  age: "Age",
  years: "years",
  select_month: "Select month",
  general_info: "General info",
  full_name: "Full name",
  birthday: "Birthday",
  fan_profile: "Fan profile",
  address: "Address",
  activity: "Activity",

  // Scouting
  scouting: "Scouting",
  saved_shortlists: "Saved shortlists",
  create_shortlist: "Create shortlist",
  shortlist_name: "Shortlist name",
  formation: "Formation",
  selected_players: "Selected players",
  date_created: "Date created",
  shortlist_url: "Shortlists",

  // Users management admin

  no_users: "No users",
  modules_access: "Modules access",
  provide_access_all_modules: "Provide access for all modules",
  users_management: "Users management",
  users_management_menu: "Users management",
  account_creation_date: "Account creation date",
  team_access: "Team access",
  access_module: "Access module",
  add_user: "Add user",
  active_user_success: "User is now active",
  inactive_user_success: "User is now inactive",
  activate: "Activează",
  disable: "Disable",
  activate_user: "Activate user",
  disable_user: "Disable user",
  disable_user_confirmation: "Are you sure you want to disable this user?",
  activate_user_confirmation: "Are you sure you want to activate this user?",
  delete_user: "Delete user",
  delete_user_confirmation:
    "Are you sure you want to delete this user? All related data will be lost",
  delete_user_success: "User has been successfully deleted",
  delete_user_danger: "User has not been deleted",
  yes: "Yes",
  no: "No",
  wrong_phone_number: "Wrong phone number",
  wrong_email: "Wrong email",
  all_teams: "All teams",
  some_teams: "Some teams",
  access_all_teams: "All teams",
  access_some_teams: "Some teams",
  access_no_teams: "No team",
  edit_user: "Edit user",
  apply: "Apply",
  edit_user_success: "User was successfully updated",
  edit_user_danger: "User was not updated",

  //Products
  add_product: "Add product",
  product_image: "Product photo",
  product_name: "Product name",
  product_description: "Product description",
  product_price: "Price",
  product_status: "Status",
  product_actions: "Actions",
  product_is_active: "Product is active",
  product_delete_confirmation: "Delete confirmation",
  product_delete_message:
    "Are you sure you want to delete this product? The action is irreversible.",
  product_edit: "Edit product",
  active: "Active",
  inactive: "Inactive",
  no_products: "No products found",
  add_product_name: "Add product name",
  add_product_description: "Add product description",
  add_product_price: "Add product price",
  choose_categories: "Choose categories",
  choose_sizes: "Choose sizes",
  success_add_product: "Product was successfully added!",
  fail_add_product: "Product wasn't successfully added!",
  success_update_product: "Product was successfully updated!",
  fail_update_product: "Product wasn't successfully updated!",
  success_delete_product: "Product was successfully deleted!",
  fail_delete_product: "Product wasn't successfully deleted!",

  //Categories
  add_category: "Add category",
  edit_category: "Edit category",
  category_image: "Category photo",
  category_name: "Category name",
  add_category_name: "Add category name",
  category_actions: "Actions",
  category_is_active: "Category is active",
  category_delete_confirmation: "Delete confirmation",
  category_delete_message:
    "Are you sure you want to delete this category? The action is irreversible.",
  category_edit: "Edit category",
  no_categories: "No found categories",
  success_add_category: "Category was successfully added!",
  fail_add_category: "Category wasn't successfully added!",
  success_update_category: "Category was successfully updated!",
  fail_update_category: "Category wasn't successfully updated!",
  success_delete_category: "Category was successfully deleted!",
  fail_delete_category: "Category wasn't successfully deleted!",
  without_image: "Without photo",
  select_one_or_more_group: "Select one or more groups...",

  //Orders
  order_id: "ID",
  order_date: "Order date",
  order_customer: "Customer",
  order_shipping_method: "Shipping method",
  order_payment_method: "Payment method",
  order_status: "Status",
  order_amount: "Amount",
  order_actions: "Actions",
  order_details: "Order details",
  order_product: "Product",
  order_quantity: "Quantity",
  order_unit_price: "Unit price",
  order_wrapper: "Wrapper",
  order_vat: "VAT",
  order_subtotal: "Subtotal",
  order_phone: "Phone number",
  order_mail: "E-mail",
  order_address: "Address",
  order_message: "Order message",
  order_payment_type: "Payment type",
  order_delivery_cost: "Delivery cost",
  order_cancel: "Anulare comanda",
  order_cancel_message:
    "Are you sure you want to cancel the order? Please state your reason below:",
  order_cancel_reason: "Reason...",
  order_cancel_dismiss: "Dismiss",
  order_delete_alert: "You must cancel the order before it can be deleted",
  no_orders: "No orders found",
  order_delivery_types: {
    delivery: "Delivery",
    pickup: "Pickup",
  },
  order_payment_types: {
    online: "Online",
    cash: "Cash",
  },
  confirm_order: "Confirmed",
  cancel_order: "Cancel",
  close_order: "Close",
  deliver_order: "Delivered",
  order_delete_message:
    "Are you sure you want to delete this order? The action is irreversible.",
  success_delete_order: "Order was successfully deleted!",
  fail_delete_order: "Order wasn't successfully deleted!",
  success_confirm_order: "Order was successfully confirmed!",
  fail_confirm_order: "Order wasn't successfully confirmed!",
  success_cancel_order: "Order was successfully canceled!",
  fail_cancel_order: "Order wasn't successfully canceled!",
  success_deliver_order: "Order was successfully delivered!",
  fail_deliver_order: "Order wasn't successfully delivered!",

  //Sizes
  sizes: "Sizes",
  size: "Size",
  add_size: "Add size",
  edit_size: "Edit size",
  size_delete_message:
    "Are you sure you want to delete this size? The action is irreversible.",
  no_sizes: "No sizes found",
  add_size_name: "Add size",
  success_add_size: "Size was successfully added!",
  fail_add_size: "Size wasn't successfully added!",
  success_update_size: "Size was successfully updated!",
  fail_update_size: "Size wasn't successfully updated!",
  success_delete_size: "Size was successfully deleted!",
  fail_delete_size: "Size wasn't successfully deleted!",

  // Months
  month_1: "january",
  month_2: "february",
  month_3: "march",
  month_4: "april",
  month_5: "may",
  month_6: "june",
  month_7: "july",
  month_8: "august",
  month_9: "september",
  month_10: "october",
  month_11: "november",
  month_12: "december",

  //=========================================================================================================== Old translations

  empty_folder: "This folder is empty.",
  no_documents: "There are no documents.",
  edit_folder: "Edit folder",
  folder_name: "Folder name",
  edit_folder_success: "The folder was successfully edited!",
  edit_folder_fail: "The folder wasn't successfully edited!",
  delete_folder: "Delete folder",
  delete_folder_confirmation:
    "Are you sure you want to delete this folder? All related data will be lost.",
  success_delete_folder: "Folder was successfully deleted!",
  fail_delete_folder: "Folder wasn't successfully deleted!",
  edit_document: "Edit document",
  document_name: "Document name",
  edit_document_success: "The document was successfully edited!",
  edit_document_fail: "The document wasn't successfully edited!",
  delete_document_confirmation:
    "Are you sure you want to delete this document? All related data will be lost.",
  success_delete_document: "Document was successfully deleted!",
  fail_delete_document: "Document wasn't successfully deleted!",
  add_new_file: "Add new file",
  create_new_folder: "Create new folder",
  add_folder_success: "The folder was successfully added!",
  add_folder_fail: "The folder wasn't successfully added!",
  upload_file_success: "The file was successfully added!",
  upload_file_fail: "The file wasn't successfully added!",

  test: "test",
  Name: "Name",
  sponsor_contracts: "Sponsor contracts",
  contracts: "Contracts",
  bonuses: "Bonuses",
  player_contract: "Player contract",
  add_player_contract: "Add player contract",
  file_name: "File name",
  category: "Category",
  date_add: "Date added",
  added_by: "Added by",
  added: "Added",
  new_member: "New Member",
  new_report: "New Report",
  players: "Players",
  goalkeepers: "Goalkeepers",

  add_new_member: "Add new member",

  no_team: "No team",
  find_a_player: "Find a player",
  Nationality: "Nationality",
  League: "League",
  Age: "Age",
  Reset_filters: "Reset filters",
  Advanced_search: "Advanced search ",
  Add_to_My_List: "Add to my list ",

  Order_by: "Order by",
  Discover_more: "Discover More ",
  My_list: "My list",
  Moneda: "Currency",
  Cashflow: "Cashflow",
  There_are_no_records_to_display: "There are no records to display ",
  Bonuses: "Bonuses",
  Currency: "Currency",
  Cancel: "Cancel",
  Add: "Add",
  PROFIT: "PROFIT",
  SPENDING: "Spending",
  Add_category_to: "Add category to",
  Players: "Players",
  CashFlow: "Cashflow",
  transfer_history: "Transfer history",
  overview: "Overview",
  current_season: "Current season",
  advanced_standings: "Advanced standings",
  fixtures_schedule: "Fixtures",
  team_statistics: "Team statistics",
  player_search: "Player search",
  search: "Search...",
  show: "Display",
  extra_notes: "Extra notes:",
  choose_categ: "Choose category",
  new_categ: "New category name...",
  active_crtierias: "Active criterias:",
  Position: "Position",
  see_results: "See results",
  test_name: "Test name",
  evaluation_date: "Evaluation date",
  evaluation_year: "Year",
  see_test_details: "See test details",
  players_eval: "Evaluated players",
  latest_reports: "Latest reports",

  see_more: "See more",

  Login_Account: "Login Account",
  Welcome_to: "Welcome to",
  Privacy: "Privacy",
  Legal: "Legal",
  Contact: "Contact",
  Enter_your_username_and_password: "Enter your username and password",

  Sign_In: "Sign In",
  Dont_have_an_account_yet: "Don't have an account yet?",
  Sign_Up_1: "Sign Up!",
  Enter_your_details_to_create_your_account:
    "Enter your details to create your account",
  Full_name: "Full name",
  Email: "Email",
  User_name: "User name",
  Password: "Password",
  Confirm_Password: "Confirm Password",
  I_aggree_with_terms_and_conditions: "I agree the Terms & Conditions",
  Choose_image: "Choose image",
  image: "Image",
  video: "Video",
  videos: "Videos",
  Submit: "Submit",
  Required_field: "Required field",
  Password_and_Confirm_Password_didnt_match:
    "Password and Confirm Password didn't match",

  The_login_detail_is_incorrect: "The login detail is incorrect",

  Add_team_bonus: "Add team bonus",
  Add_players_bonus: "Add players bonus",
  Bonus_history: "Bonus history",
  Total_sum: "Total amount",
  Bonus_name: "Bonus name",
  Bonus_type: "Bonus Type",
  Bonus_condition: "Bonus Condition",
  Payment_status: "Payment status",
  Date_added: "Date added",
  EUR: "EUR",
  RON: "RON",
  Fullfiled: "Fulfilled",
  In_pending: "Pending",
  Paid: "Paid",
  Delete: "Delete",
  Change_status: "Change status",
  Add_bonus: "Add bonus",
  For_a_match: "For a match",
  Selected_step: "Selected match week",
  Totol_assigned_sum: "Total assigned sum",
  Back: "Back",
  Bonus_beneficiary: "Bonus beneficiary",
  Competition: "Competition",
  For_the_full_season: "For the entire season",
  Select_match: "Select match",
  Assignment_criteria: "Assignment criteria",
  Bonus_position: "Reach the first ... places",
  all_competitions: "All competitions",
  placeholder_documents: "No documents uploaded yet",

  Assignment_criterias: "Criteria",
  Select_bonus_type: "Bonus type",
  select_cup_phase: "Cup phase...",

  Ranking: "Position",
  Custom_duration: "Custom duration",

  Ranking_the_first_n_places: "Ranking in the first ... positions",
  Qualification_in_the_play_off: "Reach play-off",
  Ranking_for_european_cup: "Placement on European Cups position",
  Ranking_for_uefa_champions_league:
    "Placement on UEFA Champions League position",
  Qualification_in_the_romanian_cup: "Placement within Romanian Cup",
  Competiion_final: "Final",
  Competiion_semi_final: "Semifinal",
  Competiion_quarters_final: "Quarter-final",
  Competiion_eighths_final: "Last 16",
  Competiion_sixteeths_final: "Last 32",
  All_players: "All players",
  Player_holders: "Starting lineup",
  Player_and_Staff: "Players & Staff",
  Individual: "Individual",
  First_league: "First league",
  Romanian_Cup: "Romanian Cup",
  Romanian_supercup: "Romanian Supercup",
  Goal_scored: "Goal scored",
  Other_crieteria: "Other criteria",
  Staff_bonus: "Staff",
  Another_bonus: "Another receiver",

  Final_result_win: "Final result: Win",
  Final_result_draw: "Final result: Draw",
  Next: "Next",

  Add_contract_details: "Contract details",
  Contract_extra: "Eextra conditions",
  Upload_file: "Upload file",
  player_name: "Player name",
  contract_period: "Contract period",
  Other: "Other",
  choose_team: "Choose team",
  team: "Team",
  average_rating: "Average rating",
  show_on_loan: "Players on loan",

  one_year: "One year",
  one_year_half: "One year and a half",
  two_years: "Two years",
  two_years_half: "Two years and a half",
  three_years: "Three years",
  three_years_half: "Three years and a half",
  four_years: "Four years",
  four_years_half: "Four years and a half",
  five_years: "Five years",
  five_years_half: "Five years and a half",

  sure_delete_event:
    "Are you sure you want to delete the event from the match ?",

  add_new_event: "Add new event",

  event_category: "Select category",

  ending_date: "Ending date",
  year: "Year",
  month: "Month",
  week: "Week",
  day: "Day",
  today: "Today",
  new_categ_name: "New category name",
  club_management: "Club management",

  success_added_player: "Player was successfully added!",
  fail_added_player: "Player wasn't successfully added!",
  category_color: "Color",
  edit_categories: "Edit categories",
  no_country: "No country",

  physical_reports: "Physical reports",
  technical_reports: "Technical reports",
  see_profile: "See profile",

  name: "Name",
  partner_name: "Partner name",
  unpaid_amount: "Left to pay",
  paid_amount: "Paid amount",
  last_payment: "Last payment date",
  deadline_date: "Deadline date",

  "Players & Staff": "Players & Staff",
  "Starting lineup": "Starting lineup",
  "All the players": "All the players",

  this_will_overwrite_your_preferred_currency:
    "The current action will change the selected currency from your account settings. Are you sure?",
  Confirm: "Confirm",

  "Left shoulder": "Left shoulder",
  "Right shoulder": "Right shoulder",
  "Left knee": "Left knee",
  "Right knee": "Right knee",
  "Left thigh": "Left thigh",
  "Right thigh": "Right thigh",
  "Left shin": "Left shin",
  "Right shin": "Right shin",

  Head: "Head",
  "Left ankle": "Left ankle",
  "Right ankle": "Right ankle",
  "Another zone": "Another zone",

  Developer: "Developer",
  "Team manager": "Team manager",
  Antrenor: "Antrenor",
  "Antrenor cu portarii": "Antrenor cu portarii",
  Presedinte: "Presedinte",
  "Preparator fizic": "Preparator fizic",
  "Manager IT": "Manager IT",
  "Manager Academia Poli Iasi": "Manager Academia Poli Iasi",

  Goalkeeper: "Goalkeeper",
  "Centre-Back": "Centre-Back",
  "Left-Back": "Left-Back",
  "Right-Back": "Right-Back",
  "Defensive Midfield": "Defensive Midfield",
  "Central Midfield": "Central Midfield",
  "Right Midfield": "Right Midfield",
  "Left Midfield": "Left Midfield",
  "Attacking Midfield": "Attacking Midfield",
  "Left Winger": "Left Winger",
  "Right Winger": "Right Winger",
  "Second Striker": "Second Striker",
  "Centre-Forward": "Centre-Forward",
  ConnectionCannotBeRefreshed:
    "The next refresh attempt will be allowed according to next refresh possible time or connection's status is inactive.",
  cur_: "",
  cur_Th: "T",
  cur_M: "M",
  cur_B: "B",
  cur_T: "T",

  "All documents": "All documents",
  "Players contracts": "Players contracts",
  "Sponsor contracts": "Sponsor contracts",

  //start
  Remove_from_my_list: "Remove from my list",
  Budget: "Budget",
  fast_search: "Fast search",
  video_search: "Search video by name",
  total: "Total",
  auth_page_left_description: "",
  no_item: "No item.",
  Coach: "Coach",
  "Goalkeepers coach": "Goalkeepers coach",
  President: "President",
  "Physical trainer": "Physical trainer",
  "IT Manager": "IT Manager",
  "Poli Iasi Academy Manager": "Poli Iasi Academy Manager",
  Bills: "Facturi",
  Various: "Diverse",
  "Training scheme": "Training scheme",

  left_shoulder: "Left shoulder",
  right_shoulder: "Right shoulder",
  left_knee: "Left knee",
  right_knee: "Right knee",
  left_thigh: "Left thigh",
  right_thigh: "Right thigh",
  left_shin: "Left shin",
  right_shin: "Right shin",
  back: "Back",
  head: "Head",
  left_ankle: "Left ankle",
  right_ankle: "Right ankle",
  left_biceps_femoris: "Left biceps femoris",
  right_biceps_femoris: "Right biceps femoris",
  another_zone: "Another zone",

  ranking_position: "Standing position",

  no_staff_members_yet: "No staff members found",

  "GENERAL.SOMETHING_WENT_WRONG":
    "Ups! Something went wrong. Please try again later.",
  Error: "Error",

  Back_button: "Back",

  FORM_CONTROL: {
    "TRANSLATOR.SELECT": "Select your language",
    "MENU.NEW": "new",
    "MENU.ACTIONS": "Actions",
    "MENU.CREATE_POST": "Create New Post",
    "MENU.PAGES": "Pages",
    "MENU.FEATURES": "Features",
    "MENU.APPS": "Apps",
    "MENU.DASHBOARD": "Dashboard",
    "AUTH.GENERAL.OR": "Or",
    "AUTH.GENERAL.SUBMIT_BUTTON": "Submit",
    "AUTH.GENERAL.NO_ACCOUNT": "Don't have an account?",
    "AUTH.GENERAL.SIGNUP_BUTTON": "Sign Up",
    "AUTH.GENERAL.FORGOT_BUTTON": "Forgot Password",
    "AUTH.GENERAL.BACK_BUTTON": "Back",
    "AUTH.GENERAL.CANCEL_BUTTON": "Cancel",
    "AUTH.GENERAL.PRIVACY": "Privacy",
    "AUTH.GENERAL.LEGAL": "Legal",
    "AUTH.GENERAL.CONTACT": "Contact",
    "AUTH.LOGIN.TITLE": "Login Account",
    "AUTH.LOGIN.BUTTON": "Sign In",
    "AUTH.FORGOT.TITLE": "Forgotten Password?",
    "AUTH.FORGOT.DESC": "Enter your email to reset your password",
    "AUTH.FORGOT.SUCCESS": "Your password has been successfully reset.",
    "AUTH.REGISTER.TITLE": "Sign Up",
    "AUTH.REGISTER.DESC": "Enter your details to create your account",
    "AUTH.REGISTER.SUCCESS": "Your account has been successfuly registered.",
    "AUTH.INPUT.EMAIL": "Email",
    "AUTH.INPUT.FULLNAME": "Fullname",
    "AUTH.INPUT.PASSWORD": "Password",
    "AUTH.INPUT.CONFIRM_PASSWORD": "Confirm Password",
    "AUTH.INPUT.USERNAME": "Username",
    "AUTH.VALIDATION.INVALID": "{name} is not valid",
    "AUTH.VALIDATION.REQUIRED": "{name} is required",
    "AUTH.VALIDATION.MIN_LENGTH": "Email minimum length is 3",
    "AUTH.VALIDATION.MAX_LENGTH": "Email max length is 50",
    "AUTH.VALIDATION.AGREEMENT_REQUIRED":
      "Accepting terms & conditions are required",
    "AUTH.VALIDATION.NOT_FOUND": "The requested {name} is not found",
    "AUTH.GENERAL.USER_NOT_FOUND": "User not found",
    "AUTH.GENERAL.USER_INACTIVE": "User is inactive",
    "AUTH.VALIDATION.INVALID_LOGIN": "The login detail is incorrect",
    "AUTH.VALIDATION.INVALID_EMAIL": "Email invalid",
    "AUTH.GENERAL.SOMETHING_WENT_WRONG":
      "Ups! Something went wrong. Please try again later.",
    "AUTH.VALIDATION.NO_USER_WITH_EMAIL":
      "The email is not used by any active user",
    "AUTH.VALIDATION.REQUIRED_FIELD": "Required field",
    "AUTH.VALIDATION.MIN_LENGTH_FIELD": "Minimum field length:",
    "AUTH.VALIDATION.MAX_LENGTH_FIELD": "Maximum field length:",
    "AUTH.VALIDATION.INVALID_FIELD": "Field is not valid",
    "ECOMMERCE.COMMON.SELECTED_RECORDS_COUNT": "Selected records count: ",
    "ECOMMERCE.COMMON.ALL": "All",
    "AUTH.RESET_PASSWORD.TITLE": "Reset Password",
    "AUTH.RESET_PASSWORD.TOKEN_EXPIRED": "Token expired",
    "AUTH.GENERAL.EMPTY_TOKEN": "Token is empty",
    "ECOMMERCE.COMMON.SUSPENDED": "Suspended",
    "ECOMMERCE.COMMON.ACTIVE": "Active",
    "ECOMMERCE.COMMON.FILTER": "Filter",
    "ECOMMERCE.COMMON.BY_STATUS": "by Status",
    "ECOMMERCE.COMMON.BY_TYPE": "by Type",
    "ECOMMERCE.COMMON.BUSINESS": "Business",
    "ECOMMERCE.COMMON.INDIVIDUAL": "Individual",
    "ECOMMERCE.COMMON.SEARCH": "Search",
    "ECOMMERCE.COMMON.IN_ALL_FIELDS": "in all fields",
    "ECOMMERCE.ECOMMERCE": "eCommerce",
    "ECOMMERCE.CUSTOMERS.CUSTOMERS": "Customers",
    "ECOMMERCE.CUSTOMERS.CUSTOMERS_LIST": "Customers list",
    "ECOMMERCE.CUSTOMERS.NEW_CUSTOMER": "New Customer",
    "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.TITLE": "Customer Delete",
    "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.DESCRIPTION":
      "Are you sure to permanently delete this customer?",
    "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.WAIT_DESCRIPTION":
      "Customer is deleting...",
    "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.MESSAGE":
      "Customer has been deleted",
    "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.TITLE": "Customers Delete",
    "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.DESCRIPTION":
      "Are you sure to permanently delete selected customers?",
    "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.WAIT_DESCRIPTION":
      "Customers are deleting...",
    "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.MESSAGE":
      "Selected customers have been deleted",
    "ECOMMERCE.CUSTOMERS.UPDATE_STATUS.TITLE":
      "Status has been updated for selected customers",
    "ECOMMERCE.CUSTOMERS.UPDATE_STATUS.MESSAGE":
      "Selected customers status have successfully been updated",
    "ECOMMERCE.CUSTOMERS.EDIT.UPDATE_MESSAGE": "Customer has been updated",
    "ECOMMERCE.CUSTOMERS.EDIT.ADD_MESSAGE": "Customer has been created",
  },

  verification_code: "Verification code",
  insert_verification_code:
    "Insert the 6-digit code sent to the phone number associated with this account",
  verification_code_no_receive_message: "Did not receive message?",
  verification_code_send_again: "Send again",

  auth_code_expired: "Authentication code expired",
  auth_code_incorrect: "Authentication code incorrect",
  auth_code_try_again: "Authentication error. Please try again.",

  team_types_list: "Team types",
  team_type_add: "Add new type",
  team_type_add_short: "Add type",

  team_type_notification_title: "Club team types",
  team_type_notification_succes: "Team type added successfully!",
  team_type_notification_fail: "Selected type has been successfully removed!",

  tm_link_team: "Transfermarkt link (optional)",
  minutes_played_uppercase: "Minutes played",
  all_fields_mandatory_error: "All the fields are mandatory",
  no_sections_added_error: "You must add at least one training section",
  rec_date_not_before_inj_date: "Recovery date can't be before injury date",
  documents_upload_success: "You have added a new file!",

  ACCESS_FORBIDDEN_ERROR: "Access forbidden",
  new_category_empty_error: "New category name can't be empty",
  delete_team: "Delete team",
  season_date_start_add_team: "Current season start date",
  season_date_end_add_team: "Current season end date",
  delete_team_message:
    "Are you sure you want to delete this team? All players and related data will be lost.",
  deleteTeamMessage2: "All players and related data will be lost.",

  auth_error_title: "Authentication error",
  auth_error_message: "Please check the entered data and try again",
  reset_error_title: "Reset password error",
  hello: "Hello",
  password_reset_email: "Your new password is",

  import_fans_title: "Import data about fans",
  select_fields_to_save: "Select the fields to be saved",
  enter_source_name: "Enter the source name",
  enter_delimiter: "Enter the delimiter",
  sample_name_fans_file: "e.g: Newsletter subscribers",
  associated_with: "Associated with",
  activity_date: "Date of the activity",
  column: "Colomn",
  social_media_activity: "Social media activity",

  // Costs

  add_transaction: "Add transaction",
  select_cost_category: "Select cost category",
  add_new_cost_category: "Add new cost category",
  costs_category: "Cost category",
  edit_departaments_list: "Edit departments list",
  add_departament: "Add department",
  edit_departament: "Edit department",
  department_name: "Department name",
  add_spending: "Add spending",
  department: "Department",
  subdepartment: "Subdepartment",

  tags: "Tags",
  date_format: "dd/mm/yyyy",
  player: "Player",
  apply_filters: "Apply filters",
  departments_list: "Departments list",
  has_subdepartments: "Has subdepartments",
  subdepartment_designation: "Subdepartment name",
  add_new_subdepartment: "Add new substartment",
  add_tag: "Add tag",
  entity_tag: "entity tag",
  staff: "Staff",
  select: "Select",
  select_department: "Select department",
  select_subdepartment: "Select subdepartment",
  select_tag_type: "Select tag type",
  no_costs: "No costs found",

  success_add_department: "Department was successfully added!",
  fail_add_department: "Department wasn't successfully added!",
  no_departments: "No departments found",
  success_update_department: "Department was successfully updated!",
  fail_update_department: "Department wasn't successfully updated!",
  success_delete_department: "Department was successfully deleted!",
  fail_delete_department: "Department wasn't successfully deleted!",
  department_delete_message:
    "Are you sure you want to delete this department? The action is irreversible.",
  success_add_cost: "New cost was successfully added!",
  fail_add_cost: "New cost wasn't successfully added!",
  success_update_cost: "Cost was successfully updated!",
  fail_update_cost: "Cost wasn't successfully updated!",
  cost_delete_message:
    "Are you sure you want to delete this cost? The action is irreversible.",
  success_delete_cost: "Cost was successfully deleted!",
  fail_delete_cost: "Cost wasn't successfully deleted!",
  delete_cost: "Delete cost",
  delete_cost_message:
    "The action is irreversible. Are you sure you want to delete the cost ",

  // necessity reports
  add_necessity_report: "Add report",
  criteria_number: "Criteria number",
  registration_number: "Registration number",
  purchase_category: "Purchase category",

  // receipt
  download_receipt: "Download receipt",
  no_data_receipt: "There are no online payments.",

  // mobile app admin
  mobile_app: "Mobile app",
  copied_to_clipboard: "Copied to clipboard",
  fail_copy_to_clipboard: "Failed to copy to clipboard",
  add_parent: "Add parent",
  edit_player: "Edit player",
  edit_relative: "Edit relative",
  player_players_name: "Player/Players name",
  current_status_payment: "Recurrent payment status",
  first_name: "First name",
  last_name: "Last name",
  player_relative: "Player relative",
  notif_edit_external_user: "Edit mobile user",
  edit_user_success_message: "User has been edited successfully !",
  edit_user_error_message: "User has not been edited !",
  delete_relative: "Delete relative",
  delete_mobile_user_text:
    "Are you sure you want to delete this user? All related data will be lost.",
  notif_delete_mobile_user_title: "Delete mobile user",
  notif_delete_mobile_user_message: "User has been deleted successfully !",
  notif_delete_mobile_user_failed_message: "User has not been deleted!",
  desactivate_user: "Desactivate mobile user",
  desactivate_user_popup_text: "Are you sure you want to disable this user?",
  desactivate: "Desactivate",
  activate_user_popup_text: "Are you sure you want to activate this user?",

  notif_desactivate_mobile_user_success_message:
    "User has been successfully deactivated!",
  notif_desactivate_mobile_user_failed_message:
    "User has not been deactivated!",
  notif_activate_mobile_user_success_message:
    "User has been successfully activated!",
  notif_activate_mobile_user_failed_message: "User has not been activated!",

  no_valid_user_key: "There no valid data for user account activation!",

  add_mobile_app_user_success: "User has been successfully added!",
  add_mobile_app_user_fail: "User hasn't been successfully added!",

  // users notifications
  next_step: "Next step",
  previous_step: "Previous step",
  send_notification: "Send notification",
  create_notification: "Create notification",
  sent_notification: "Sent notification",
  date_hour_of_send: "Date and hour of send",
  notification_send_by: "Send by",
  notification_title: "Title of notification",
  notification_message: "Notification message",
  notification_group: "Group",
  plyers_names: "Players names",
  audience: "Audience",
  delete_notification: "Delete notification",
  delete_notification_message:
    "Are you sure you want to delete this notification?",
  details_notification_sent: "Details notification sent",
  step_one_selecte_audience: "Step 1 - select audience",
  all_groups: "All groups",
  step_two_select_audience: "Step 2 - write message",
  your_message: "Your message",

  notification_title_message: "User notification",
  notification_sucess_message: "Notification was successfully sent !",
  notification_failed_message: "Notification sending has failed !",
  notification_delete_sucess_message: "Notification was successfully deleted !",
  notification_delete_failed_message: "Notification deleting has failed !",

  no_notification: "There are no notification.",
  send_custom_notification: "Send custom notification",
  settings_automatic_notification: "Settings automatic notification",
  send_notification_day: "Day of sending notification",
  send_notification_for: "Send notification for",

  send_notification_day_options: {
    first: "First day of month",
    middle: "The 15th day of month",
    last: "Last day of month",
  },

  send_notification_for_options: {
    previous: "Previous month",
    current: "Current month",
    next: "Next month",
  },

  success_save_notification_settings:
    "Notification settings was successfully saved!",
  fail_save_notification_settings:
    "Notification settings wasn't successfully saved!",

  documents_to_sign: "Documents to sign",
  create_document: "Create document",
  document_title: "Document name",
  signed_by: "Signed by",
  document_status: "Document status",
  no_documents_to_sign: "No documents to sign.",
  step: "Step",
  contract: "Contract",
  contract_annex: "Contract annex",
  document_type: "Document type",
  document_will_be: "Document will be:",
  document_active: "Active",
  document_archived: "Archived",
  success_generate_document: "Document was successfully generated!",
  fail_generate_document: "Document wasn't successfully generated!",
  success_save_document: "Document was successfully saved!",
  fail_save_document: "Document wasn't successfully saved!",
  download_view_document: "View / download document",
  delete_player_document_to_sign: "Delete player from document",
  delete_player_document_to_sign_message:
    "Are you sure that you want to delete the player from document list? The action is irreversible.",
  add_auxiliary_person: "Add auxiliary person",
  add_new_auxiliary_person: "Add new auxiliary person",
  select_auxiliary_person: "Select auxiliary person",
  auxiliary_person_name: "Auxiliary person name",
  success_add: "The adding action was successfully done!",
  fail_add: "The adding action wasn't successfully done!",
  success_delete: "The deleting action was successfully done!",
  fail_delete: "The deleting action wasn't successfully done!",
  auxiliary_persons: "Auxiliary persons",
  delete_auxiliary_person_document_to_sign:
    "Delete auxiliary person from document",
  delete_auxiliary_person_document_to_sign_message:
    "Are you sure that you want to delete the auxiliary person from document list? The action is irreversible.",

  // ----------------------------------Payments

  date_hour_of_payment: "Date and time of payment",
  payment_month: "Payment month",
  payment_month_description: "Payment month / Description",
  payer: "Payer",
  payment_method: "Payment Method",
  payments_title: "Payments",
  table_name: "Name",
  table_months: {
    jan: "January",
    feb: "February",
    mar: "March",
    apr: "April",
    may: "May",
    jun: "June",
    jul: "July",
    aug: "August",
    sep: "September",
    oct: "October",
    nov: "November",
    dec: "December",
  },
  add_payment_title: "Add payment for player ",
  add_payment_select_month: "Select month",
  add_payment_amount: "Amount",
  add_payment_type: {
    cash: "Cash",
    bank_transfer: "Bank transfer",
  },
  add_payment_date: "Payment date",
  add_payment_description: "Description (optional)",
  add_payment_button_add: "Add payment",
  add_payment_button_cancel: "Cancel",
  edit_payments_title: "Edit payments for player ",
  edit_payments_empty: "There no payments.",
  add_payment_overwrite_first:
    "Are you sure that you want to overwrite data about payment in ",
  add_payment_overwrite_second: " for player ",
  add_payment_button_overwrite: "Yes, overwrite payment",
  delete_payment_question: "Are you sure that you want to delete the payment?",
  delete_payment_button_delete: "Yes, delete",
  payment_successfully_added: "Payment added successfully!",
  payment_successfully_deleted:
    "The selected payment has been successfully removed!",
  payment_fail_deleted:
    "The selected payment hasn't been successfully removed!",
  monthly_tax: "Monthly tax",
  success_add_payment: "Payment was successfully added!",
  fail_add_payment: "Payment wasn't successfully added!",
  delete_payment: "Delete payment",
  payment_management: "Payment management",
  generate_debtor_pdf_report: "Generate debtor PDF report",
  generate_pdf_report: "Generate  PDF report",
  generate_attendance_pdf_report: "Generate attendance PDF report",
  special_monthly_tax_for_year: "Conditions for the monthly fee for the year ",

  //Match - notification message
  matches_module: "Matches",
  success_save_players_rating: "Players rating was successfully saved!",
  fail_save_players_rating: "Players rating wasn't successfully saved!",
  the_match_lot_was_successfully_saved:
    "The match squad was successfully saved!",
  fail_the_match_lot_was_successfully_saved:
    "The match squad was not successfully saved!",
  the_player_has_been_removed_from_the_squad:
    "The player has been removed from the squad !",
  fail_the_player_has_been_removed_from_the_squad:
    "The player has not been removed from the squad !",
  players_successfully_added_to_the_squad:
    "Players successfully added to the squad !",
  players_failed_added_to_the_squad: "Players failed added to the squad !",

  //Receipt
  success_save_receipt: "The receipt was successfully modified!",
  fail_save_receipt: "The receipt wasn't successfully modified!",
  success_delete_receipt: "The receipt was successfully deleted!",
  fail_delete_receipt: "The receipt wasn't successfully deleted!",

  num_and_date_receipt: "Number and issue date",
  edit_receipt: "Edit receipt",
  receipt_number: "Receipt number",
  issue_date: "Issue date",
  payer_name: "Payer name",
  payment_name: "Payment name",
  receipt_description: "Receipt description",
  delete_receipt: "Delete receipt",
  delete_receipt_message:
    "Are you sure that you want to delete the receipt? The action is irreversible.",
  delete_document_message:
    "Are you sure that you want to delete the document? The action is irreversible.",

  // STATISTICS GOALS
  goals_statistics: "Golas statistics",
  show_statistics: "Show stats",
  invalid_period_time: "Start date must be greater than end date",

  free_kick: "Free Kick",
  cross: "Cross",
  direct_play: "Direct Play",
  combination_play: "Combination",
  counterattack: "Counterattack",
  penalty: "Penalty",
  individual_action: "Individual action",
  opposite_third_recovery: "Opposite third recovery",
  another: "Others",

  scored_goals_first_half: "Goals first half",
  scored_goals_second_half: "Goals second half",
  conceded_goals_first_half: "Goals first half",
  conceded_goals_second_half: "Goals second half",

  scored_goals_by_category: "Scoared goals by category",
  conceded_goals_by_category: "Conceded goals by category",
  scored_goals_per_half: "Scored goals / half",
  conceded_goals_per_half: "Conceded goals / half",
  "-": "-",
  no_data_found: "-",

  //WEBSITE SETTINGS
  website_settings: "Website settings",
  teams_settings: "Teams settings",
  staff_settings: "Staff settings",
  online_payments_settings: "Online payments settings",
  enable_online_payments_module: "Enable online payments module on site",
  only_online_payments: "Only online payments page",
  iframe: "Enable <i>iframe</i> mode for online payments page",
  stripe_fee_passed_to_customer: "Stripe fee is passed to customer",
  client_edit_monthly_tax: "Monthly tax can be edited by client",

  payment_types_settings: "Payment types settings",
  enable_another_tax: "Show <i>Another payment</i> option",
  enable_payment_request: "Show <i>Payment requests</i> option",

  enrolment_request_settings: "Enrolment request settings",
  enable_enrolment_form: "Enable enrolment request module on site",
  privacy_policy: "Privacy policy",

  // Enrolment requests
  enrolment_request: "Enrolment request",
  edit_request: "Edit request",
  child_name: "Child name",
  child_surname: "Child surname",
  request_date: "Request date",
  adult_name: "Adult name",
  adult_surname: "Adult surname",
  child: "Child",
  city: "City",
  no_requests: "No requests",
  delete_request: "Delete request",
  delete_request_message: "You are sure you want to delete this request?",
  accept: "Accept",
  success_enrolment_request: "Request has been accepted",
  failed_enrolment_request: "Request has not been accepted",

  //PAYMENT REQUEST
  payment_request: "Payment request",
  payment_requests: "Payment requests",
  payers: "Platitori",
  no_payment_request: "There are no payment requests.",

  add_payment_request: "Add payment request",
  add_payment_details: "Enter payment details",
  payment_request_title: "Payment name",
  enter_payment_request_title: "Enter name",
  payment_request_amount: "Payment value",
  enter_payment_request_amount: "Enter value",
  choose_players: "Choose players",
  all_players: "All players",

  success_add_payment_request: "The payment request was successfully added!",
  fail_add_payment_request: "The payment request was successfully added!",

  success_delete_payment_request:
    "The payment request was successfully deleted!",
  fail_delete_payment_request:
    "The payment request wasn't successfully deleted!",
  delete_payment_request: "Delete payment request",
  delete_payment_request_message:
    "Are you sure that you want to delete the payment request? The action is irreversible.",

  amount_to_pay: "Amount to be collected",
  amount_received: "Amount received",
  payment_types: {
    cash: "cash",
    bank_transfer: "bank tansfer",
    parents_app: "parents app",
    online_website: "online website",
    recurrence: "recurrent payment",
  },
  add_players: "Add players",

  success_add_players_to_payment_request:
    "The players were successfully added!",
  fail_add_players_to_payment_request:
    "The players weren't successfully added!",

  edit_amount_to_pay: "Edit amount to pay",
  edit_payment_request: "Edit payment request",

  add_payment: "Add payment",
  payment_type: "Payment type",
  choose_payment_type: "Choose payment type",
  payment_date: "Payment date",
  choose_payment_date: "Choose payment date",

  success_edit_payment_request_amount: "The amount was successfully updated!",
  fail_edit_payment_request_amount: "The amount wasn't successfully updated!",

  delete_player_payment_request: "Delete payment request for player",
  delete_player_payment_request_message:
    "Are you sure that you want to delete the payment request for player? The action is irreversible.",

  // MATCH SQUAD
  match_squad: "Match squad",
  save_match_squad: "Save match squad",
  delete_player_from_match_squad:
    "Are you sure you want to remove this player from the match squad?",

  // Club export inactive/dded players filters

  from: "From...",
  until: "Until...",
  export_inactive_players: "Export inactive players",
  export_added_players: "Export added players",
  export_added_inactive_players: "Export added/inactive players",
  export_player_list: "Export player list",
  born_between: "Born between",
  and_between: "And...",
  select_data_types_included_in_the_list:
    "Select data types included in the list",
  import_players: "Import players",
  select_file: "Select file",
  date_format_list: [
    {
      value: "1",
      label: "DD MM YYYY",
      formatExp: "DD-MM-YYYY",
    },
    {
      value: "2",
      label: "MM DD YYYY",
      formatExp: "MM-DD-YYYY",
    },
    {
      value: "3",
      label: "MM DD YYYY",
      formatExp: "MM-DD-YYYY",
    },
    {
      value: "4",
      label: "YYYY DD MM",
      formatExp: "YYYY-MM-DD",
    },
    {
      value: "5",
      label: "YYYY MM DD",
      formatExp: "YYYY-MM-DD",
    },
    {
      value: "6",
      label: "MM DD YY",
      formatExp: "MM-DD-YY",
    },
    {
      value: "7",
      label: "DD MM YY",
      formatExp: "DD-MM-YY",
    },
    {
      value: "8",
      label: "YY MM DD",
      formatExp: "YY-MM-DD",
    },
  ],
  select_page_orientation: "Select page orientation",
  orientation_L: "Landscape",
  orientation_P: "Portrait",

  select_date_format: "Select date format",

  //Players export list
  player_dob: "Date of birthday",
  cnp: "Identity card",
  medical_certificate_validity: "Medical certificate validity",
  export: "Export",
  all_filters: "All filters",
  hide_inactiv_players: "Hide inactiv players",
  legitimation_date: "Legitimation date",
  enrolment_date: "Enrolment date",
  player_position: "Position",
  player_position_alternative: "Secondary position",
  player_main_foot: "Main foot",
  birth_country: "Nationality",
  second_country: "Nationality 2",
  add_data_type: "Add new data type (table column)",

  //Player profile

  compare_two_players: "Compare 2 players",
  first_player: "First player",
  second_player: "Second player",
  player_atleast_one_evaluation:
    "The selected player must have at least one rating",

  used_phone_number_by_another_user:
    "The phone number already exists for another user !",
  used_phone_number_by_another_player:
    "The phone number already exists for another player !",

  insert_shirt_number: "Insert shirt number",

  // competitions
  select_age_category: "Select age category",
  no_subgroup_added: "No age subgroup added",

  //PRIVACY POLICY
  no_privacy_policy: "No privacy policy found",
  success_save_privacy_policy: "Privacy policy was successfully saved.",
  subgroup_name: "Subgroup name",
  edit_age_group_competition: "Edit age group competition",
  add_age_group_competition: "Add grupa de vârstă a competiției",
  age_group_name: "Group name",
  competition_success_added: "Competition was successfully added",
  delete_team_success: "Team was added successfully",
  delete_team_fail: "Team was NOT added",
  additional_info_generate_raport: "Additional report generation information",
  effective_duration: "Effective duration",
  effective_duration_min: "Effective duration (min)",
  delete_staff_member: "Delete staff member",
  delete_staff_member_text:
    "Are you sure you want to remove this staff member? The action is irreversible.",
  training_session_successfully_deleted:
    "The training session was successfully deleted !",
  training_session_failed_deleted:
    "Sesiunea de antrenament nu a fost ștearsă !",
  delete_training: "Delete training session",
  delete_training_text:
    "Are you sure you want to delete this training? All related data will be lost.",

  // Player notes
  add_notes: "Add notes",
  create_note: "Create note",
  your_observations: "Your observations",
  mark_as_critical_point: "Mark as critical point",
  note_add_success: "Note added successfully",
  note_add_fail: "Note added failed",
  no_notes_placeholder: "No notes added yet",
  delete_note_text: "Are you sure you want to delete this note?",
  delete_note: "Delete note",
  note_delete_success: "Note deleted successfully",
  note_delete_fail: "Note deleted failed",
  last_edit: "Last edit",
  note_edit_success: "Note edited successfully",
  note_edit_fail: "Note edited failed",

  //RPE TRAINING
  rpe_details_saved_successfully:
    "RPE training details have been successfully saved !",
  rpe_details_saved_failed: "RPE training details have NOT been saved!",
  show_trainings: "Show trainings",
  show_training: "Show training",
  see_presence_list: "See attendance sheet",
  save_data: "Save data",
  rpe_before: "RPE before",
  rpe_after: "RPE after",
  add_rpe: "Add RPE",
  global_accumulation: "Global accumulation",
  time_min: "Time",
  variability_between_workouts: "Variability between workouts",
  injuruy_general: "Injury",
  kg_before: "Kg before",
  kg_after: "Kg after",
  training_intensity: "Training intensity",

  add_player_to_future_training_sessions:
    "Add the player to the team's already scheduled future training sessions ",
  delete_player_from_training:
    "Are you sure you want to remove the player from this training? All related data will be lost.",
  player_deleted_successfully: "Player deleted successfully",
  player_deleted_failed: "Player deleted failed from training",
  delete_section: "Delete section",
  delete_section_text:
    "Are you sure you want to delete this section? All related data will be lost.",
  section_deleted_successfully: "The section has been successfully deleted!",
  section_deleted_failed: "The section has NOT been deleted!",
  start_date_greater_than_end_date: "Start date must be greater than end date",
  no_attendances_in_the_selected_period:
    "There are no attendances in the selected period",
  download_in_format: "Download in format",
  match_and_date_of_the_event: "Match and date of the event",
  payments_made: "Payments made",
  total_paid: "Total paid",
  remaining_payment: "Remaining payment",
  there_are_no_records: "There are no records.",
  receiver_entity: "Receiver",
  agent: "Agent",
  club: "Club",
  import_bonus_update: "Import bonus update",
  competition: "Competition",
  date: "Date",
  import: "Import",
  imported: "Imported",
  financial: "Financial",
  import_update_bonus_success: "Import was successfully done!",
  import_update_bonus_fail: "Import wasn't successfully done!",
  import_update_bonus_text:
    "Imported match will update only bonuses added until now.",
  unmapped_player: "WARNING! Unmapped players",

  no_necessity_report: "There are no necessity report.",
  necessity_report_suscces: "The necessity report was added with suscces!",
  necessity_report_fail: "The necessity report has not been added!",
  date_of_referral: "Date of referral",
  add_the_necessity_report: "Add the necessity report",
  delete_necessity_report: "Șterge raport de necesitate",
  delete_necessity_report_text:
    "Are you sure you want to delete this report? All related data will be lost.",
  edit_necessity_report: "Editing of the necessity report",
  approval_disapproval_of_necessity_report:
    "Approval/disapproval of necessity report",
  necessity_report_reason: "Reason ",
  in_case_of_disapproval_is_mandatory_field:
    "(in case of disapproval is mandatory field)",
  add_bonus_active_conditions: "Enter bonus for active conditions",
  contract_operations: "Contract operations",
  bonus_added_successfully: "A new bonus has been successfully added!",
  delete_contract: "Delete contract",
  delete_contract_text:
    "The action is irreversible. Are you sure you want to delete the contract for the player ",
  yes_delete: "Yes, delete",
  delete_contract_staff_text:
    "The action is irreversible. Are you sure you want to delete the contract for staff?",
  delete_bonus: "Delete bonus",
  delete_bonus_text:
    "The action is irreversible. Are you sure you want to delete the bonus?",
  child_birthday: "Child date of birth",
  for_the_department: "For department",
  already_expense_for_match: "there is already an expense tag for the match",

  edit_cost_types: {
    Staff: "Staff",
    Meci: "Match",
    Jucator: "Player",
    Echipa: "Team",
  },

  take_over: "Take over",
  people_per_page: "People per page",
  filter_by_birthday: "Filter by birth date",

  dropdown: {
    Toate: "All",
    Gol: "Goal",
    Assist: "Assist",
    yellow_card: "Yellow Card",
    red_card: "Red Card",
  },
  "Meci amical": "Friendly",
  Friendly: "Friendly",
  select_staff_member: "Select staff member",
  Defender: "Defender",
  Midfielder: "Midfielder",
  Forward: "Forward",

  right_foot: "Right Foot",
  left_foot: "Left Foot",
  both: "Both",
  "Adauga competitie noua": "Add new competition",
  add_new_competition: "Add new competition",

  opponent_team_name: "Opponent Team Name",
  opponent_team_goals: "Opponent Team goals",
  player_team_goals: "Player's Team goals",
  goals_scored: "Goals scored",
  key_passes: "Key passes",
  edit_national_callup: "Edit national callup",
  simple_all: "All",
  Recuperare: "Recovery",
  Diverse: "Various",
  select_goal_category: "Select goal category",
  none: "None",
  seniors: "Seniors",

  // PLatform modules and submodules + actions per submodule

  email_and_phone_number_used: "Email and phone number already used",
  phone_number_used: "Phone number already used",
  email_used: "Email already used",
  fail_insert_user: "Failed insert user",
  fail_insert_permission: "Fail insert permission",
  fail_update_user: "Fail update user",

  some_modules: "Some module",
  full_access: "Full access",
  modules: {
    DASHBOARD: {
      DASHBOARD: "Dashboard",
    },
    CLUB: {
      CLUB: "Club",
      TEAM: "Teams",
      CALENDAR: "Calendar",
      MATCH: "Matches schedule",
      MATCH_COSTS: "Meci Costs",
      COMPETITION: "Competitions",
      EDIT_COMMON_COMPETITION: "Edit common competitions",
      VENUE: "Venue management",
      CARDS_MANAGEMENT: "Suspension status",
      GOALS_STATS: "Goals stats",
      PRESS: "Press",
      FRF_LIST: "A/B List",
      ENROLMENT_REQUEST: "Enrolment request",
    },
    TRAINING: {
      TRAINING: "Training",
      TRAINING_PLANING: "Plan training",
      TRAINING_HISTORY: "Training sessions history",
      WEIGHT_CONTROL: "Weight control",
      TRAINING_SECTION: "Training sections",
      TRAINING_PRESENCE_REPORT: "Attendance report",
    },

    REPORT: {
      REPORT: "Reports",
      PHYSICAL_TEST: "Physical tests",
      EVALUATION: "Evaluations",
    },
    FINANCIAL: {
      FINANCIAL: "Financial",
      PAYMENT_REQUEST: "Payment request",
      COST: "Costs",
      ONLINE_PAYMENT: "Online payment",
      NECCESSITY_REPORT: "Necessity reports",
      JUSTIFYING_DOCUMENT: "Justifying documents",
      APPROVE_NECESSITY_REPORT: "Approve necessity reports",
      RECEIPT: "Receipt",
      IMPORT_FROM_STATEMENT: "Upload bank account statements",
      BONUS_MONITORING: "Bonuse monitoring",
      BUDGET: "Budget",
      PAYMENT_MANAGEMENT: "Payment management",
      FINANCIAL_REPORT: "Financial report",
    },
    MEDICAL: {
      MEDICAL: "Medical",
      INJURY: "Injury",
    },
    DOCUMENT: {
      DOCUMENT: "Documents",
      CONTRACT_PLAYER: "Player contract",
      CONTRACT_STAFF: "Staff contract",
    },
    FAN: {
      FAN: "Fan Engagement",
      FANS_LIST: "Fans list",
      FANS_IMPORT: "Import data",
      FANS_CAMPAIGN: "Campaigns",
      FANS_SOCIAL_MEDIA: "Social media activity",
      TAX_REDIRECTION: "Tax redirectors",
    },
    VIDEOGALLERY: {
      VIDEOGALLERY: "Videos gallery",
      VIDEO: "Videos",
    },
    SITE: {
      SITE: "Site management",
      NEWS: "News",
      ABOUT_US: "About Us",
      SPONSOR: "Sponsors",
      PHOTO_VIDEO_GALLERY: "Gallery",
      CONTACT: "Contact data",
      SOCIAL_MEDIA_CONNECT: "Social media connect",
      WEBSITE_SETTINGS: "Website settings",
      TEAMS_SETTINGS: "Teams settings",
      STAFF_SETTINGS: "Staff settings",
      PRIVACY_POLICY: "Privacy policy",
    },
    PARENTS_APP: {
      PARENTS_APP: "Admin parents app",
      PARENT_USER: "Users list",
      NOTIFICATE_USER: "Users notifications",
      MOBILE_APP_NEWS: "News",
      DOC_TO_SIGN: "Documents to sign",
    },
    SHOP: {
      SHOP: "Shop",
      PRODUCT: "Products",
      ORDER: "Orders",
      CATEGORY: "Categories",
      SIZE: "Sizes",
    },
    CLUB_ADMIN: {
      CLUB_ADMIN: "Users management",
      USER_LIST: "Users management",
    },
  },

  min_characters: "Min. 8 characters",
  password_min_characters_err: "Password must contain at least 8 characters",
  password_confirm_coincide_err: "Password and confirmation do not coincide",

  suspension_table: "Suspension table",
  import_suspension_update: "Import suspension update",
  home_match_abbreviation: "H",
  away_match_abbreviation: "A",
  no_data: "There are no data.",
  matches: "Matches",
  import_update_cards_management_success: "Import was successfully done!",
  import_update_cards_management_fail: "Import wasn't successfully done!",
  import_update_cards_management_text:
    "Imported match will update only players mapped until now.",
  import_cards_management_update: "Import suspension table update",
  // NEW FUNCTIONALITY
  functionality_request: "Request functionality",
  describe_functionality: "Describe the functionality you would need",
  functionality_request_success: "Successfully added functionality",
  functionality_request_danger: "Functionality has not been successfully added",
  send_request: "Send request",
  try_now: "Try now",
  new_functionalities: "New functionalities",
  no_functionality_yet: "No functionalities yet",

  //constants
  list_a: "List A",
  list_b: "List B",
  no_list: "No list",

  all_documents: "All documents",
  signed_documents: "Signed documents",
  unsigned_documents: "Unsigned documents",

  GK: "Goalkeeper",
  DEF: "Defender",
  MID: "Midfielder",
  ATT: "Forward",

  RT: "Right",
  LT: "Left",
  BOTH: "Both",

  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",

  //register
  register_title: {
    "thefootballbrain.app":
      "Create an account and test the platform for free for 30 days",
    "app.talentsafari.club": "Elevate your Players to the Global Stage",
  },
  register_subtitle_green: {
    "thefootballbrain.app":
      "You're more organized, save time and can focus on what really matters!",
    "app.talentsafari.club": "",
  },
  register_subtitle: {
    "thefootballbrain.app":
      "Keep track of all groups and athletes, schedule your practices and matches, have a complete profile of each athlete, easily collect dues from parents, have all your activity in one place, always accessible.",
    "app.talentsafari.club":
      "Our innovative platform, Talent Safari, is specifically designed to bridge the gap between local talents and global opportunities. We understand the passion, the skill, and the untapped potential that thrives within your club and we are here to amplify it to the world.",
  },
  create_account: "Create account",
  start_trial: "Start trial",
  language: "Language",
  country: "Country",
  select_country: "Select country",
  name_surname: "First and last name",
  function: "Function",
  email_for_code: "Email (you will receive a code in the next step)",
  phone: "Phone",
  password_min_8: "Password (min. 8 characters)",
  confirm_password: "Confirm password",
  have_already_account: "Do you already have an account?",
  login_here: "Log in here",
  complete_all_data: "Enter all data",
  wrong_password_min_8: "Password must contain at least 8 characters",
  wrong_password_not_same: "Passwords do not match",
  already_used_email: "Email already used",
  already_used_email_phone_number: "Email and phone number already used",
  already_used_phone_number: "Already used phone number",
  send_6_digit_code_message:
    "Ţi-am trimis un cod din 6 cifre pe adresa . Te rugăm să introduci codul pentru a-ţi valida contul şi a începe trial-ul:",
  success_create_account: "Contul a fost creat",
  wrong_code: "Ai greșit codul",
  wrong_user_no_found: "Nu s-a găsit utilizator",
  already_registered_account: "Cont deja înregistrat",
  disabled_code: "Cod dezactivat",
  error_activation_user: "Eroare la activarea utilizatorului",
  send_6_digit_code_on_email:
    "Ţi-am trimis un cod din 6 cifre pe adresa ${accountEmail}. Te rugăm să introduci codul pentru a-ţi valida contul şi a începe trial-ul:",
  complete_code_from_email: "Completează tot codul primit pe email",

  coach: "Coach",
  club_president: "Club president",
  video_analyst: "Video analyst",
  administrator: "Administrator",
  coordinator: "Coordinator",
  director: "Director",
  dietician: "Dietician",
  kinesiologist: "Kinesiologist",
  manager: "Manager",
  doctor: "Medic",
  physical_trainer: "Physical trainer",
  vicepresident: "Vice President",

  // AI Chat
  aiChat: "AI Chat",
  feedbackMessageSuccess: "Feedback was successfully sent!",
  feedbackMessageError: "Feedback wasn't successfully sent!",
  history: "History",
  feedback_placeholder: "Tell us what's wrong with this answer...",
};
