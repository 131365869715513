export default {
  // Video Gallery
  add_video: "Aggiungi video",
  video_description: "Descrizione video",
  upload_video: "Carica video",
  video_title: "Titolo del video",

  // Campaigns
  campaign_title: "Campagne",
  campaign_name: "Nome della campagna",
  campaign_type: "Tipo",
  campaign_status: "Stato",
  campaign_audience: "Pubblico",
  campaign_last_edited: "Ultima modificata",
  campaign_opens: "Aperte",
  campaign_clicks: "Acceduto",
  campaign_status_filter: "Filtra per stato",
  campaign_type_filter: "Filtra per tipo",
  campaign_pagination: "Risultati per pagina",
  campaign_your_audience: "Il tuo pubblico",
  campaign_choose_audience: "Scegli pubblico",
  campaign_new_segment: "Nuovo segmento",
  campaign_add_new_condition: "Aggiungi una nuova condizione",
  campaign_save_segment: "Salva segmento",
  campaign_sender: "Mittente",
  campaign_subject: "Soggetto",
  campaign_enter_subject: "Introdurre soggetto",
  campaign_content: "Contenuto",
  campaign_main_title: "Titolo principale",
  campaign_preview_article: "Articolo in anteprima",
  campaign_schedule_campaign: "Programmare la campagna",
  campaign_send_later: "Invia più tardi",
  campaign_send_test_message: "Invia messaggio di prova",
  campaign_save_message: "Salva messaggio",
  campaign_save_as_template: "Salva come modello",
  campaign_cancel: "Annulla",
  campaign_is_before: "È prima",
  campaign_is_after: "È dopo",
  campaign_specific_date: "Una data precisa",
  campaign_range: "Una gamma",
  campaign_contact_must_match: "Il contatto deve corrispondere",
  campaign_gender: "Genere",
  campaign_birthday: "Compleanno",
  campaign_date_added: "Data aggiunta",
  campaign_view_email: "Visualizzazione email",
  campaign_download: "Scarica",
  campaign_print: "Pressare",
  campaign_top_links: "Collegamenti principali cliccati",
  campaign_view_all_links: "Visualizza tutti i collegamenti",
  campaign_recipients: "Destinatari",
  campaign_total_recipients: "Destinatari totali",
  campaign_total_opens: "Ha aperto",
  campaign_total_clicked: "Cliccato",
  campaign_total_bounced: "Rifiutare",
  campaign_total_unsubscribed: "annullato l'iscrizione",
  campaign_delivered: "Consegnato",
  email_campaign: "Campagna di e-mail",
  campaign_report: "Rapporto della campagna",
  campaign_view_all: "Mostra tutto",
  campaign_subscribers_most_opens:
    "Abbonati con la maggior parte delle aperture",
  campaign_numbers_stats: "Numeri e statistiche",
  campaign_abuse_reports: "Rapporti di abuso",
  campaign_last_clicked: "Ultimo clic",
  campaign_total_clicks: "Clic totali",
  campaign_clicks_per_unique_opens: "Clic per aperture uniche",
  campaign_forwarded: "Inoltrato",
  campaign_last_opened: "Ultima apertura",
  campaign_successful_deliveries: "Consegne di successo",
  payment_management: "Gestione pagamenti",
  payment_requests: "Richieste di pagamento",

  //Fans section
  tax_redirectors_title: "Reindirizzamento dell'imposta sui profitti",
  tax_redirectors_total: "Reindirizzamenti totali",
  tax_redirector_name: "Nome e cognome",
  tax_redirector_register_date: "Data di registrazione",
  tax_redirector_email: "Indirizzo email",
  tax_redirector_phone_number: "Numero di telefono",

  //Site administration
  about_us_title: "Chi siamo",
  upload_main_image: "Carica l'immagine principale",
  upload_another_main_image: "Carica un'altra immagine principale",
  sections: "Sezioni",
  section: "Sezione",
  admin_add: "Aggiungere",
  admin_cancel: "Annulla",
  admin_delete: "Elimina",
  upload_image: "Carica immagine",
  upload_other_image: "Carica un'altra immagine",
  admin_save: "Salva",
  admin_cancel_modif: "Non salvare le modifiche",
  admin_preview: "Pagina di anteprima",
  gallery_title: "Galleria di anteprima",
  gallery_photos_title: "Galleria di foto",
  gallery_videos_title: "Galleria video",
  gallery_bio_title: "Biografia",
  gallery_add_button: "Aggiungi immagine/immagini",
  gallery_add_button_videos: "Aggiungi video / video",
  gallery_add_button_bio: "Aggiungi/modifica biografia",
  gallery_placeholder: "Al momento non hai caricato alcuna immagine.",
  gallery_videos_placeholder: "Al momento non hai caricato nessun video.",
  gallery_bio_placeholder: "Al momento non hai aggiunto alcuna biografia.",

  partners_title: "Partner e sponsor",
  add_partner_button: "Aggiungi partner",
  sponsor_name_form: "Nome dello sponsor",
  sponsor_site_form: "URL del sito",
  sponsor_logo_form: "Carica logo (.png)",
  sponsor_site_notif_title: "Gestire i partner",
  sponsor_site_notif_success: "Il partner è stato aggiunto con successo",
  delete_partner: "Elimina partner",
  delete_partner_popup_text: "Sei sicuro di voler rimuovere questo partner?",
  admin_confirm: "Confermare",
  delete_sponsor_notif_text: "Il partner è stato rimosso con successo",

  admin_contact_title: "Informazioni di contatto",
  admin_contact_phone: "Informazioni di contatto",
  admin_contact_address: "Indirizzo",
  admin_contact_org_name: "Nome dell'organizzazione",
  admin_contact_save_changes: "Salvare le modifiche",
  admin_contact_notif_title: "Gestione dei dati di contatto",
  admin_contact_notif_text_success:
    "Le modifiche sono state salvate con successo!",

  gallery_delete_popup_title: "Elimina immagine",
  gallery_delete_popup_text: "Sei sicuro di voler eliminare questa immagine?",
  upload_images_popup_title: "Carica immagini",
  gallery_upload_select_file: "Seleziona il file",
  gallery_upload_select_files: "Seleziona i file",
  gallery_upload_nr_singular: " immagine caricata",
  gallery_upload_nr_plural: "immagine caricata",
  gallery_notification_title: "Galleria",
  gallery_notification_success: "File caricati con successo!",
  file_remove_success_title: "File rimosso con successo.",
  general_error_message: "Si è verificato un errore, riprova più tardi.",
  news_page_title: "Ultime notizie aggiunte",
  news_page_add_button: "Aggiungi notizie",
  news_page_header_title: "Titolo",
  news_page_header_tag: "Etichetta",
  news_page_header_date: "Data di pubblicazione",
  news_page_header_options: "Opzioni",
  news_page_delete_popup_title: "Elimina articolo",
  news_page_delete_popup_text:
    "Sei sicuro di voler rimuovere questa notizia? L'azione è irreversibile.",
  add_news_title: "Aggiungi notizie",
  add_news_article_title: "Titolo della notizia",
  add_news_publish_button: "Pubblica articolo",
  add_news_cancel_button: "Elimina articolo",
  article_preview: "Anteprima articolo",
  edit_news_title: "Modifica notizie",
  edit_news_delete_button: "Elimina articolo",
  edit_section: "Modifica sezione",

  //Medical
  active_injuries_title: "Lesioni attuali",
  active_injuries_add_button: "Aggiungi danno",
  active_injuries_player_name: "Nome del giocatore",
  active_injuries_type: "Tipo di lesione",
  active_injuries_injury_date: "Data dell'infortunio",
  active_injuries_estimated_recovery: "Recupero stimato",
  injury_details: "Dettagli sulla lesione",
  add_injury_popup_title: "Aggiungi nuovo infortunio",
  injury_zone: "Zona",
  edit_details: "Modifica i dettagli",
  treatment: "Trattamento",
  injury_edit: "Modificare",
  add_treatment: "Aggiungi trattamento",
  end_injury: "Fine infortunio",
  select_team: "Selezionare squadra",
  all_teams: "Tutte le squadre",
  select_player: "Seleziona giocatore",
  select_zone: "Seleziona zona",
  start_date: "Data d'inizio",
  end_date: "Data di fine",
  return_date: "Data di ritorno",
  no_trainings: "Nessuna formazione",
  treatment_details: "Dettagli del trattamento",
  injuries_history: "Storia degli infortuni",
  absence_period: "Periodo di assenza",
  no_treatment_found: "Nessun trattamento trovato",
  total_treatment_cost: "Costo totale del trattamento",
  injury_date: "Data",
  injury_day: "giorno",
  injury_days: "giorni",
  total_cost: "Costo totale",
  no_injuries_found:
    "Nessun infortunio riscontrato durante il periodo selezionato",
  injury_change_success:
    "La lesione selezionata è stata modificata con successo!",
  injury_close_success: "L'infortunio è terminato nella data prescelta",
  injury_delete_success: "La lesione selezionata è stata rimossa con successo!",
  treatment_delete_success:
    "Il trattamento selezionato è stato rimosso con successo!",
  injury_management_title: "Gestire gli infortuni",
  injury_added_success: "L'infortunio è stato aggiunto con successo",
  treatment_edit_success:
    "Il trattamento selezionato è stato modificato con successo!",
  cost: "Costo",
  staff_members: "Membri dello staff",
  roles_list: "Elenco ruoli",
  add_member: "Aggiungi membro",
  staff_member_name: "Nome",
  staff_member_role: "Ruolo",
  edit_profile: "Modifica Profilo",
  staff_full_name: "Nome e cognome",
  staff_description: "Descrizione",
  edit_staff_member_popup_title: "Profilo del membro del personale",
  edit_staff_member_popup_message: "Dettagli salvati con successo!",
  delete_staff_member_message:
    "Il profilo selezionato è stato rimosso con successo!",
  popup_delete_staff_member_title: "Elimina profilo",
  popup_delete_staff_member_message:
    "Sei sicuro di voler rimuovere questo profilo? L'azione è irreversibile.",
  add_new_role: "Aggiungi nuovo ruolo",
  add_role: "Aggiungi ruolo",
  manage_role_notification_title: "Ruoli di gestione del club",
  manage_role_notification_succes: "Ruolo aggiunto con successo!",
  manage_role_notification_fail:
    "Il ruolo selezionato è stato rimosso con successo!",
  active_teams: "Squadre attive",
  add_team: "Aggiungi squadra",
  no_teams_yet: "Il club al momento non ha alcuna squadra",
  team_type: "Tipo",
  team_name: "Nome della squadra",
  number_players: "Numero di giocatori",
  team_coach: "Allenatore",
  team_coordinator: "Coordinatore/allenatore",
  teams_notification_title: "Gestisci squadre",
  teams_notification_success: "Squadra aggiunta con successo!",
  team_players: "Giocatori",
  team_performance: "Prestazione di squadra",
  add_player: "Aggiungi giocatore",
  PLAYER_NAME: "Nome del giocatore",
  NATIONALITY: "Nationality",
  POSITION: "Posizione",
  AGE: "Età",
  MARKET_VALUE: "Valore di mercato",
  average: "Average",
  age_years: "anni",
  minutes_played: "Minuti giocati",
  no_players_placeholder: "Questa squadra non ha giocatori",
  edit_team_name: "Modifica nome squadra",
  birth_date: "Data di nascita",

  //Player profile
  birthplace: "Luogo di nascita",
  player_age: "Età",
  weight: "Il peso",
  height: "Altezza",
  select_year: "Seleziona l'anno",
  section_access_forbidden: "Non hai accesso a questa sezione",
  delete_player: "Elimina giocatore",
  delete_player_popup_text:
    "Sei sicuro di voler rimuovere questo giocatore da questa squadra? L'azione è irreversibile.",
  change_player_team: "Cambia la squadra del giocatore",
  select_new_team: "Seleziona nuova squadra",
  player_transactions_history: "Cronologia delle operazioni eseguite",
  transaction_description: "Descrizione",
  transaction_date: "Data della transazione",
  transaction_type: "Tipo",
  trasnaction_amount: "Quantità",
  total_expenses: "Spese totali",
  contract_details: "Dettagli del contratto",
  active_contract: "Active contract",
  active_bonuses: "Bonus attivi",
  expiry_date: "Data di scadenza",
  monthly_wage: "Salario mensile",
  placeholder_bonuses: "Nessun bonus aggiunto",
  starting_date: "Data d'inizio",
  shirt_number: "Numero di maglia",
  move_player: "Cambia squadra",
  edit_photo: "Modifica foto",

  //Aside main menu

  Club: "Il mio club",
  dashboard: "Pannello di controllo",
  trainings: "Sessione di allenamento",
  Reports: "Rapporti",
  finances: "Finanziario",
  medical: "Medico",
  documents: "Documenti",
  fans_relation: "Fidanzamento dei tifosi",
  site_management: "Amministrazione del sito",
  toggle_aside: "Fai da parte",
  Users: "Users",
  shop: "Negozio",

  //Aside menu list

  player_contracts: "Contratti dei giocatori",
  staff_contracts: "Contratti del personale",
  injuries: "Lesioni",
  tax_redirectors: "Reindirizzatori fiscali",
  news: "Notizia",
  cashflow: "Flusso monetario",
  transactions: "Transazioni",
  sponsors: "Sponsor",
  clients: "Clienti",
  providers: "Fornitori",
  wages_costs: "Costi salariali",
  match_costs: "Match costs",
  my_list: "La mia lista",
  plan_training: "Pianifica formazione",
  trainings_history: "Training sessions history",
  weight_control: "Controllo del peso",
  teams: "Squadre",
  matches_schedule: "Calendario delle partite",
  competitions: "Concorsi",
  contact_data: "Informazioni di contatto",
  fans_list: "Fans list",
  import_data: "Importa dati",
  categories: "Categorie",
  products: "Prodotti",
  orders: "Ordini",

  financial_aside_title: "Finanziario",
  reports_aside_title: "Rapporti",

  account_suspension: "Account sospeso",
  suspended_account_text:
    "L'account del tuo club è stato sospeso. Tutte le informazioni sono al sicuro e saranno disponibili non appena il problema sarà risolto",
  back_to_login: "Torna al login",
  year: "Anno",
  suspension_status: "Stato della sospensione",
  goals_stats: "Statistiche dei gol",
  press: "Stampa",
  ab_list: "Lista A/B",
  bonuses_monitoring: "Monitoraggio bonus",
  budget: "Budget",
  costs: "Costi",
  edit_costs: "Modifica spese",
  online_payment_list: "Lista pagamenti online",
  monthly_tax_range: "Intervallo di tasse mensili",
  no_online_payments: "Nessun pagamento online trovato.",
  necessity_reports: "Rapporti di necessità",
  upload_bank_account_statements: "Carica estratti conto bancari",
  upload_bank_account_statements_amount_column:
    "Seleziona la colonna da associare agli importi dei pagamenti:",
  upload_bank_account_statements_date_column:
    "Seleziona la colonna da associare alla data della transazione:",
  upload_bank_account_statements_payer_column:
    "Seleziona la colonna contenente il nome del pagatore:",
  select_column: "Seleziona colonna",

  justifying_documents: "Documenti giustificativi",
  title: "Titolo",
  add_justifying_document: "Aggiungi documento giustificativo",
  no_justifying_documents: "Nessun documento giustificativo.",
  provider: "Fornitore",
  document_details: "Dettagli documento",
  accommodation_chart: "Tabella alloggi",
  transportation_table: "Tabella trasporti",
  meal_time: "Orario pasti",
  single_date: "Data singola",
  period_of_time: "Periodo di tempo",
  success_add_justifying_document:
    "Il documento è stato aggiunto con successo!",
  fail_add_justifying_document:
    "Il documento non è stato aggiunto con successo!",
  success_edit_justifying_document:
    "Il documento è stato aggiornato con successo!",
  fail_edit_justifying_document:
    "Il documento non è stato aggiornato con successo!",
  success_delete_justifying_document:
    "Il documento è stato eliminato con successo!",
  fail_delete_justifying_document:
    "Il documento non è stato eliminato con successo!",
  edit_justifying_document: "Modifica documento giustificativo",
  delete_justifying_document_confirmation:
    "Sei sicuro di voler rimuovere questo documento? L'azione è irreversibile.",
  invalid_selected_period_of_time:
    "Il periodo di tempo selezionato non è valido!",
  select_one_or_more_group: "Seleziona una o più squadre...",

  receipts: "Ricevute",
  my_list: "La mia lista",
  end_recurrent: "Fine del giorno ricorrente",
  start_recurrent: "Inizio del giorno ricorrente",
  day_of_the_week: "Giorno della settimana",
  actions: "Azioni",
  add_new_weight: "Nuova misurazione",
  duration_minutes: "Durata (min)",
  duration: "Durata",
  confirmed_presence: "Presenza confermata",
  presence_at_training: "Presenza all'allenamento",
  add_recurrent_training: "Aggiungi allenamento ricorrente",
  table: "Tabella",
  chart: "Grafico",
  plan_training: "Pianifica allenamento",
  import_data_success: "Dati importati con successo",
  import_data_fail: "Dati NON importati",
  delete_data_success: "Dati eliminati con successo",
  delete_data: "Elimina dati",
  delete_data_fail: "I dati non sono stati eliminati",
  trainings_history: "Cronologia allenamenti",
  weight_control: "Controllo peso",
  weight_added_success: "Misurazione del peso aggiunta con successo",
  weight_added_fail: "Impossibile aggiungere la misurazione del peso",
  optimal_weight_added_success: "Peso ottimale aggiunto con successo",
  optimal_weight_added_fail: "Impossibile aggiungere il peso ottimale",
  add_weight: "Aggiungi peso",
  add_optimal_weight: "Aggiungi peso ottimale",
  measure_date: "Data misurazione",
  training_sections: "Sezioni di allenamento",
  attendance_report: "Rapporto presenze",
  show_presence: "Mostra presenza",
  average_grade: "Voto medio",
  no_trainings: "Nessun allenamento",
  no_training_data: "Nessun dato di allenamento disponibile",
  no_training_sections: "Nessuna sezione di allenamento",
  have_no_presence: "Non ha presenza",
  find_training_section: "Trova sezioni di allenamento...",
  find_training_section_short: "Trova sezioni...",
  delete_diagram_training_board_message:
    "Sei sicuro di voler cancellare tutti gli elementi dal diagramma?",
  success_save_tactic_board: "La lavagna tattica è stata salvata con successo!",
  fail_save_tactic_board:
    "La lavagna tattica non è stata salvata con successo!",
  shapes: "Forme",
  equipment: "Attrezzatura",
  delete_board: "Elimina lavagna",
  download_board: "Scarica immagine",
  delete_file_diagram_training_board_message:
    "Sei sicuro di voler eliminare il diagramma?",
  success_delete_tactic_board:
    "La lavagna tattica è stata eliminata con successo!",
  fail_delete_tactic_board:
    "La lavagna tattica non è stata eliminata con successo!",
  tactic_board_unsaved_changes:
    "Hai modifiche non salvate. Sei sicuro di voler uscire senza salvare?",

  add_training_section_success: "Sezione di allenamento aggiunta con successo",
  edit_training_section_success:
    "Sezione di allenamento modificata con successo",
  edit_training_section_fail:
    "La sezione di allenamento NON è stata modificata",
  add_training_section_fail: "La sezione di allenamento NON è stata aggiunta",
  number_of_players: "Numero di giocatori in campo",
  number_of_goalkeepers: "Numero di portieri",

  physical_tests: "Test fisici",
  add_physical_tests: "Aggiungi test fisico",

  add_event_success: "L'evento è stato aggiunto con successo",
  add_event_fail: "L'evento NON è stato aggiunto",
  add_event_type_success:
    "La lista delle categorie di eventi è stata modificata con successo",
  add_event_type_fail:
    "La lista delle categorie di eventi NON è stata modificata",
  evaluation_reports: "Valutazioni",
  teams: "Squadre",
  calendar: "Calendario",
  matches_schedule: "Calendario partite",
  competitions: "Competizioni",
  initial_points: "Punti iniziali",
  search_logo: "Cerca logo",
  continue_with_previous_logo: "Continua con il logo attuale",

  next_article: "Articolo successivo",
  previous_article: "Articolo precedente",
  see_article: "Vedi articolo originale",
  type_keyword: "Digita parola chiave...",

  select_venue: "Seleziona sede/sedi",
  is_recurrent: "È ricorrente",
  event_name: "Nome evento",
  add_event_for_venue: "Aggiungi evento per sede",
  has_subdivison: "Ha suddivisioni",
  subdivison_name: "Nome suddivisione",
  venue_name: "Nome sede",
  venue_list: "Lista sedi",
  edit_training: "Modifica allenamento",
  recurring_until: "Ricorrente fino a",
  add_venue: "Aggiungi sede",
  delete_venue:
    "L'azione è irreversibile. Sei sicuro di voler eliminare la sede",
  edit_category_list: "Modifica lista categorie",
  category_list: "Lista categorie",
  venue_management: "Gestione sedi",
  social_media_connect: "Connessione social media",
  campaigns: "Campagne",
  users_list: "Lista utenti",
  users_notifications: "Notifiche utenti",
  edit_match_costs: "Modifica costi partita",
  total_cost_match: "Costi totali",
  costs_categories: "Categorie di costi",
  add_cost_category: "Aggiungi categoria",
  add_new_category_name: "Nuovo nome categoria",
  category_cost_sum: "Importo",
  save_edit_match_costs: "Salva costi partita",
  financial_report: "Rapporto finanziario",
  select_period: "Seleziona periodo",
  receipts_stats_club: "Statistiche incassi club",
  receipts_stats_per_team: "Statistiche incassi per squadra",
  incoming_payments: "Pagamenti in entrata",
  expected_incoming_payments: "Pagamenti in entrata previsti",
  number_of_players_that_paid: "Numero di giocatori che hanno pagato",
  paid_sum: "Somma pagata",
  percentage_of_total_paid: "Percentuale del totale pagato",
  total_to_be_paid: "Totale da pagare",
  debtors: "Debitori",
  annual_receipts_stats: "Statistiche incassi annuali",

  //Quick user
  change_password: "Cambia la password",
  your_currency: "Valuta selezionata",
  no_club: "Senza club",
  sign_out: "Disconnettersi",
  user_profile: "Profilo utente",
  password_reset: "Resetta la password",
  account_settings: "Impostazioni dell'account",
  password_success_text: "La password è stata modificata con successo",

  //Team players list
  vfm:
    "VFM mostra se un giocatore merita lo stipendio che guadagna attualmente, confrontandolo con la sua prestazione in campo. Un indice alto significa che un giocatore guadagna meno di quanto effettivamente meriti, confrontandolo con il livello di squadra. Più basso è l'indice, più alto è il contrasto tra prestazioni e guadagno.",
  pta:
    "PTA mostra se c'è una correlazione tra il numero di minuti giocati durante la stagione e l'attuale prestazione in campo. Più basso è l'indice, maggiore è il contrasto tra prestazione e numero di minuti (un giocatore che ha giocato troppo/troppo pochi).",
  instat_index:
    "InStat Index aiuta a determinare la classe del giocatore sulla base di un algoritmo automatico che considera il contributo del giocatore al successo della squadra, il significato delle sue azioni, il livello dell'avversario e il livello del campionato in cui gioca.",
  instat_index_title: "Indice Instat",

  //Dashboard

  end_of_month_sold: "Saldo di fine mese",
  weekly_report: "Rapporto settimanale",
  sum: "Quantità",
  period: "Periodo",
  position: "Posizione",
  ranking_points: "Punti",
  market_value_updates: "Aggiornamenti valore di mercato",
  increase: "Aumento",
  current_value: "Valore corrente",
  calendar_title: "Pianificatore di eventi",
  label_color: "Colore etichetta",
  description: "Descrizione",
  event_details: "Dettagli dell'evento",
  location: "Posizione",
  hour: "Ora",

  //Matches list

  round: "Il giro",
  match: "Incontro",
  select_competition: "Seleziona la competizione",
  past_matches: "Partite passate",
  future_matches: "Partite future",
  match_events: "Eventi delle partite",
  starting_lineup: "Formazioni iniziali",
  substitutes: "Panchina",
  add_event: "Aggiungi giocatore",
  yellow_card: "Carta gialla",
  red_card: "Cartellino rosso",
  substitution: "Sostituzione",
  goal: "Il goal",
  event_successfully_added: "Evento aggiunto con successo!",
  event_minute: "Minuto dell'evento",
  select_event_type: "Seleziona il tipo di evento",
  select_player_in: "Seleziona giocatore in",
  select_player_out: "Seleziona il giocatore fuori",
  select_goal_type: "Seleziona il tipo di il goal",
  single_goal: "Il goal",
  goal_assist: "Gol + assist",
  select_scoring_player: "Seleziona il giocatore che ha segnato",
  select_assisting_player: "Seleziona giocatore assistente",
  player_lineup_successfully_added:
    "Giocatore in formazione aggiunto con successo!",
  player_sub_successfully_added:
    "Giocatore di sostituzione aggiunto con successo!",

  //Providers components

  current_season_stats: "Statistiche della stagione in corso",
  match_sg: "incontro",
  match_pl: "partite",
  starting_lineup_matches_sg: "partita in partenza 11",
  starting_lineup_matches_pl: "partite in partenza 11",
  goals_scored_sg: "goal segnato",
  goals_scored_pl: "goal segnati",
  clean_sheets_sg: "foglio pulito",
  clean_sheets_pl: "lenzuola pulite",
  yellow_cards_sg: "Carta gialla",
  yellow_cards_pl: "Cartellini gialli",
  red_cards_sg: "Cartellino rosso",
  red_cards_pl: "Cartellini rossi",
  assist_sg: "assistere",
  assist_pl: "assiste",
  season_total: "Totale stagione",
  season_avg: "Media stagionale",
  parameter_list: "Elenco dei parametri",
  player_actions: "Azioni individuali del giocatore",
  select_match: "Seleziona partita",
  attack: "attacco",
  defence: "Difesa",
  technical: "Tecnico",
  add_name: "Inserisci il nome",
  select_existing_partners: "Seleziona dai partner esistenti",
  balance_at_end_month: "Saldo a fine mese",
  or_lowercase: "o",
  supplier_operations: "Operazioni del fornitore",
  supplier_success: "Il fornitore è stato aggiunto con successo",
  add_supplier: "Aggiungi fornitore",
  //Trainings

  trainings_evolution: "Evoluzione delle sessioni di allenamento",
  physical_tracking_results: "Risultati del monitoraggio fisico",
  heart_rate: "Frequenza cardiaca",
  distance_covered: "Distanza ricoperta",
  kcal_spent: "calorie",
  sprint_counter: "Contatore sprint",
  speed: "Velocità",
  medium: "avg",

  start_hour: "Ora di inizio",
  end_hour: "Ora di fine",
  training: "Sessione di allenamento ",
  training_schedule: "Programma di allenamento",
  hours_interval: "Fascia oraria",
  optimal_weight: "Peso ottimale",
  add_weight: "Aggiungi peso",
  measure_date: "Data della misura",
  weight_value: "Valore",

  //Documents

  file_details: "Dettagli del file",
  upload_file: "Caricare un file",
  add_new_category: "Aggiungi una nuova categoria",
  remove_contract: "Fine contratto",
  remove_contract_date: "Data di cessazione",
  remove_contract_confirm: "Confermare",
  active_contracts: "Contratti attivi",
  bonuses_list: "Elenco dei bonus",
  add_contract: "Elenco dei bonus",
  occupation: "Ruolo",
  choose_staff_member: "Scegli un membro dello staff",
  contract_bonuses: "Bonus contrattuali",
  signing_date: "Data della firma",
  add_contract_extra_bonuses_text:
    "Per aggiungere ulteriori bonus e condizioni vedere la sezione bonus",
  select_condition: "Seleziona condizione",
  add_condition_value: "Inserisci il valore della condizione",
  add_condition: "Aggiungi condizione",
  active_conditions: "Condizioni attive",
  bonus: "Bonus",

  //Training plan

  create_training: "Crea sessione di allenamento",
  add_training_sections: "Aggiungi sezioni",
  total_training_duration: "Total time",
  training_details: "Dettagli sull'allenamento",
  hours_error_training: "The end hour must be greater than the start hour",
  save_training: "Salva sessione di allenamento",
  training_sections: "Sezioni di formazione",
  create_new_section: "Crea una nuova sezione",
  add_new_section_name: "Nuovo nome della sezione",
  add_training_session: "Aggiungi sessione di allenamento",
  training_add_title: "Gestione della formazione",
  training_success: "Formazione aggiunta con successo!",
  section_add_title: "Gestione della sezione",
  section_added_success: "Sezione aggiunta con successo!",
  training_date: "Data",
  training_description_details: "Descrizione/Dettagli",

  //Matches

  match_date: "Data della partita",
  away_team: "Squadra in trasferta",
  home_team: "Squadra di casa",
  round_number: "Numero tondo",
  round_number_name: "Numero/nome tondo",
  add_match: "Aggiunta partita",
  match_successfully_added: "Partita aggiunta con successo!",
  competitions_actions: "Operazioni di concorsi",
  new_team: "Nuova squadra",
  clubs_team: "La squadra del club",
  add_competition: "Aggiungi concorrenza",
  competition_name: "Nome del concorso",
  competition_type: {
    name: "Tipo di competizione",
    elements: {
      championship: "Campionato",
      knockout: "Fase eliminatoria",
      group_stage: "Gruppi + fase eliminatoria",
    },
  },
  teams_number: "Numero di squadre",
  ranking: "classifica",
  edit_team: "Modifica squadra",
  points_short: "Pts.",
  manage_competitions: "Manage competitions",
  manage_competitions_success_changes: "Modifiche salvate con successo",
  active_competitions: "Competizioni attive",
  competition_deleted_success: "Concorso rimosso con successo",
  round_deleted_success: "Round rimosso con successo",
  match_deleted_success: "Partita rimossa con successo",
  match_delete_popup_title: "Elimina partita",
  match_delete_popup_text:
    "Sei sicuro di voler rimuovere questa partita? Tutti i dati andranno persi.",
  fixture_table: "Girone del torneo",
  delete_competition: "Elimina concorrenza",
  delete_round: "Elimina round",
  edit_round: "Modifica round",
  new_round_name: "Nuovo nome tondo",
  delete_competition_text:
    "Sei sicuro di voler rimuovere questo concorso? Tutti i dati andranno persi.",
  edit_competition: "Modifica competizione",
  edit_score: "Modifica punteggio",
  no_matches_for_selected_options:
    "Nessuna corrispondenza disponibile per le opzioni selezionate",

  player_tech_parameters: "Parametri tecnici del giocatore",
  parameters_list: "Elenco dei parametri",
  teams_stats: "Statistiche della squadra",
  administrate_teams: "Gestire i team",
  administrate_teams_success: "Il nome della squadra è cambiato con successo!",
  administrate_teams_delete: "La squadra è stata eliminata con successo!",

  // Reports
  add_report: "Aggiungi rapporto",
  made_by: "Fatto da",
  player_comparison: "Confronto giocatori",
  evaluated_params: "Parametri valutati",
  parameter: "Parametro",
  unit_of_measure: "Unità di misura",
  evaluator_name: "Nome valutatore",
  report_name: "Nome rapporto",
  parameters_added: "parametri aggiunti",
  select_parameter: "Select parameter",
  create_new_parameter: "Crea nuovo parametro",
  add_parameter: "Aggiungi parametro",
  evaluation_list: "Elenco di valutazione",
  choose_player: "Scegli un giocatore",
  add_value: "Inserisci il valore",
  add_data: "Inserisci i dati",
  evaluation: "Valutazione",
  player_added: "Giocatore aggiunto",
  save_report: "Salva report",
  add_parameter_warning_notification_title: "Aggiungi rapporto",
  add_parameter_warning_notification_text: "Seleziona un parametro.",
  add_player_warning_notification_title: "Aggiungi rapporto",
  add_player_warning_notification_text:
    " Seleziona un giocatore prima di aggiungere dati. Se non ci sono giocatori disponibili nell'elenco, assicurati di aver selezionato la squadra.",

  // Financial
  transaction_history: "Cronologia delle transazioni ",
  current_balance: "Bilancio corrente",
  select_interval: "Seleziona intervallo",
  select_type: "Seleziona il tipo",
  items_on_page: "Articoli a pagina",
  total_income: "Reddito totale",
  total_expense: "Total expense",

  // Fan engagement
  combine_fan_profiles: "Combina i profili dei fan",
  filter_profiles: "Filtro",
  firstname_lastname: "Nome",
  phone_no: "Numero di telefono ",
  age: "Età",
  years: "anni",
  general_info: "Informazioni generali",
  full_name: "Nome e cognome",
  birthday: "Compleanno",
  fan_profile: "Profilo del fan ",
  address: "Indirizzo",
  activity: " Attività ",

  // Scouting
  saved_shortlists: "Liste salvate ",
  create_shortlist: "Crea lista ristretta ",
  shortlist_name: "Nome della lista ristretta ",
  formation: "Formazione",
  selected_players: "Giocatori selezionati ",
  date_created: "Data di creazione",
  shortlist_url: " Liste ",

  //Products
  add_product: "Aggiungi prodotto",
  product_image: "Immagine del prodotto",
  product_name: "Nome del prodotto",
  product_description: "Descrizione",
  product_price: "Prezzo",
  product_status: "Stato",
  product_actions: "Azioni",
  product_is_active: "Il prodotto è attivo",

  //Orders
  order_id: "ID",
  order_date: "data dell'ordine",
  order_customer: "Cliente",
  order_shipping_method: "Metodo di spedizione",
  order_status: "Stato",
  order_amount: "Quantità",
  order_actions: "Azioni",

  // Months
  month_1: "gennaio",
  month_2: "febbraio",
  month_3: "marzo",
  month_4: "aprile",
  month_5: "maggio",
  month_6: "giugno",
  month_7: "iuglio",
  month_8: "augusto",
  month_9: "settembre",
  month_10: "ottobre",
  month_11: "novembre",
  month_12: "dicembre",

  //=========================================================================================================== Old translations
  test: "test",
  Name: "Nome",
  sponsor_contracts: "Contratti di sponsor",
  contracts: "Contratti",
  bonuses: "Bonus",
  add_new_file: "Aggiungi nuovo file",
  add_document: "Aggiungi documento",
  player_contract: "Contratto del giocatore",
  add_player_contract: "Aggiungi contratto giocatore",
  file_name: "Nome del file",
  category: "Categoria",
  date_add: "Data aggiunta",
  added_by: "Aggiunto da",
  new_member: "Nuovo membro",
  new_report: "Nuovo rapporto",
  players: "Giocatori",

  budget: "Budget",
  add_new_member: "Aggiungi nuovo membro",

  no_team: "Nessuna squadra",
  find_a_player: "Trova un giocatore",
  Nationality: "Nazionalità",
  League: "Lega",
  Age: "Età",
  Reset_filters: "Reimposta filtri",
  Advanced_search: "Ricerca Avanzata ",
  Add_to_My_List: "Aggiungi alla mia lista ",

  Order_by: "Ordinato da",
  Discover_more: "Scopri di più ",
  My_list: "La mia lista",
  Moneda: "Valuta",
  Cashflow: "Flusso monetario",
  There_are_no_records_to_display: "Non ci sono record da visualizzare ",
  Bonuses: "Bonuses",
  Currency: "Valuta",
  Cancel: "Annulla",
  Add: "Aggiungere",
  PROFIT: "PROFITTO",
  SPENDING: "SPESA",
  Add_category_to: "Aggiungi categoria a",
  Players: "Giocatori",
  CashFlow: "Flusso monetario",
  transfer_history: "Cronologia trasferimenti",
  overview: "Panoramica",
  current_season: "Stagione in corso",
  advanced_standings: "Classifica avanzata",
  fixtures_schedule: "Infissi",
  team_statistics: "Statistiche di squadra",
  player_search: "Ricerca giocatore",
  search: "Ricerca...",
  show: "Schermo",
  extra_notes: "Note aggiuntive:",
  choose_categ: "Scegli la categoria",
  new_categ: "Nuovo nome della categoria...",
  active_crtierias: "Criteri attivi:",
  Position: "Posizione",
  see_results: "Vedi i risultati",
  test_name: "Nome del test",
  evaluation_date: "Data di valutazione",
  evaluation_year: "Anno",
  see_test_details: "Vedi i dettagli del test",
  players_eval: "Giocatori valutati",
  latest_reports: "Ultimi rapporti",

  team_logo: "Logo della squadra",
  see_more: "Vedi altro",

  Login_Account: "Accedi all'account",
  Welcome_to: "Benvenuto a",
  Privacy: "La vita privada",
  Legal: "Legittimo",
  Contact: "Contatto",
  Enter_your_username_and_password: "Inserisci il tuo nome utente e password",

  Sign_In: "Registrazione",
  Dont_have_an_account_yet: "Non hai ancora un conto?",
  Sign_Up_1: "Iscriviti!",
  Enter_your_details_to_create_your_account:
    "Inserisci i tuoi dati per creare il tuo account",
  Full_name: "Nome e cognome",
  Email: "email",
  User_name: "Nome utente",
  Password: "Parola",
  Confirm_Password: "Conferma parola",
  I_aggree_with_terms_and_conditions: "Accetto i Termini e condizioni",
  Choose_image: "Scegli immagine",
  Submit: "Invia",
  Required_field: "Campo obbligatorio",
  Password_and_Confirm_Password_didnt_match:
    "Parola e Conferma parola non corrispondevano",

  The_login_detail_is_incorrect: "I dati di accesso non sono corretti",

  Add_team_bonus: "Aggiungi bonus squadra",
  Add_players_bonus: "Aggiungi bonus giocatori",
  Bonus_history: "Cronologia bonus",
  Total_sum: "Importo totale",
  Bonus_name: "Nome bonus",
  Bonus_type: "Tipo di bonus",
  Bonus_condition: "Condizione bonus",
  Payment_status: "Stato del pagamento",
  Date_added: "Data aggiunta",
  EUR: "EUR",
  RON: "RON",
  Fullfiled: "Soddisfatto",
  In_pending: "In attesa di",
  Paid: "Pagato",
  Delete: "Elimina",
  Change_status: "Cambiare stato",
  Add_bonus: "Aggiungi bonus",
  For_a_match: "Per una partita",
  Selected_step: "Settimana della partita selezionata",
  Totol_assigned_sum: "Somma totale assegnata",
  GoBack: "Di ritorno",
  Bonus_beneficiary: "Beneficiario del bonus",
  Competition: "Concorrenza",
  For_the_full_season: "Per tutta la stagione",
  Select_match: "Seleziona partita",
  Assignment_criteria: "Criteri di assegnazione",
  Bonus_position: "Raggiungi i primi... posti",
  all_competitions: "Tutte le competizioni",
  placeholder_documents: "Nessun documento ancora caricato",

  Assignment_criterias: "Criteri",
  Select_bonus_type: "Tipo di bonus",
  select_cup_phase: "Fase di Coppa...",

  Ranking: "Posizione",
  Custom_duration: "Durata personalizzata",
  attendance_report: "Rapporto presenze",
  Ranking_the_first_n_places: "Classifica nelle prime ... posizioni",
  Qualification_in_the_play_off: "Portata play-off",
  Ranking_for_european_cup: "Piazzamento in Coppa dei Campioni",
  Ranking_for_uefa_champions_league: "Posizionamento in UEFA Champions League",
  Qualification_in_the_romanian_cup:
    "Piazzamento all'interno della Coppa di Romania",
  Competiion_final: "Finale",
  Competiion_semi_final: "Semifinale",
  Competiion_quarters_final: "Quarti di finale",
  Competiion_eighths_final: "Ultimi 16",
  Competiion_sixteeths_final: "Ultimi 32",
  All_players: "Tutti i giocatori",
  Player_holders: "Formazione iniziale",
  Player_and_Staff: "Giocatori e staff",
  Individual: "Individuale",
  First_league: "Prima campionato",
  Romanian_Cup: "Coppa di Romania",
  Romanian_supercup: "Supercoppa di Romania",
  Goal_scored: "Gol segnato",
  Other_crieteria: "Altri criteri",
  Staff_bonus: "Personale",
  Another_bonus: "Un altro ricevitore",

  Final_result_win: "Risultato finale: vittoria",
  Final_result_draw: "Risultato finale: pareggio",
  Next: "Prossimo",

  Add_contract_details: "Dettagli del contratto",
  Contract_extra: "Condizioni extra",
  Upload_file: "Caricare un file",
  player_name: "Nome del giocatore",
  contract_period: "Durata del contratto",
  Other: "Altro",
  choose_team: "Scegli squadra",
  team: "Squadra",
  average_rating: "Voto medio",

  one_year: "Un anno",
  one_year_half: "Un anno e mezzo",
  two_years: "Due anni",
  two_years_half: "Due anni e mezzo",
  three_years: "Tre anni",
  three_years_half: "Tre anni e mezzo",
  four_years: "Quattro anni",
  four_years_half: "Four years and a half",
  five_years: "Cinque anni",
  five_years_half: "Cinque anni e mezzo",

  add_new_event: "Aggiungi nuovo evento",
  event_name: "Nome dell'evento",
  event_category: "Seleziona categoria",
  ending_date: "Data di fine",
  month: "Mese",
  week: "Settimana",
  day: "Giorno",
  today: "Oggi",
  new_categ_name: "Nome nuova categoria",
  club_management: "Gestione del circolo",
  physical_reports: "Rapporti fisici",
  technical_reports: "Rapporti tecnici",
  see_profile: "Vedi profilo",

  name: "Nome",
  partner_name: "Nome del partner",
  unpaid_amount: "Lasciato pagare",
  paid_amount: "Importo pagato",
  last_payment: "Data ultimo pagamento",
  deadline_date: "Data di scadenza",

  "Giocatori e staff": "Giocatori e staff",
  "Formazione iniziale": "Formazione iniziale",
  "Tutti i giocatori": "Tutti i giocatori",

  this_will_overwrite_your_preferred_currency:
    "L'azione corrente cambierà la valuta selezionata dalle impostazioni del tuo account. Sei sicuro?",
  Confirm: "Confermare",

  "Left shoulder": "Left shoulder",
  "Right shoulder": "Right shoulder",
  "Left knee": "Left knee",
  "Right knee": "Right knee",
  "Left thigh": "Left thigh",
  "Right thigh": "Right thigh",
  "Left shin": "Left shin",
  "Right shin": "Right shin",
  Back: "Back",
  Head: "Head",
  "Left ankle": "Left ankle",
  "Right ankle": "Right ankle",
  "Another zone": "Otra zona",

  Developer: "Sviluppatore",
  "Team manager": "Manager della squadra",
  Antrenor: "Allenatore",
  "Antrenor cu portarii": "Allenatore dei portieri",
  Presedinte: "Presidente",
  "Preparator fizic": "Preparatore atletico",
  "Manager IT": "Manager IT",
  "Manager Academia": "Manager dell'Accademia",

  Goalkeeper: "Goalkeeper",
  "Centre-Back": "Centre-Back",
  "Left-Back": "Left-Back",
  "Right-Back": "Right-Back",
  "Defensive Midfield": "Defensive Midfield",
  "Central Midfield": "Central Midfield",
  "Right Midfield": "Right Midfield",
  "Left Midfield": "Left Midfield",
  "Attacking Midfield": "Attacking Midfield",
  "Left Winger": "Left Winger",
  "Right Winger": "Right Winger",
  "Second Striker": "Second Striker",
  "Centre-Forward": "Centre-Forward",
  ConnectionCannotBeRefreshed:
    "La connessione non può essere aggiornata in questo momento. Attendere fino al prossimo periodo di aggiornamento consentito o verificare se la connessione è inattiva.",
  cur_: "",
  cur_Th: "T",
  cur_M: "M",
  cur_B: "B",
  cur_T: "T",

  "All documents": "All documents",
  "Players contracts": "Players contracts",
  "Sponsor contracts": "Sponsor contracts",

  //start
  Remove_from_my_list: "Remove from my list",
  Budget: "Budget",
  fast_search: "Fast search",
  total: "Total",
  auth_page_left_description: "",
  no_item: "No item.",
  Coach: "Coach",
  "Goalkeepers coach": "Goalkeepers coach",
  President: "President",
  "Physical trainer": "Physical trainer",
  "IT Manager": "IT Manager",
  "Poli Iasi Academy Manager": "Poli Iasi Academy Manager",
  Bills: "Facturi",
  Various: "Diverse",
  "Training scheme": "Training scheme",

  left_shoulder: "Left shoulder",
  right_shoulder: "Right shoulder",
  left_knee: "Left knee",
  right_knee: "Right knee",
  left_thigh: "Left thigh",
  right_thigh: "Right thigh",
  left_shin: "Left shin",
  right_shin: "Right shin",
  back: "Back",
  head: "Head",
  left_ankle: "Left ankle",
  right_ankle: "Right ankle",

  ranking_position: "Standing position",
  no_staff_members_yet: "No staff members found",
  "GENERAL.SOMETHING_WENT_WRONG":
    "Ups! Something went wrong. Please try again later.",
  Error: "Error",

  Back_button: "Back",

  FORM_CONTROL: {
    "TRANSLATOR.SELECT": "Scegli la tua lingua",
    "MENU.NEW": "Nuovo",
    "MENU.ACTIONS": "Azioni",
    "MENU.CREATE_POST": "Crea nuovo post",
    "MENU.PAGES": "Pagine",
    "MENU.FEATURES": "Funzionalità",
    "MENU.APPS": "Applicazioni",
    "MENU.DASHBOARD": "Dashboard",
    "AUTH.GENERAL.OR": "O",
    "AUTH.GENERAL.SUBMIT_BUTTON": "Invia",
    "AUTH.GENERAL.NO_ACCOUNT": "Non hai un account?",
    "AUTH.GENERAL.SIGNUP_BUTTON": "Registrati",
    "AUTH.GENERAL.FORGOT_BUTTON": "Password dimenticata",
    "AUTH.GENERAL.BACK_BUTTON": "Indietro",
    "AUTH.GENERAL.CANCEL_BUTTON": "Annulla",
    "AUTH.GENERAL.PRIVACY": "Privacy",
    "AUTH.GENERAL.LEGAL": "Legale",
    "AUTH.GENERAL.CONTACT": "Contatto",
    "AUTH.LOGIN.TITLE": "Accedi",
    "AUTH.LOGIN.BUTTON": "Accedi",
    "AUTH.FORGOT.TITLE": "Password dimenticata?",
    "AUTH.FORGOT.DESC": "Inserisci la tua email per reimpostare la password",
    "AUTH.FORGOT.SUCCESS": "La tua password è stata reimpostata con successo.",
    "AUTH.REGISTER.TITLE": "Registrazione",
    "AUTH.REGISTER.DESC": "Inserisci i tuoi dati per creare il tuo account",
    "AUTH.REGISTER.SUCCESS": "Il tuo account è stato registrato con successo.",
    "AUTH.INPUT.EMAIL": "Email",
    "AUTH.INPUT.FULLNAME": "Nome completo",
    "AUTH.INPUT.PASSWORD": "Password",
    "AUTH.INPUT.CONFIRM_PASSWORD": "Conferma password",
    "AUTH.INPUT.USERNAME": "Nome utente",
    "AUTH.VALIDATION.INVALID": "{name} non valido",
    "AUTH.VALIDATION.REQUIRED": "{name} richiesto",
    "AUTH.VALIDATION.MIN_LENGTH": "Lunghezza minima 3 caratteri",
    "AUTH.VALIDATION.MAX_LENGTH": "Lunghezza massima 50 caratteri",
    "AUTH.VALIDATION.AGREEMENT_REQUIRED":
      "Devi accettare i termini e le condizioni",
    "AUTH.VALIDATION.NOT_FOUND": "{name} richiesto non trovato",
    "AUTH.VALIDATION.INVALID_LOGIN": "Dati di accesso non validi",
    "AUTH.VALIDATION.INVALID_EMAIL": "Email non valida",
    "AUTH.GENERAL.SOMETHING_WENT_WRONG":
      "Ops! Qualcosa è andato storto. Riprova più tardi.",
    "AUTH.VALIDATION.NO_USER_WITH_EMAIL":
      "Nessun utente attivo con questa email.",
    "AUTH.VALIDATION.REQUIRED_FIELD": "Campo obbligatorio",
    "AUTH.VALIDATION.MIN_LENGTH_FIELD": "Lunghezza minima del campo:",
    "AUTH.VALIDATION.MAX_LENGTH_FIELD": "Lunghezza massima del campo:",
    "AUTH.VALIDATION.INVALID_FIELD": "Campo non valido",
  },

  verification_code: "Codice di verifica",
  insert_verification_code:
    "Inserisci il codice a 6 cifre inviato al numero di telefono associato a questo account",
  verification_code_no_receive_message: "Non hai ricevuto il messaggio?",
  verification_code_send_again: "Invia di nuovo",

  auth_code_expired: "Il codice di autenticazione è scaduto",
  auth_code_incorrect: "Codice di autenticazione errato",
  auth_code_try_again: "Errore di autenticazione. Per favore riprova.",

  team_types_list: "Tipi di squadra",
  team_type_add: "Aggiungi nuovo tipo",
  team_type_add_short: "Aggiungi tipo",

  team_type_notification_title: "Tipi di squadra del club",
  team_type_notification_succes: "Tipo di squadra aggiunto con successo!",
  team_type_notification_fail:
    "Il tipo selezionato è stato rimosso con successo!",

  tm_link_team: "Link Transfermarkt (opzionale)",
  minutes_played_uppercase: "Minuti giocati",
  all_fields_mandatory_error: "Tutti i campi sono obbligatori",
  no_sections_added_error: "Devi aggiungere almeno una sezione",
  rec_date_not_before_inj_date:
    "La data di recupero non può essere precedente alla data dell'infortunio",
  documents_upload_success: "Hai aggiunto un nuovo file!",
  video_upload_success: "Hai aggiunto un nuovo video!",
  video_upload_failed:
    "Il tuo video deve essere di dimensioni massime di 125 MB per il caricamento!",
  video_delete_success: "Il tuo video è stato eliminato con successo!",
  video_operation: "Operazione video",
  ACCESS_FORBIDDEN_ERROR: "Accesso vietato",
  new_category_empty_error:
    "Il nome della nuova categoria non può essere vuoto",
  delete_team: "Elimina squadra",
  season_date_start_add_team: "Data di inizio stagione corrente",
  season_date_end_add_team: "Data di fine stagione corrente",
  deleteTeamMessage: "Sei sicuro di voler eliminare questa squadra?",
  deleteTeamMessage2: "Tutti i giocatori e i dati correlati andranno persi.",
  add_optimal_weight: "Modifica peso ottimale",

  auth_error_title: "Errore di autenticazione",
  auth_error_message: "Si prega di controllare i dati inseriti e riprovare",
  reset_error_title: "Errore di reimpostazione della password",
  hello: "Ciao",
  password_reset_email: "La tua nuova password è",

  import_fans_title: "Importa i dati sui sostenitori",
  select_fields_to_save: "Seleziona i campi da salvare",
  enter_source_name: "Inserisci il nome della fonte",
  enter_delimiter: "Inserisci il delimitatore",
  sample_name_fans_file: "es: Iscritti alla Newsletter",
  associated_with: "Associato a",
  activity_date: "Data dell'attività",
  column: "Colonna",
  social_media_activity: "Attività sui social media",

  // ----------------------------------Payments

  payments_title: "Pagamenti",
  table_name: "Nome",
  table_months: {
    jan: "Gennaio",
    feb: "Febbraio",
    mar: "Marzo",
    apr: "Aprile",
    may: "Maggio",
    jun: "Giugno",
    jul: "Luglio",
    aug: "Agosto",
    sep: "Settembre",
    oct: "Ottobre",
    nov: "Novembre",
    dec: "Dicembre",
  },
  add_payment_title: "Aggiungi pagamento per il giocatore",
  add_payment_select_month: "Seleziona mese",
  add_payment_amount: "Importo",
  add_payment_type: {
    cash: "Contanti",
    bank_transfer: "Bonifico bancario",
  },
  add_payment_date: "Data pagamento",
  add_payment_description: "Descrizione (opzionale)",
  add_payment_button_add: "Aggiungi pagamento",
  add_payment_button_cancel: "Annulla",
  edit_payments_title: "Modifica pagamenti del giocatore",
  edit_payments_empty: "Nessun pagamento registrato",
  add_payment_overwrite_first:
    "Sei sicuro di voler sovrascrivere i dati del pagamento in",
  add_payment_overwrite_second: "per il giocatore",
  add_payment_button_overwrite: "Sì, sovrascrivi pagamento",
  delete_payment_question: "Sei sicuro di voler eliminare questo pagamento?",
  delete_payment_button_delete: "Sì, elimina",
  payment_successfully_added: "Pagamento aggiunto con successo!",
  payment_successfully_deleted:
    "Il pagamento selezionato è stato eliminato con successo!",

  // Adding only missing keys from en.js
  equipment_management: "Gestione attrezzatura",
  equipment_inventory: "Inventario attrezzatura",
  equipment_add: "Aggiungi attrezzatura",
  equipment_edit: "Modifica attrezzatura",
  equipment_delete: "Elimina attrezzatura",
  equipment_name: "Nome attrezzatura",
  equipment_type: "Tipo attrezzatura",
  equipment_quantity: "Quantità",
  equipment_status: "Stato",
  equipment_location: "Posizione",
  equipment_assigned_to: "Assegnato a",
  equipment_maintenance_date: "Data manutenzione",
  equipment_notes: "Note",

  // Financial Management
  financial_overview: "Panoramica finanziaria",
  income: "Entrate",
  expenses: "Spese",
  financial_reports: "Report finanziari",
  add_transaction: "Aggiungi transazione",
  edit_transaction: "Modifica transazione",
  delete_transaction: "Elimina transazione",
  transaction_category: "Categoria",
  transaction_amount: "Importo",

  // Communication
  communication_center: "Centro comunicazioni",
  announcements: "Annunci",
  messages: "Messaggi",
  notifications: "Notifiche",
  broadcast_message: "Messaggio broadcast",
  send_notification: "Invia notifica",
  message_recipients: "Destinatari",
  message_subject: "Oggetto",
  message_content: "Contenuto",
  message_priority: "Priorità",

  // Event Management
  event_calendar: "Calendario eventi",
  event_type: "Tipo evento",
  event_date: "Data evento",
  event_time: "Ora evento",
  event_location: "Luogo evento",
  event_description: "Descrizione evento",
  event_participants: "Partecipanti",
  event_status: "Stato evento",
  edit_event: "Modifica evento",
  delete_event: "Elimina evento",

  // Player Management
  player_profile: "Profilo giocatore",
  player_statistics: "Statistiche giocatore",
  player_history: "Storico giocatore",
  player_documents: "Documenti giocatore",
  player_medical: "Cartella medica",
  player_performance: "Prestazioni",
  player_attendance: "Presenze",
  player_emergency_contact: "Contatto di emergenza",
  player_guardian: "Tutore",
  player_education: "Istruzione",
  player_notes: "Note",

  // Training Categories
  training_categories: "Categorie di allenamento",
  physical_training: "Allenamento fisico",
  technical_training: "Allenamento tecnico",
  tactical_training: "Allenamento tattico",
  mental_training: "Allenamento mentale",
  recovery_session: "Sessione di recupero",
  group_training: "Allenamento di gruppo",
  individual_training: "Allenamento individuale",

  // Match Statistics
  match_stats: "Statistiche partita",
  possession: "Possesso palla",
  shots: "Tiri",
  shots_on_target: "Tiri in porta",
  corners: "Calci d'angolo",
  fouls: "Falli",
  yellow_cards: "Cartellini gialli",
  red_cards: "Cartellini rossi",
  offsides: "Fuorigioco",
  saves: "Parate",
  goals_scored: "Gol segnati",
  goals_conceded: "Gol subiti",

  // Document Types
  document_types: "Tipi di documento",
  contract: "Contratto",
  medical_certificate: "Certificato medico",
  insurance: "Assicurazione",
  identification: "Documento d'identità",
  consent_form: "Modulo di consenso",
  registration_form: "Modulo di registrazione",
  transfer_documents: "Documenti di trasferimento",
  educational_records: "Documenti scolastici",

  // System Settings
  system_settings: "Impostazioni di sistema",
  general_settings: "Impostazioni generali",
  notification_settings: "Impostazioni notifiche",
  privacy_settings: "Impostazioni privacy",
  security_settings: "Impostazioni sicurezza",
  language_settings: "Impostazioni lingua",
  display_settings: "Impostazioni display",
  backup_settings: "Impostazioni backup",

  // Access Control
  access_control: "Controllo accessi",
  user_roles: "Ruoli utente",
  permissions: "Permessi",
  role_assignment: "Assegnazione ruoli",
  access_levels: "Livelli di accesso",
  user_groups: "Gruppi utente",
  access_logs: "Log accessi",
  security_audit: "Audit sicurezza",

  // Report Generation
  report_generation: "Generazione report",
  generate_report: "Genera report",
  report_template: "Template report",
  report_type: "Tipo report",
  report_period: "Periodo report",
  report_format: "Formato report",
  report_recipients: "Destinatari report",
  schedule_report: "Pianifica report",
  report_frequency: "Frequenza report",

  // Performance Metrics
  performance_metrics: "Metriche di prestazione",
  physical_metrics: "Metriche fisiche",
  endurance: "Resistenza",
  strength: "Forza",
  agility: "Agilità",
  flexibility: "Flessibilità",
  technical_metrics: "Metriche tecniche",
  passing_accuracy: "Precisione nei passaggi",
  shooting_accuracy: "Precisione al tiro",
  dribbling_skills: "Abilità nel dribbling",
  ball_control: "Controllo palla",
  tactical_metrics: "Metriche tattiche",
  positioning: "Posizionamento",
  game_reading: "Lettura del gioco",
  decision_making: "Processo decisionale",
  team_play: "Gioco di squadra",

  // Injury Management
  injury_management: "Gestione infortuni",
  injury_record: "Registro infortuni",
  injury_type: "Tipo di infortunio",
  injury_location: "Zona infortunata",
  injury_severity: "Gravità infortunio",
  estimated_recovery: "Recupero stimato",
  actual_recovery: "Recupero effettivo",
  rehabilitation_plan: "Piano di riabilitazione",
  injury_notes: "Note sull'infortunio",
  injury_status: "Stato infortunio",
  return_to_play: "Ritorno all'attività",

  // Nutrition Management
  nutrition_management: "Gestione nutrizione",
  meal_plan: "Piano alimentare",
  dietary_requirements: "Requisiti dietetici",
  nutrition_goals: "Obiettivi nutrizionali",
  supplement_plan: "Piano integratori",
  hydration_tracking: "Monitoraggio idratazione",
  weight_management: "Gestione peso",
  nutrition_notes: "Note nutrizionali",
  dietary_restrictions: "Restrizioni alimentari",
  meal_schedule: "Programma pasti",

  // Competition Management
  competition_management: "Gestione competizioni",
  competition_level: "Livello competizione",
  competition_season: "Stagione",
  competition_format: "Formato competizione",
  competition_rules: "Regolamento",
  competition_schedule: "Calendario",
  competition_standings: "Classifica",
  competition_stats: "Statistiche competizione",
  competition_history: "Storico competizione",

  // Team Analytics
  team_analytics: "Analisi squadra",
  team_trends: "Tendenze squadra",
  team_comparison: "Confronto squadre",
  team_rankings: "Classifiche",
  team_progress: "Progressi squadra",
  team_reports: "Report squadra",
  team_insights: "Approfondimenti squadra",
  team_predictions: "Previsioni squadra",

  // Body Parts
  Neck: "Collo",
  "Right arm": "Braccio destro",
  "Left arm": "Braccio sinistro",
  "Right elbow": "Gomito destro",
  "Left elbow": "Gomito sinistro",
  "Right wrist": "Polso destro",
  "Left wrist": "Polso sinistro",
  "Right hand": "Mano destra",
  "Left hand": "Mano sinistra",
  Chest: "Petto",
  Abdomen: "Addome",
  "Right hip": "Anca destra",
  "Left hip": "Anca sinistra",
  "Right calf": "Polpaccio destro",
  "Left calf": "Polpaccio sinistro",
  "Right foot": "Piede destro",
  "Left foot": "Piede sinistro",

  // Status Messages
  status_active: "Attivo",
  status_inactive: "Inattivo",
  status_pending: "In attesa",
  status_completed: "Completato",
  status_cancelled: "Annullato",
  status_suspended: "Sospeso",
  status_expired: "Scaduto",
  status_draft: "Bozza",
  status_published: "Pubblicato",
  status_archived: "Archiviato",

  // Time Periods
  period_daily: "Giornaliero",
  period_weekly: "Settimanale",
  period_monthly: "Mensile",
  period_quarterly: "Trimestrale",
  period_yearly: "Annuale",
  period_custom: "Personalizzato",

  // Document Status
  document_status_draft: "Bozza",
  document_status_pending: "In attesa di approvazione",
  document_status_approved: "Approvato",
  document_status_rejected: "Respinto",
  document_status_expired: "Scaduto",
  document_status_archived: "Archiviato",

  // Notification Types
  notification_info: "Informazione",
  notification_success: "Successo",
  notification_warning: "Avviso",
  notification_error: "Errore",
  notification_system: "Sistema",
  notification_custom: "Personalizzato",

  // User Actions
  action_view: "Visualizza",
  action_edit: "Modifica",
  action_delete: "Elimina",
  action_approve: "Approva",
  action_reject: "Respingi",
  action_archive: "Archivia",
  action_restore: "Ripristina",
  action_export: "Esporta",
  action_import: "Importa",
  action_print: "Stampa",
  action_share: "Condividi",
  action_download: "Scarica",
  action_upload: "Carica",
  action_generate: "Genera",
  action_schedule: "Pianifica",
  action_cancel: "Annulla",
  action_confirm: "Conferma",
  action_save: "Salva",
  action_submit: "Invia",
  action_reset: "Reimposta",

  // Validation Messages
  validation_required: "Campo obbligatorio",
  validation_min_length: "Lunghezza minima non raggiunta",
  validation_max_length: "Lunghezza massima superata",
  validation_email_format: "Formato email non valido",
  validation_phone_format: "Formato telefono non valido",
  validation_date_format: "Formato data non valido",
  validation_number_format: "Formato numero non valido",
  validation_password_match: "Le password non coincidono",
  validation_unique_value: "Valore già esistente",
  validation_file_size: "Dimensione file non valida",
  validation_file_type: "Tipo file non valido",

  // Common Actions
  select_all: "Seleziona tutto",
  deselect_all: "Deseleziona tutto",
  select_date: "Seleziona data",
  select_time: "Seleziona ora",
  select_file: "Seleziona file",
  select_option: "Seleziona opzione",
  search_placeholder: "Cerca...",
  filter_results: "Filtra risultati",
  sort_ascending: "Ordine crescente",
  sort_descending: "Ordine decrescente",
  show_more: "Mostra altro",
  show_less: "Mostra meno",
  load_more: "Carica altro",
  refresh_data: "Aggiorna dati",
  clear_filters: "Cancella filtri",
  clear_selection: "Cancella selezione",
  apply_changes: "Applica modifiche",
  discard_changes: "Annulla modifiche",

  // Training Session Types
  training_type_technical: "Tecnico",
  training_type_tactical: "Tattico",
  training_type_physical: "Fisico",
  training_type_recovery: "Recupero",
  training_type_match_preparation: "Preparazione partita",
  training_type_individual: "Individuale",
  training_type_group: "Gruppo",
  training_type_goalkeeper: "Portieri",

  // Match Events
  match_event_goal: "Gol",
  match_event_assist: "Assist",
  match_event_shot: "Tiro",
  match_event_shot_on_target: "Tiro in porta",
  match_event_foul: "Fallo",
  match_event_yellow_card: "Cartellino giallo",
  match_event_red_card: "Cartellino rosso",
  match_event_substitution: "Sostituzione",
  match_event_injury: "Infortunio",
  match_event_corner: "Calcio d'angolo",
  match_event_offside: "Fuorigioco",
  match_event_penalty: "Rigore",
  match_event_own_goal: "Autogol",

  // Player Attributes
  player_attribute_speed: "Velocità",
  player_attribute_acceleration: "Accelerazione",
  player_attribute_stamina: "Resistenza",
  player_attribute_strength: "Forza",
  player_attribute_agility: "Agilità",
  player_attribute_jumping: "Salto",
  player_attribute_balance: "Equilibrio",
  player_attribute_technique: "Tecnica",
  player_attribute_dribbling: "Dribbling",
  player_attribute_passing: "Passaggio",
  player_attribute_shooting: "Tiro",
  player_attribute_heading: "Colpo di testa",
  player_attribute_marking: "Marcatura",
  player_attribute_tackling: "Contrasto",
  player_attribute_positioning: "Posizionamento",
  player_attribute_vision: "Visione di gioco",
  player_attribute_composure: "Freddezza",
  player_attribute_leadership: "Leadership",

  // Medical Categories
  medical_category_injury: "Infortunio",
  medical_category_illness: "Malattia",
  medical_category_rehabilitation: "Riabilitazione",
  medical_category_assessment: "Valutazione",
  medical_category_prevention: "Prevenzione",
  medical_category_treatment: "Trattamento",
  medical_category_surgery: "Chirurgia",
  medical_category_consultation: "Consulto",

  // Equipment Categories
  equipment_category_training: "Attrezzatura allenamento",
  equipment_category_match: "Attrezzatura partita",
  equipment_category_medical: "Attrezzatura medica",
  equipment_category_fitness: "Attrezzatura fitness",
  equipment_category_technology: "Tecnologia",
  equipment_category_clothing: "Abbigliamento",
  equipment_category_accessories: "Accessori",
  equipment_category_maintenance: "Manutenzione",

  // Financial Categories
  financial_category_income: "Entrate",
  financial_category_expenses: "Spese",
  financial_category_salaries: "Stipendi",
  financial_category_transfers: "Trasferimenti",
  financial_category_equipment: "Attrezzature",
  financial_category_facilities: "Strutture",
  financial_category_travel: "Trasferte",
  financial_category_medical: "Spese mediche",
  financial_category_marketing: "Marketing",
  financial_category_administrative: "Amministrazione",

  // Report Categories
  report_category_performance: "Performance",
  report_category_medical: "Medico",
  report_category_financial: "Finanziario",
  report_category_attendance: "Presenze",
  report_category_scouting: "Scouting",
  report_category_training: "Allenamento",
  report_category_match: "Partita",
  report_category_analytics: "Analisi",
  report_category_summary: "Riepilogo",
  report_category_detailed: "Dettagliato",

  // System Messages and Errors
  system_error_general: "Si è verificato un errore. Riprova più tardi.",
  system_error_validation: "Si prega di correggere gli errori nel modulo.",
  system_error_permission: "Non hai i permessi necessari per questa azione.",
  system_error_not_found: "La risorsa richiesta non è stata trovata.",
  system_error_duplicate: "Esiste già un elemento con questi dati.",
  system_success_save: "Dati salvati con successo.",
  system_success_update: "Dati aggiornati con successo.",
  system_success_delete: "Elemento eliminato con successo.",

  // Form Labels and Placeholders
  form_label_search: "Cerca",
  form_label_filter: "Filtra",
  form_label_sort: "Ordina",
  form_label_date_from: "Data da",
  form_label_date_to: "Data a",
  form_label_select_all: "Seleziona tutto",
  form_label_deselect_all: "Deseleziona tutto",
  form_placeholder_search: "Inserisci termine di ricerca...",
  form_placeholder_select: "Seleziona un'opzione",
  form_placeholder_date: "Seleziona una data",

  // Table Headers
  table_header_id: "ID",
  table_header_name: "Nome",
  table_header_date: "Data",
  table_header_type: "Tipo",
  table_header_status: "Stato",
  table_header_actions: "Azioni",
  table_header_description: "Descrizione",
  table_header_category: "Categoria",
  table_header_priority: "Priorità",
  table_header_progress: "Progresso",

  // Modal Titles and Messages
  modal_title_confirm: "Conferma azione",
  modal_title_delete: "Conferma eliminazione",
  modal_title_edit: "Modifica",
  modal_title_create: "Crea nuovo",
  modal_message_delete: "Sei sicuro di voler eliminare questo elemento?",
  modal_message_confirm: "Sei sicuro di voler procedere con questa azione?",
  modal_message_unsaved: "Hai modifiche non salvate. Vuoi continuare?",

  // Button Labels
  button_add: "Aggiungi",
  button_edit: "Modifica",
  button_delete: "Elimina",
  button_save: "Salva",
  button_cancel: "Annulla",
  button_close: "Chiudi",
  button_confirm: "Conferma",
  button_back: "Indietro",
  button_next: "Avanti",
  button_finish: "Fine",
  button_send: "Invia",
  button_upload: "Carica",
  button_download: "Scarica",
  button_refresh: "Aggiorna",

  // Time Related
  time_now: "Adesso",
  time_today: "Oggi",
  time_yesterday: "Ieri",
  time_tomorrow: "Domani",
  time_this_week: "Questa settimana",
  time_last_week: "Settimana scorsa",
  time_this_month: "Questo mese",
  time_last_month: "Mese scorso",
  time_this_year: "Quest'anno",
  time_last_year: "Anno scorso",

  // File Management
  file_upload: "Carica file",
  file_download: "Scarica file",
  file_delete: "Elimina file",
  file_size_limit: "Dimensione massima file",
  file_type_allowed: "Tipi di file consentiti",
  file_upload_success: "File caricato con successo",
  file_upload_error: "Errore durante il caricamento del file",
  file_download_error: "Errore durante il download del file",
  file_delete_success: "File eliminato con successo",
  file_delete_error: "Errore durante l'eliminazione del file",

  // Calendar and Schedule
  calendar_view_day: "Vista giornaliera",
  calendar_view_week: "Vista settimanale",
  calendar_view_month: "Vista mensile",
  calendar_view_year: "Vista annuale",
  calendar_event_new: "Nuovo evento",
  calendar_event_edit: "Modifica evento",
  calendar_event_delete: "Elimina evento",
  calendar_event_repeat: "Ripeti evento",
  calendar_event_all_day: "Tutto il giorno",
  calendar_event_reminder: "Promemoria",

  // Player Status
  player_status_active: "Attivo",
  player_status_injured: "Infortunato",
  player_status_suspended: "Sospeso",
  player_status_loan: "In prestito",
  player_status_transfer_listed: "In lista trasferimenti",
  player_status_retired: "Ritirato",
  player_status_released: "Svincolato",
  player_status_trial: "In prova",

  // Contract Types
  contract_type_professional: "Professionale",
  contract_type_amateur: "Dilettante",
  contract_type_youth: "Giovanile",
  contract_type_loan: "Prestito",
  contract_type_scholarship: "Borsa di studio",
  contract_type_trial: "Periodo di prova",

  // Training Ground Areas
  area_main_pitch: "Campo principale",
  area_training_pitch: "Campo allenamento",
  area_indoor_facility: "Struttura al coperto",
  area_gym: "Palestra",
  area_medical_room: "Sala medica",
  area_physio_room: "Sala fisioterapia",
  area_meeting_room: "Sala riunioni",
  area_analysis_room: "Sala analisi",

  // Performance Ratings
  rating_excellent: "Eccellente",
  rating_very_good: "Molto buono",
  rating_good: "Buono",
  rating_average: "Nella media",
  rating_below_average: "Sotto la media",
  rating_poor: "Scarso",
  rating_very_poor: "Molto scarso",
  rating_not_rated: "Non valutato",

  // Weather Conditions
  weather_sunny: "Soleggiato",
  weather_cloudy: "Nuvoloso",
  weather_rainy: "Piovoso",
  weather_stormy: "Temporalesco",
  weather_windy: "Ventoso",
  weather_snow: "Nevoso",
  weather_fog: "Nebbia",
  weather_extreme_heat: "Caldo estremo",
  weather_extreme_cold: "Freddo estremo",

  // Pitch Conditions
  pitch_excellent: "Eccellente",
  pitch_good: "Buono",
  pitch_average: "Nella media",
  pitch_poor: "Scarso",
  pitch_very_poor: "Molto scarso",
  pitch_waterlogged: "Allagato",
  pitch_frozen: "Ghiacciato",
  pitch_under_maintenance: "In manutenzione",

  // Match Preparation
  match_prep_tactical: "Preparazione tattica",
  match_prep_physical: "Preparazione fisica",
  match_prep_mental: "Preparazione mentale",
  match_prep_team_talk: "Discorso alla squadra",
  match_prep_opposition_analysis: "Analisi avversari",
  match_prep_set_pieces: "Calci piazzati",
  match_prep_individual_instructions: "Istruzioni individuali",
  match_prep_team_selection: "Selezione squadra",
  match_prep_formation_setup: "Impostazione formazione",
  match_prep_final_briefing: "Briefing finale",

  // Team Roles and Responsibilities
  role_head_coach: "Allenatore capo",
  role_assistant_coach: "Vice allenatore",
  role_goalkeeper_coach: "Allenatore dei portieri",
  role_fitness_coach: "Preparatore atletico",
  role_physiotherapist: "Fisioterapista",
  role_team_doctor: "Medico della squadra",
  role_scout: "Osservatore",
  role_analyst: "Analista",
  role_team_manager: "Team manager",
  role_kit_manager: "Magazziniere",

  // Training Equipment
  equipment_balls: "Palloni",
  equipment_cones: "Coni",
  equipment_bibs: "Pettorine",
  equipment_goals: "Porte",
  equipment_hurdles: "Ostacoli",
  equipment_ladders: "Scale",
  equipment_poles: "Paletti",
  equipment_resistance_bands: "Elastici",
  equipment_weights: "Pesi",
  equipment_medical_kit: "Kit medico",

  // Match Officials
  official_referee: "Arbitro",
  official_assistant_referee_1: "Primo assistente",
  official_assistant_referee_2: "Secondo assistente",
  official_fourth_official: "Quarto uomo",
  official_var: "VAR",
  official_observer: "Osservatore arbitrale",

  // Competition Phases
  phase_group_stage: "Fase a gironi",
  phase_round_of_32: "Sedicesimi di finale",
  phase_round_of_16: "Ottavi di finale",
  phase_quarter_final: "Quarti di finale",
  phase_semi_final: "Semifinale",
  phase_final: "Finale",
  phase_qualification: "Qualificazione",
  phase_playoff: "Playoff",
  phase_relegation: "Playout",

  // Player Development Areas
  development_technical: "Sviluppo tecnico",
  development_tactical: "Sviluppo tattico",
  development_physical: "Sviluppo fisico",
  development_mental: "Sviluppo mentale",
  development_social: "Sviluppo sociale",
  development_educational: "Sviluppo educativo",

  // Facility Management
  facility_pitch_booking: "Prenotazione campo",
  facility_maintenance: "Manutenzione",
  facility_equipment: "Attrezzatura",
  facility_security: "Sicurezza",
  facility_cleaning: "Pulizia",
  facility_lighting: "Illuminazione",
  facility_parking: "Parcheggio",
  facility_access_control: "Controllo accessi",

  // Communication Types
  communication_announcement: "Annuncio",
  communication_newsletter: "Newsletter",
  communication_report: "Report",
  communication_feedback: "Feedback",
  communication_survey: "Sondaggio",
  communication_meeting: "Riunione",
  communication_training: "Allenamento",
  communication_match: "Partita",
  communication_event: "Evento",
  communication_emergency: "Emergenza",

  // Analytics Categories
  analytics_performance: "Analisi prestazioni",
  analytics_tactical: "Analisi tattica",
  analytics_technical: "Analisi tecnica",
  analytics_physical: "Analisi fisica",
  analytics_medical: "Analisi medica",
  analytics_recruitment: "Analisi reclutamento",
  analytics_financial: "Analisi finanziaria",
  analytics_attendance: "Analisi presenze",
  analytics_engagement: "Analisi coinvolgimento",
  analytics_development: "Analisi sviluppo",

  // Match Analysis
  analysis_possession: "Analisi possesso palla",
  analysis_passing: "Analisi passaggi",
  analysis_shooting: "Analisi tiri",
  analysis_defending: "Analisi difesa",
  analysis_set_pieces: "Analisi calci piazzati",
  analysis_key_moments: "Momenti chiave",
  analysis_player_performance: "Prestazioni giocatori",
  analysis_team_shape: "Disposizione squadra",
  analysis_opposition: "Analisi avversari",
  analysis_trends: "Tendenze",

  // Training Focus Areas
  focus_ball_control: "Controllo palla",
  focus_passing_drills: "Esercizi di passaggio",
  focus_shooting_practice: "Pratica di tiro",
  focus_defensive_drills: "Esercizi difensivi",
  focus_attacking_patterns: "Schemi d'attacco",
  focus_set_piece_routines: "Routine calci piazzati",
  focus_pressing_traps: "Trappole del pressing",
  focus_transition_play: "Gioco di transizione",
  focus_positional_play: "Gioco posizionale",
  focus_combination_play: "Gioco di combinazione",

  // Medical Assessment Types
  assessment_physical: "Valutazione fisica",
  assessment_functional: "Valutazione funzionale",
  assessment_biomechanical: "Valutazione biomeccanica",
  assessment_psychological: "Valutazione psicologica",
  assessment_nutritional: "Valutazione nutrizionale",
  assessment_recovery: "Valutazione recupero",
  assessment_fatigue: "Valutazione fatica",
  assessment_injury_risk: "Valutazione rischio infortuni",
  assessment_return_to_play: "Valutazione ritorno al gioco",
  assessment_performance: "Valutazione prestazioni",

  // Scouting Categories
  scouting_technical_ability: "Capacità tecniche",
  scouting_tactical_awareness: "Consapevolezza tattica",
  scouting_physical_attributes: "Attributi fisici",
  scouting_mental_strength: "Forza mentale",
  scouting_potential: "Potenziale",
  scouting_consistency: "Costanza",
  scouting_injury_history: "Storico infortuni",
  scouting_character: "Carattere",
  scouting_adaptability: "Adattabilità",
  scouting_leadership: "Leadership",

  // Performance Metrics
  metric_distance_covered: "Distanza percorsa",
  metric_sprint_speed: "Velocità sprint",
  metric_acceleration: "Accelerazione",
  metric_deceleration: "Decelerazione",
  metric_high_intensity_runs: "Corse ad alta intensità",
  metric_work_rate: "Ritmo di lavoro",
  metric_heart_rate: "Frequenza cardiaca",
  metric_recovery_rate: "Tasso di recupero",
  metric_power_output: "Potenza espressa",
  metric_fatigue_index: "Indice di fatica",

  // Team Building Activities
  activity_team_bonding: "Attività di gruppo",
  activity_leadership_workshop: "Workshop leadership",
  activity_communication_session: "Sessione comunicazione",
  activity_problem_solving: "Problem solving",
  activity_goal_setting: "Definizione obiettivi",
  activity_stress_management: "Gestione stress",
  activity_motivation_session: "Sessione motivazionale",
  activity_team_strategy: "Strategia di squadra",
  activity_conflict_resolution: "Risoluzione conflitti",
  activity_performance_review: "Revisione prestazioni",

  disabled_code: "Codice disabilitato",
  error_activation_user: "Errore nell'attivazione dell'utente",
  send_6_digit_code_on_email:
    "Ti abbiamo inviato un codice a 6 cifre all'indirizzo ${accountEmail}. Inserisci il codice per validare il tuo account e iniziare la prova:",
  complete_code_from_email: "Completa il codice ricevuto via email",

  coach: "Allenatore",
  club_president: "Presidente del club",
  video_analyst: "Analista video",
  administrator: "Amministratore",
  coordinator: "Coordinatore",
  director: "Direttore",
  dietician: "Dietologo",
  kinesiologist: "Chinesiologo",
  manager: "Manager",
  doctor: "Medico",
  physical_trainer: "Preparatore atletico",
  vicepresident: "Vice Presidente",

  // Register
  register_title: {
    "thefootballbrain.app":
      "Crea un account e prova la piattaforma gratuitamente per 30 giorni",
    "app.talentsafari.club": "Porta i tuoi giocatori sulla scena mondiale",
  },
  register_subtitle_green: {
    "thefootballbrain.app":
      "Sarai più organizzato, risparmierai tempo e potrai concentrarti su ciò che conta davvero!",
    "app.talentsafari.club": "",
  },
  register_subtitle: {
    "thefootballbrain.app":
      "Tieni traccia di tutti i gruppi e gli atleti, programma i tuoi allenamenti e le partite, hai un profilo completo di ogni atleta, raccogli facilmente le quote dai genitori, hai tutta la tua attività in un unico posto, sempre accessibile.",
    "app.talentsafari.club":
      "La nostra piattaforma innovativa, Talent Safari, è specificamente progettata per colmare il divario tra i talenti locali e le opportunità globali. Comprendiamo la passione, l'abilità e il potenziale inespresso che prospera nel tuo club e siamo qui per amplificarlo al mondo.",
  },

  // Constants
  list_a: "Lista A",
  list_b: "Lista B",
  no_list: "Nessuna lista",

  all_documents: "Tutti i documenti",
  signed_documents: "Documenti firmati",
  unsigned_documents: "Documenti non firmati",

  GK: "Portiere",
  DEF: "Difensore",
  MID: "Centrocampista",
  ATT: "Attaccante",

  RT: "Destro",
  LT: "Sinistro",
  BOTH: "Entrambi",

  monday: "Lunedì",
  tuesday: "Martedì",
  wednesday: "Mercoledì",
  thursday: "Giovedì",
  friday: "Venerdì",
  saturday: "Sabato",
  sunday: "Domenica",

  // Quick Setup
  quickSetup: {
    information_about_your_club: "Informazioni dettagliate sul tuo club",
    team: "Squadra",
    match_training: "Partite e allenamenti",
    matches: "Partite",
    competition: "Competizioni",
    add_staff_min: "Aggiungi minimo x_number membri dello staff",
    add_team_min: "Aggiungi minimo x_number squadra",
    add_player_min: "Aggiungi minimo x_number giocatori",
    add_training_min: "Aggiungi minimo x_number allenamento",
    add_match_min: "Aggiungi minimo x_number partita",
    add_competition_min: "Aggiungi minimo x_number competizione",
    start_setup: "Inizia configurazione",
    continue_setup: "Continua configurazione",
    quick_action: "Azione rapida",
  },

  // No data placeholders
  no_data_activ_contract: "Nessun contratto attivo",
  no_data_active_license: "Nessuna licenza attiva",

  // Teams list
  active_season: "Stagione attiva",
  season: "Stagione",
  select_season: "Seleziona stagione",
  modify_start_date: "Modifica data di inizio",
  modify_end_date: "Modifica data di fine",
  set_season_as_active: "Imposta stagione come attiva",
  create_season: "Crea stagione",
  select_start_date: "Seleziona data di inizio",
  select_end_date: "Seleziona data di fine",
  season_start_date: "Data di inizio",
  season_end_date: "Data di fine",
  save_season: "Salva stagione",
  team_data: "Dati della squadra",
  season_management: "Gestione stagione",
  success_save_team_details:
    "I dati della squadra sono stati salvati con successo!",
  fail_save_team_details:
    "I dati della squadra non sono stati salvati con successo!",
  start_end_season_error: "*La data di fine è precedente a quella di inizio",
  empty_date_error: "*Seleziona entrambe le date",
  success_update_season: "La stagione è stata aggiornata con successo!",
  fail_update_season: "La stagione non è stata aggiornata con successo!",
  success_delete_season: "La stagione è stata eliminata con successo!",
  fail_delete_season: "La stagione non è stata eliminata con successo!",
  delete_season: "Elimina stagione",
  delete_season_message:
    "Sei sicuro di voler rimuovere questa stagione della squadra e i relativi dati? L'azione è irreversibile.",
  mobile_app_details: "Dettagli app mobile",
  general_details: "Dettagli generali",
  mobile_app_users: "Utenti app mobile",

  success_update_player_instat_id:
    "L'ID Instat del giocatore è stato modificato con successo!",
  fail_update_player_instat_id:
    "L'ID Instat del giocatore non è stato modificato con successo!",

  // Notifications
  notification_title_message: "Notifica utente",
  notification_sucess_message: "La notifica è stata inviata con successo!",
  notification_failed_message: "L'invio della notifica non è riuscito!",
  notification_delete_sucess_message:
    "La notifica è stata eliminata con successo!",
  notification_delete_failed_message:
    "L'eliminazione della notifica non è riuscita!",
  no_notification: "Non ci sono notifiche.",
  send_custom_notification: "Invia notifica personalizzata",
  settings_automatic_notification: "Impostazioni notifiche automatiche",
  send_notification_day: "Giorno di invio notifica",
  send_notification_for: "Invia notifica per",

  send_notification_day_options: {
    first: "Primo giorno del mese",
    middle: "Il 15 del mese",
    last: "Ultimo giorno del mese",
  },

  send_notification_for_options: {
    previous: "Mese precedente",
    current: "Mese corrente",
    next: "Mese successivo",
  },

  // Match notifications
  matches_module: "Partite",
  success_save_players_rating:
    "Le valutazioni dei giocatori sono state salvate con successo!",
  fail_save_players_rating:
    "Le valutazioni dei giocatori non sono state salvate con successo!",
  the_match_lot_was_successfully_saved:
    "La rosa della partita è stata salvata con successo!",
  fail_the_match_lot_was_successfully_saved:
    "La rosa della partita non è stata salvata con successo!",
  the_player_has_been_removed_from_the_squad:
    "Il giocatore è stato rimosso dalla rosa!",
  fail_the_player_has_been_removed_from_the_squad:
    "Il giocatore non è stato rimosso dalla rosa!",
  players_successfully_added_to_the_squad:
    "Giocatori aggiunti alla rosa con successo!",
  players_failed_added_to_the_squad:
    "Aggiunta dei giocatori alla rosa non riuscita!",

  // Receipt
  success_save_receipt: "La ricevuta è stata modificata con successo!",
  fail_save_receipt: "La ricevuta non è stata modificata con successo!",
  success_delete_receipt: "La ricevuta è stata eliminata con successo!",
  fail_delete_receipt: "La ricevuta non è stata eliminata con successo!",
  num_and_date_receipt: "Numero e data di emissione",

  // Export players
  export_player_list: "Esporta lista giocatori",
  born_between: "Nato tra",
  and_between: "E...",
  select_data_types_included_in_the_list:
    "Seleziona i tipi di dati da includere nella lista",
  import_players: "Importa giocatori",
  select_page_orientation: "Seleziona orientamento pagina",
  orientation_L: "Orizzontale",
  orientation_P: "Verticale",
  select_date_format: "Seleziona formato data",

  // Players export list
  player_dob: "Data di nascita",
  cnp: "Carta d'identità",
  medical_certificate_validity: "Validità certificato medico",
  export: "Esporta",
  all_filters: "Tutti i filtri",
  hide_inactiv_players: "Nascondi giocatori inattivi",
  legitimation_date: "Data di legittimazione",
  enrolment_date: "Data di iscrizione",
  player_position: "Posizione",
  player_position_alternative: "Posizione secondaria",
  player_main_foot: "Piede preferito",
  birth_country: "Nazionalità",
  second_country: "Seconda nazionalità",
  add_data_type: "Aggiungi nuovo tipo di dati (colonna tabella)",

  // Player profile
  compare_two_players: "Confronta 2 giocatori",
  first_player: "Primo giocatore",
  second_player: "Secondo giocatore",
  player_atleast_one_evaluation:
    "Il giocatore selezionato deve avere almeno una valutazione",
  used_phone_number_by_another_user:
    "Il numero di telefono è già utilizzato da un altro utente!",
  used_phone_number_by_another_player:
    "Il numero di telefono è già utilizzato da un altro giocatore!",
  insert_shirt_number: "Inserisci numero di maglia",

  // Competitions
  select_age_category: "Seleziona categoria d'età",
  no_subgroup_added: "Nessun sottogruppo d'età aggiunto",

  // Privacy policy
  no_privacy_policy: "Nessuna privacy policy trovata",
  success_save_privacy_policy: "Privacy policy salvata con successo.",
  subgroup_name: "Nome sottogruppo",
  edit_age_group_competition: "Modifica competizione per categoria d'età",

  // Modules
  MEDICAL: {
    MEDICAL: "Medico",
    INJURY: "Infortunio",
  },
  DOCUMENT: {
    DOCUMENT: "Documenti",
    CONTRACT_PLAYER: "Contratto giocatore",
    CONTRACT_STAFF: "Contratto staff",
  },
  FAN: {
    FAN: "Coinvolgimento tifosi",
    FANS_LIST: "Lista tifosi",
    FANS_IMPORT: "Importa dati",
    FANS_CAMPAIGN: "Campagne",
    FANS_SOCIAL_MEDIA: "Attività social media",
    TAX_REDIRECTION: "Reindirizzamento fiscale",
  },
  VIDEOGALLERY: {
    VIDEOGALLERY: "Galleria video",
    VIDEO: "Video",
  },
  SITE: {
    SITE: "Gestione sito",
    NEWS: "Notizie",
    ABOUT_US: "Chi siamo",
    SPONSOR: "Sponsor",
    PHOTO_VIDEO_GALLERY: "Galleria",
    CONTACT: "Dati di contatto",
    SOCIAL_MEDIA_CONNECT: "Connessione social media",
    WEBSITE_SETTINGS: "Impostazioni sito web",
    TEAMS_SETTINGS: "Impostazioni squadre",
    STAFF_SETTINGS: "Impostazioni staff",
    PRIVACY_POLICY: "Privacy policy",
  },
  PARENTS_APP: {
    PARENTS_APP: "App genitori admin",
    PARENT_USER: "Lista utenti",
    NOTIFICATE_USER: "Notifiche utenti",
    MOBILE_APP_NEWS: "Notizie",
    DOC_TO_SIGN: "Documenti da firmare",
  },
  SHOP: {
    SHOP: "Negozio",
    PRODUCT: "Prodotti",
    ORDER: "Ordini",
    CATEGORY: "Categorie",
    SIZE: "Taglie",
  },

  // Platform update
  platform_update: "Aggiorna",
  platform_update_message: "L'app si sta aggiornando alla versione $version!",

  // Alt titles
  altTitle: {
    view_team: "Visualizza squadra",
    edit_team: "Modifica squadra",
    costs: "Pagamenti ricevuti",
    medical_certificate: "Certificati medici",
    import_players: "Importa giocatori",
  },

  // Costs
  select_cost_category: "Seleziona categoria di costo",
  add_new_cost_category: "Aggiungi nuova categoria di costo",
  costs_category: "Categoria di costo",
  edit_departaments_list: "Modifica lista dipartimenti",
  add_departament: "Aggiungi dipartimento",
  edit_departament: "Modifica dipartimento",
  department_name: "Nome dipartimento",
  add_spending: "Aggiungi spesa",
  department: "Dipartimento",
  subdepartment: "Sottosezione",
  has_subdepartments: "Ha sottosezioni",
  subdepartment_designation: "Nome sottosezione",
  add_new_subdepartment: "Aggiungi nuova sottosezione",
  add_tag: "Aggiungi tag",
  entity_tag: "Tag entità",
  select_department: "Seleziona dipartimento",
  select_subdepartment: "Seleziona sottosezione",
  select_tag_type: "Seleziona tipo di tag",
  no_costs: "Nessun costo trovato",

  // Department notifications
  success_add_department: "Dipartimento aggiunto con successo!",
  fail_add_department: "Dipartimento non aggiunto con successo!",
  no_departments: "Nessun dipartimento trovato",
  success_update_department: "Dipartimento aggiornato con successo!",
  fail_update_department: "Dipartimento non aggiornato con successo!",
  success_delete_department: "Dipartimento eliminato con successo!",
  fail_delete_department: "Dipartimento non eliminato con successo!",
  department_delete_message:
    "Sei sicuro di voler eliminare questo dipartimento? L'azione è irreversibile.",

  // Cost notifications
  success_add_cost: "Nuovo costo aggiunto con successo!",
  fail_add_cost: "Nuovo costo non aggiunto con successo!",
  success_update_cost: "Costo aggiornato con successo!",
  fail_update_cost: "Costo non aggiornato con successo!",
  cost_delete_message:
    "Sei sicuro di voler eliminare questo costo? L'azione è irreversibile.",
  success_delete_cost: "Costo eliminato con successo!",
  fail_delete_cost: "Costo non eliminato con successo!",
  delete_cost: "Elimina costo",

  // Website settings
  website_settings: "Impostazioni sito web",
  online_payments_settings: "Impostazioni pagamenti online",
  enable_online_payments_module: "Abilita modulo pagamenti online sul sito",
  only_online_payments: "Solo pagina pagamenti online",
  iframe: "Abilita modalità <i>iframe</i> per la pagina dei pagamenti online",
  stripe_fee_passed_to_customer:
    "La commissione Stripe viene addebitata al cliente",
  client_edit_monthly_tax: "La quota mensile può essere modificata dal cliente",

  payment_types_settings: "Impostazioni tipi di pagamento",
  enable_another_tax: "Mostra opzione <i>Altro pagamento</i>",
  enable_payment_request: "Mostra opzione <i>Richieste di pagamento</i>",

  enrolment_request_settings: "Impostazioni richiesta iscrizione",
  enable_enrolment_form: "Abilita modulo richiesta iscrizione sul sito",

  // Enrolment requests
  enrolment_request: "Richiesta di iscrizione",
  edit_request: "Modifica richiesta",
  child_name: "Nome bambino",
  child_surname: "Cognome bambino",
  request_date: "Data richiesta",
  adult_name: "Nome adulto",
  adult_surname: "Cognome adulto",
  child: "Bambino",
  city: "Città",
  no_requests: "Nessuna richiesta",
  delete_request: "Elimina richiesta",
  delete_request_message: "Sei sicuro di voler eliminare questa richiesta?",

  // Section management
  delete_section: "Elimina sezione",
  delete_section_text:
    "Sei sicuro di voler eliminare questa sezione? Tutti i dati correlati andranno persi.",
  section_deleted_successfully: "La sezione è stata eliminata con successo!",
  section_deleted_failed: "La sezione NON è stata eliminata!",
  start_date_greater_than_end_date:
    "La data di inizio deve essere maggiore della data di fine",
  no_attendances_in_the_selected_period:
    "Non ci sono presenze nel periodo selezionato",
  download_in_format: "Scarica in formato",
  match_and_date_of_the_event: "Partita e data dell'evento",
  payments_made: "Pagamenti effettuati",
  total_paid: "Totale pagato",
  remaining_payment: "Pagamento rimanente",
  there_are_no_records: "Non ci sono record.",
  receiver_entity: "Destinatario",
  agent: "Agente",

  // Import bonus
  import_bonus_update: "Importa aggiornamento bonus",
  import: "Importa",
  imported: "Importato",
  import_update_bonus_success: "Importazione completata con successo!",
  import_update_bonus_fail: "Importazione non completata con successo!",
  import_update_bonus_text:
    "La partita importata aggiornerà solo i bonus aggiunti finora.",
  unmapped_player: "ATTENZIONE! Giocatori non mappati",

  no_necessity_report: "Non c'è alcun rapporto di necessità.",

  // Training board
  delete_diagram_training_board_message:
    "Sei sicuro di voler cancellare tutti gli elementi dal diagramma?",
  success_save_tactic_board: "Lavagna tattica salvata con successo!",
  fail_save_tactic_board: "Lavagna tattica non salvata con successo!",
  shapes: "Forme",
  equipment: "Attrezzatura",
  delete_board: "Elimina lavagna",
  download_board: "Scarica immagine",
  delete_file_diagram_training_board_message:
    "Sei sicuro di voler eliminare il diagramma?",
  success_delete_tactic_board: "Lavagna tattica eliminata con successo!",
  fail_delete_tactic_board: "Lavagna tattica non eliminata con successo!",
  tactic_board_unsaved_changes:
    "Hai modifiche non salvate. Sei sicuro di voler uscire senza salvare?",

  // Training sections
  add_training_section_success: "Sezione di allenamento aggiunta con successo",
  edit_training_section_success:
    "Sezione di allenamento modificata con successo",
  edit_training_section_fail: "Sezione di allenamento NON modificata",
  add_training_section_fail: "Sezione di allenamento NON aggiunta",
  number_of_players: "Numero di giocatori di campo",
  number_of_goalkeepers: "Numero di portieri",
  age_group: "Categoria d'età",
  no_training_sections: "Nessuna sezione di allenamento",
  have_no_presence: "Non ha presenze",
  find_training_section: "Trova sezione di allenamento... ",
  find_training_section_short: "Trova sezione...",

  // Physical tests
  physical_tests: "Test fisici",
  add_physical_tests: "Aggiungi test fisico",

  // Events
  add_event_success: "Evento aggiunto con successo",
  add_event_fail: "Evento NON aggiunto",
  add_event_type_success: "Lista categorie eventi modificata con successo",
  add_event_type_fail: "Lista categorie eventi NON modificata",
  evaluation_reports: "Valutazioni",
  calendar: "Calendario",
  initial_points: "Punti iniziali",
  search_logo: "Cerca logo",
  continue_with_previous_logo: "Continua con il logo attuale",

  // Articles
  next_article: "Articolo successivo",
  previous_article: "Articolo precedente",
  see_article: "Vedi articolo originale",
  type_keyword: "Digita parola chiave...",

  // Venues
  select_venue: "Seleziona sede/sedi",
  is_recurrent: "È ricorrente",
  add_event_for_venue: "Aggiungi evento per sede",
  has_subdivison: "Ha suddivisioni",
  subdivison_name: "Nome suddivisione",
  venue_name: "Nome sede",
  venue_list: "Lista sedi",
  edit_training: "Modifica allenamento",
  recurring_until: "Ricorrente fino a",
  add_venue: "Aggiungi sede",
  delete_venue:
    "L'azione è irreversibile. Sei sicuro di voler eliminare la sede ",
  edit_category_list: "Modifica lista categorie",
  category_list: "Lista categorie",
  venue_management: "Gestione sedi",

  // Match management
  select_match_type: "Seleziona tipo di partita",
  autogoal: "Autogol",
  continue_without_external_team_logo: "Continua senza logo squadra esterna",
  continue_without_own_team_logo: "Continua senza logo squadra propria",
  players_match_rating: "Valutazione giocatori",
  no_players: "Non ci sono giocatori.",
  save_players_rating: "Salva valutazione giocatori",
  add_match_formation_first_half: "Aggiungi formazione primo tempo",
  add_match_formation_second_half: "Aggiungi formazione secondo tempo",
  view_match_formation_first_half: "Visualizza formazione primo tempo",
  view_match_formation_second_half: "Visualizza formazione secondo tempo",
  first_half_playing_formation: "Formazione primo tempo",
  second_half_playing_formation: "Formazione secondo tempo",
  choose_playing_formation: "Scegli formazione di gioco",
  select_position: "Seleziona posizione",

  // Tactic board
  Create_new_tactic_board: "Crea lavagna tattica personalizzata",
  added_on_date: "in data",
  Category: "Categoria",
  Accessibility: "Accessibilità",
  Section_type: "Tipo di sezione",
  section_type: "Tipo di sezione",
  Add_note: "Aggiungi nota",
  total_duration: "Durata totale",
  accessibility: "Accessibilità",
  label_name: "Nome etichetta",
  add_new_label: "Aggiungi nuova etichetta",
  last_update: "Ultimo aggiornamento",
  Labels: "Etichette",
  label: "Etichetta",
  no_description_added: "Nessuna descrizione aggiunta",
  no_labels_added: "Nessuna etichetta aggiunta",

  // Competition
  warning_comp_no_date_end:
    "La data di fine competizione non è selezionata. La competizione sarà registrata nella sezione 'Storico competizioni'.",
  warning_comp_before_current_date:
    "La data selezionata è precedente alla data corrente. La competizione sarà registrata nella sezione 'Storico competizioni'.",

  // Team settings
  team_management: "Gestione squadra",
  number_order: "Numero d'ordine",
  tax_value: "Valore tassa",
  display_on_website: "Mostra sul sito web",
  display_for_online_payments: "Mostra per pagamenti online",
  save_settings: "Salva impostazioni",
  select_at_least_one_player: "Seleziona almeno un giocatore!",

  // Staff settings
  staff_management: "Gestione staff",

  // Match format
  match_format: "Formato partita",
  add_match_format: "Aggiungi formato partita",
  other_format: "Altro formato",
  select_match_location: "Seleziona luogo partita",

  // Competition
  competition_has_more_cat: "La competizione ha più categorie d'età",
  number_of_groups: "Numero di gruppi",
  competition_edit_success: "Competizione modificata con successo",
  competition_edit_fail: "Competizione NON modificata",
  county: "Provincia",
  select_county: "Seleziona provincia",
  in: "in",
  source: "Fonte",
  select_source: "Seleziona fonte",
  series: "Serie",
  select_series: "Seleziona serie",
  your_club: "Il tuo club",
  select_your_club: "Seleziona il tuo club",
  club_team: "Squadra nel tuo club",
  select_club_team: "Seleziona la squadra nel tuo club",
  import_competition: "Importa competizione",
  create_competition: "Crea competizione",
  import_matches_and_competition: "Importa partite e competizione",
  game_day: "Giornata di gioco",
  date_time: "Data e ora",
  manage_competitions_failed_changes: "Le modifiche non sono state salvate",

  // Users management
  no_users: "Nessun utente",
  modules_access: "Accesso ai moduli",
  provide_access_all_modules: "Fornisci accesso a tutti i moduli",
  users_management: "Gestione utenti",
  users_management_menu: "Gestione utenti",
  account_creation_date: "Data creazione account",
  team_access: "Accesso squadra",
  access_module: "Accesso modulo",
  add_user: "Aggiungi utente",

  // Orders
  order_delivery_types: {
    delivery: "Consegna",
    pickup: "Ritiro",
  },
  order_payment_types: {
    online: "Online",
    cash: "Contanti",
  },
  confirm_order: "Confermato",
  cancel_order: "Annulla",
  close_order: "Chiudi",
  deliver_order: "Consegnato",
  order_delete_message:
    "Sei sicuro di voler eliminare questo ordine? L'azione è irreversibile.",
  success_delete_order: "Ordine eliminato con successo!",
  fail_delete_order: "Ordine non eliminato con successo!",
  success_confirm_order: "Ordine confermato con successo!",
  fail_confirm_order: "Ordine non confermato con successo!",
  success_cancel_order: "Ordine annullato con successo!",
  fail_cancel_order: "Ordine non annullato con successo!",
  success_deliver_order: "Ordine consegnato con successo!",
  fail_deliver_order: "Ordine non consegnato con successo!",

  // Sizes
  sizes: "Taglie",
  size: "Taglia",
  add_size: "Aggiungi taglia",
  edit_size: "Modifica taglia",
  size_delete_message:
    "Sei sicuro di voler eliminare questa taglia? L'azione è irreversibile.",
  no_sizes: "Nessuna taglia trovata",
  add_size_name: "Aggiungi taglia",
  success_add_size: "Taglia aggiunta con successo!",
  fail_add_size: "Taglia non aggiunta con successo!",
  success_update_size: "Taglia aggiornata con successo!",
  fail_update_size: "Taglia non aggiornata con successo!",
  success_delete_size: "Taglia eliminata con successo!",
  fail_delete_size: "Taglia non eliminata con successo!",

  // Documents
  empty_folder: "Questa cartella è vuota.",
  no_documents: "Non ci sono documenti.",
  edit_folder: "Modifica cartella",
  folder_name: "Nome cartella",
  edit_folder_success: "La cartella è stata modificata con successo!",
  edit_folder_fail: "La cartella non è stata modificata con successo!",
  delete_folder: "Elimina cartella",
  delete_folder_confirmation:
    "Sei sicuro di voler eliminare questa cartella? Tutti i dati correlati andranno persi.",
  success_delete_folder: "Cartella eliminata con successo!",
  fail_delete_folder: "Cartella non eliminata con successo!",
  edit_document: "Modifica documento",
  document_name: "Nome documento",
  edit_document_success: "Il documento è stato modificato con successo!",
  edit_document_fail: "Il documento non è stato modificato con successo!",
  delete_document_confirmation:
    "Sei sicuro di voler eliminare questo documento? Tutti i dati correlati andranno persi.",
  success_delete_document: "Documento eliminato con successo!",
  fail_delete_document: "Documento non eliminato con successo!",
  create_new_folder: "Crea nuova cartella",
  add_folder_success: "La cartella è stata aggiunta con successo!",
  add_folder_fail: "La cartella non è stata aggiunta con successo!",
  upload_file_success: "Il file è stato aggiunto con successo!",
  upload_file_fail: "Il file non è stato aggiunto con successo!",

  // Players
  goalkeepers: "Portieri",
  show_on_loan: "Giocatori in prestito",

  // Match events
  sure_delete_event: "Sei sicuro di voler eliminare l'evento dalla partita?",

  // Success messages
  success_added_player: "Giocatore aggiunto con successo!",
  fail_added_player: "Giocatore non aggiunto con successo!",
  category_color: "Colore",
  edit_categories: "Modifica categorie",
  no_country: "Nessun paese",

  // Additional missing keys
  incorect_phone_number: "Numero di telefono non corretto",
  yellow: "Gialli",
  teams_from_competition: "Squadre della competizione",
  add_location_to_field_management: "Aggiungi posizione alla gestione campo",
  date_and_time_are_approximate: "Data e ora sono approssimative",
  minutes_per_time: "Minuti per tempo",
  match_from_competition: "Partita di competizione",
  frendly_match: "Amichevole",
  player_match_rating: "Valutazione",
  parameter_name: "Nome parametro",
  add_new_parameter: "Aggiungi nuovo parametro",
  training_data: "Dati allenamento",
  note: "nota",
  tactic_board: "Lavagna tattica",
  Section_labels: "Etichette sezione",
  Section_description: "Descrizione sezione",
  Necessary_equipment: "Attrezzatura necessaria",
  Media: "Media",
  Image: "Immagine",
  Edit_labels: "Modifica etichette",
  Edit: "Modifica",
  total_payment_at_present: "Pagamento totale (attuale)",
  edit_player_contract: "Modifica contratto giocatore",
  bonus_progress: "Progresso",
  bonus_value: "Valore bonus",
  no_bonuses: "Nessun bonus trovato",
  training_fail: "Allenamento non aggiunto con successo!",
  section_added_fail: "Sezione non aggiunta con successo!",
  training_date_team: "Data e squadra",
  add_view_image: "Aggiungi/visualizza immagine",
  add_view_video: "Aggiungi/visualizza video",
  add_section: "Aggiungi sezione",
  add_section_name: "Aggiungi nome sezione",
  add_section_description: "Aggiungi descrizione sezione",
  sponsor_name: "Nome sponsor",
  receipts_stats: "Statistiche ricevute",
  scouting: "Scouting",
  no_orders: "Nessun ordine trovato",
  tags: "Tag",
  date_format: "gg/mm/aaaa",
  player: "Giocatore",
  apply_filters: "Applica filtri",
  departments_list: "Lista dipartimenti",
  generate_attendance_pdf_report: "Genera report PDF presenze",
  special_monthly_tax_for_year: "Condizioni per la quota mensile dell'anno ",
  add_players: "Aggiungi giocatori",
  success_add_players_to_payment_request:
    "I giocatori sono stati aggiunti con successo!",
  fail_add_players_to_payment_request:
    "I giocatori non sono stati aggiunti con successo!",
  edit_amount_to_pay: "Modifica importo da pagare",
  edit_payment_request: "Modifica richiesta pagamento",
  add_payment: "Aggiungi pagamento",
  payment_type: "Tipo pagamento",
  choose_payment_type: "Scegli tipo pagamento",
  payment_date: "Data pagamento",
  choose_payment_date: "Scegli data pagamento",
  success_edit_payment_request_amount:
    "L'importo è stato aggiornato con successo!",
  fail_edit_payment_request_amount:
    "L'importo non è stato aggiornato con successo!",
  delete_player_payment_request: "Elimina richiesta pagamento per giocatore",
  delete_player_payment_request_message:
    "Sei sicuro di voler eliminare la richiesta di pagamento per il giocatore? L'azione è irreversibile.",
  match_squad: "Rosa partita",
  save_match_squad: "Salva rosa partita",
  delete_player_from_match_squad:
    "Sei sicuro di voler rimuovere questo giocatore dalla rosa della partita?",
  from: "Da...",
  until: "Fino a...",
  export_inactive_players: "Esporta giocatori inattivi",
  export_added_players: "Esporta giocatori aggiunti",
  export_added_inactive_players: "Esporta giocatori aggiunti/inattivi",
  club: "Club",
  date: "Data",
  financial: "Finanziario",
  min_characters: "Min. 8 caratteri",
  password_min_characters_err: "La password deve contenere almeno 8 caratteri",
  password_confirm_coincide_err: "La password e la conferma non coincidono",
  suspension_table: "Tabella sospensioni",
  import_suspension_update: "Importa aggiornamento sospensioni",
  home_match_abbreviation: "C",
  away_match_abbreviation: "T",
  no_data: "Non ci sono dati.",
  import_update_cards_management_success:
    "Importazione completata con successo!",
  import_update_cards_management_fail:
    "Importazione non completata con successo!",
  import_update_cards_management_text:
    "La partita importata aggiornerà solo i giocatori mappati finora.",
  import_cards_management_update: "Importa aggiornamento tabella sospensioni",
  functionality_request: "Richiedi funzionalità",
  describe_functionality: "Descrivi la funzionalità di cui avresti bisogno",
  functionality_request_success: "Funzionalità aggiunta con successo",
  functionality_request_danger: "Funzionalità non aggiunta con successo",
  send_request: "Invia richiesta",
  try_now: "Prova ora",
  new_functionalities: "Nuove funzionalità",
  no_functionality_yet: "Nessuna funzionalità disponibile",

  // Additional missing keys
  generate_training_raport: "Genera report allenamento",
  training_location: "Luogo allenamento",
  antrenament: "Sessione di allenamento",
  Video: "Video",

  // Match evaluation
  select_match_evaluation: "Seleziona valutazione partita",
  pitch_condition: "Condizione campo",
  weather_condition: "Condizioni meteo",
  game_play: "Gioco (Debolezze / Punti di forza)",
  opposing_player_mention: "Giocatori notevoli della squadra avversaria",
  save_details: "Salva dettagli",

  // Match officials
  generate_refree_report: "Genera report arbitro",
  reserve_referee: "Arbitro di riserva",
  obs_refree: "Osservatore arbitrale",
  obs_game: "Osservatore partita",
  assistant_referee: "Assistente arbitro",
  central_referee: "Arbitro centrale",
  refrees: "Arbitri",
  save_refrees: "Salva arbitri e osservatori",
  no_data_documents: "Nessun documento trovato",
  no_data_events: "Nessun evento trovato",
  first_half: "Primo tempo",
  second_half: "Secondo tempo",
  generate_match_report: "Genera report partita",
  full_time_result: "Risultato finale",

  // Match formation
  associate_player: "Associa giocatore",
  change_playing_formation: "Cambia formazione di gioco",
  change_playing_formation_message:
    "Sei sicuro di voler cambiare la formazione di gioco? I dati relativi alla distribuzione dei giocatori andranno persi.",
  yes_change: "Sì, cambia",
  success_save_playing_formation: "Formazione salvata con successo!",
  fail_save_playing_formation: "Formazione non salvata con successo!",
  import_lineups: "Importa formazione",
  import_also_substitutes: "Importa anche le riserve",
  success_import_lineup: "Formazione importata con successo!",
  fail_import_lineup: "Formazione non importata con successo!",
  delegate: "Delegato",
  delete_match_event: "Elimina evento partita",
  delete_match_event_success: "Evento partita eliminato con successo!",
  delete_match_event_fail: "Evento partita non eliminato con successo!",
  update_record_event_success: "Record modificato con successo!",
  update_record_event_fail: "Record non modificato con successo!",

  // Videos
  related_videos: "Video correlati",
  add_video_clip: "Aggiungi clip video",
  select_video: "Seleziona video",
  success_map_match_video: "Video aggiunto con successo!",
  fail_map_match_video: "Video non aggiunto con successo!",
  success_unmap_match_video: "Video rimosso con successo!",
  fail_unmap_match_video: "Video non rimosso con successo!",
  unmap_video_message: "Sei sicuro di voler rimuovere il video dalla partita?",

  // Reports
  delete_evaluation: "Elimina valutazione",
  delete_evalaution_message:
    "Sei sicuro di voler eliminare questa valutazione? Tutti i dati correlati andranno persi.",
  delete_physical_test_message:
    "Sei sicuro di voler eliminare questo test fisico? Tutti i dati correlati andranno persi.",
  delete_category_message:
    "L'azione è irreversibile. Sei sicuro di voler eliminare la categoria ",
  delete_category: "Elimina categoria",
  edit_evaluation: "Modifica valutazione",
  evaluation_name: "Nome valutazione",
  generate_evaluation_table: "Genera tabella valutazione",
  add_player_evaluation: "Aggiungi giocatore alla valutazione",
  evaluation_table: "Tabella valutazione",
  add_evaluation: "Aggiungi valutazione",
  players_evaluations: "Valutazioni giocatori",
  no_data_report_physical:
    "Aggiungi almeno un parametro e una squadra, poi aggiungi un giocatore per vedere la lista delle valutazioni",
  value: "Valore",
  update_evaluation_success: "Valutazione aggiornata con successo!",
  update_evaluation_fail: "Valutazione non aggiornata con successo!",
  evaluation_info: "Informazioni valutazione",
  select_evaluation_params: "Seleziona parametri valutazione",
  select_category: "Seleziona categoria",
  all_params: "Tutti i parametri",
  grade: "Voto",
  details: "Dettagli",
  success_add_evaluation: "Valutazione aggiunta con successo!",
  fail_add_evaluation: "Valutazione non aggiunta con successo!",
  success_update_evaluation: "Valutazione aggiornata con successo!",
  fail_update_evaluation: "Valutazione non aggiornata con successo!",
  success_delete_evaluation: "Valutazione eliminata con successo!",
  fail_delete_evaluation: "Valutazione non eliminata con successo!",
  save_evaluation: "Salva valutazione",
  no_evaluation: "Non ci sono valutazioni.",
  visible_in_mobile_app: "Visibile nell'app mobile",

  // Staff
  staff: "Staff",
  select: "Seleziona",

  // Video gallery
  success_video_added: "Tag video aggiunto con successo!",
  failed_video_added: "Tag video non aggiunto con successo!",
  success_video_edited: "Tag video modificato con successo!",
  failed_video_edited: "Tag video non modificato con successo!",

  // Staff licenses
  license_file: "File licenza",
  without_license: "Senza licenza",
  uefa_b: "UEFA B",
  uefa_a: "UEFA A",
  uefa_a_youth: "UEFA A Youth",
  uefa_b_youth: "UEFA B Youth",
  uefa_a_gk: "UEFA A GK",
  uefa_b_gk: "UEFA B GK",
  uefa_pro: "UEFA Pro",
  uefa_non_uefa: "NON-UEFA",
  uefa_c: "UEFA C",
  uefa_coaching_license: "Licenza allenatore",
  date_expire_license: "Data scadenza licenza",
  select_license: "Seleziona licenza",
  staff_license: "Licenza",
  add_certificate: "Aggiungi certificato",
  add_medical_tests: "Aggiungi test medici",

  // Gallery
  gallery_notification_fail: "File non caricati con successo!",
  gallery_notification_partial_upload:
    "File caricati parzialmente con successo!",
  gallery_notification_max_size_upload:
    "Dimensione massima file accettata 2 GB!",
  player_gallery_no_images: "Nessuna foto trovata.",
  player_gallery_no_videos: "Nessun video trovato.",
  player_gallery_delete_image_success: "Foto eliminata con successo.",
  player_gallery_delete_image_fail: "Foto non eliminata con successo.",
  edit_biography: "Modifica biografia",
  no_news: "Non ci sono notizie.",

  // Articles
  article_sub_title: "Sottotitolo",
  article_paragraph: "Paragrafo",
  article_image: "Immagine",
  success_add_news: "Notizia aggiunta con successo.",
  fail_add_news: "Notizia non aggiunta con successo.",
  success_delete_news: "Notizia eliminata con successo.",
  fail_delete_news: "Notizia non eliminata con successo.",
  success_edit_news: "Notizia modificata con successo.",
  fail_edit_news: "Notizia non modificata con successo.",

  // Audience
  select_audience: "Seleziona pubblico",
  only_my_club: "Solo il mio club",
  all_clubs: "Tutti i club",
  send_mobile_app_notification: "Invia notifica app mobile",

  // Medical
  expired: "Scaduto",
  expires_in_days: "Scade tra (giorni)",
  visa_expiration_date: "Data scadenza visto",
  date_of_examination: "Data visita",
  medical_certificates: "Certificati medici",
  no_medical_certificates: "Nessun certificato medico trovato.",
  add_medical_certificate: "Aggiungi certificato",
  date_of_certificate_examination: "Data visita certificato",
  expiry_date_examination: "Data scadenza certificato",
  proof_of_medical_certificate: "Prova certificato medico",
  date_of_medical_tests_examination: "Data test medici",
  expiry_date_medical_tests: "Data scadenza test medici",

  // Additional fields
  designation: "Nome",
  subcategory_designation: "Nome sottocategoria",
  type: "Tipo",
  dimension: "Dimensione",
  added_at: "Aggiunto il",
  added: "Aggiunto",

  // Additional missing keys
  select_parameters: "Seleziona parametri",

  // Error messages
  date_time_difference_error:
    "Il valore del Momento Iniziale deve essere minore del valore del Momento Finale",

  // ... existing code ...

  // Additional missing keys
  MENU: {
    NEW: "Nuovo",
    ACTIONS: "Azioni",
    CREATE_POST: "Crea nuovo post",
    PAGES: "Pagine",
    FEATURES: "Funzionalità",
    APPS: "Applicazioni",
    DASHBOARD: "Dashboard",
  },

  // Training related
  training_type: "Tipo di allenamento",
  training_types: "Tipi di allenamento",
  training_category: "Categoria allenamento",
  training_subcategory: "Sottocategoria allenamento",
  training_subcategories: "Sottocategorie allenamento",
  training_exercise: "Esercizio",
  training_exercises: "Esercizi",
  training_description: "Descrizione allenamento",
  training_objective: "Obiettivo allenamento",
  training_equipment: "Attrezzatura necessaria",
  training_duration: "Durata allenamento",
  training_intensity: "Intensità allenamento",
  training_notes: "Note allenamento",
  training_time: "Ora allenamento",
  training_coach: "Allenatore",
  training_group: "Gruppo allenamento",
  training_attendance: "Presenza allenamento",
  training_feedback: "Feedback allenamento",
  training_rating: "Valutazione allenamento",
  training_status: "Stato allenamento",
  training_progress: "Progresso allenamento",
  training_history: "Storico allenamenti",
  training_plan: "Piano allenamento",
  training_session: "Sessione allenamento",
  training_load: "Carico allenamento",
  training_intensity_low: "Bassa",
  training_intensity_medium: "Media",
  training_intensity_high: "Alta",
  training_status_planned: "Pianificato",
  training_status_completed: "Completato",
  training_status_cancelled: "Annullato",
  training_status_postponed: "Posticipato",

  // Match related
  match_type: "Tipo di partita",
  match_types: "Tipi di partita",
  match_category: "Categoria partita",
  match_categories: "Categorie partita",
  match_status: "Stato partita",
  match_statuses: "Stati partita",
  match_result: "Risultato partita",
  match_results: "Risultati partite",
  match_statistics: "Statistiche partita",
  match_lineup: "Formazione",
  match_lineups: "Formazioni",
  match_report: "Report partita",
  match_analysis: "Analisi partita",
  match_highlights: "Highlights partita",
  match_summary: "Riepilogo partita",
  match_details: "Dettagli partita",
  match_location: "Luogo partita",
  match_time: "Ora partita",
  match_duration: "Durata partita",
  match_referee: "Arbitro",
  match_officials: "Ufficiali di gara",
  match_attendance: "Presenza spettatori",
  match_conditions: "Condizioni partita",
  match_notes: "Note partita",
  match_status_scheduled: "Programmata",
  match_status_in_progress: "In corso",
  match_status_completed: "Completata",
  match_status_postponed: "Posticipata",
  match_status_cancelled: "Annullata",

  // Player related
  player_stats: "Statistiche giocatore",
  player_details: "Dettagli giocatore",
  player_development: "Sviluppo giocatore",
  player_evaluation: "Valutazione giocatore",
  player_salary: "Stipendio giocatore",
  player_bonuses: "Bonus giocatore",
  player_equipment: "Attrezzatura giocatore",
  player_status: "Stato giocatore",
  player_availability: "Disponibilità giocatore",
  player_injuries: "Infortuni giocatore",
  player_suspensions: "Squalifiche giocatore",
  player_achievements: "Risultati giocatore",
  player_goals: "Gol giocatore",
  player_assists: "Assist giocatore",
  player_cards: "Cartellini giocatore",
  player_minutes: "Minuti giocati",
  player_appearances: "Presenze",
  player_rating: "Valutazione",
  player_position_goalkeeper: "Portiere",
  player_position_defender: "Difensore",
  player_position_midfielder: "Centrocampista",
  player_position_forward: "Attaccante",

  // Team related
  team_stats: "Statistiche squadra",
  team_history: "Storico squadra",
  team_details: "Dettagli squadra",
  team_profile: "Profilo squadra",
  team_development: "Sviluppo squadra",
  team_evaluation: "Valutazione squadra",
  team_medical: "Cartella medica squadra",
  team_documents: "Documenti squadra",
  team_notes: "Note squadra",
  team_status: "Stato squadra",
  team_achievements: "Risultati squadra",
  team_goals: "Gol squadra",
  team_assists: "Assist squadra",
  team_cards: "Cartellini squadra",
  team_minutes: "Minuti giocati squadra",
  team_appearances: "Presenze squadra",
  team_rating: "Valutazione squadra",

  // Competition related
  competition_types: "Tipi di competizione",
  competition_category: "Categoria competizione",
  competition_categories: "Categorie competizione",
  competition_status: "Stato competizione",
  competition_statuses: "Stati competizione",
  competition_result: "Risultato competizione",
  competition_results: "Risultati competizioni",
  competition_statistics: "Statistiche competizione",
  competition_fixtures: "Partite",
  competition_teams: "Squadre",
  competition_players: "Giocatori",
  competition_details: "Dettagli competizione",
  competition_round: "Turno",
  competition_group: "Gruppo",
  competition_phase: "Fase",
  competition_status_upcoming: "In arrivo",
  competition_status_ongoing: "In corso",
  competition_status_completed: "Completata",
  competition_status_cancelled: "Annullata",

  // Staff related
  staff_type: "Tipo di staff",
  staff_types: "Tipi di staff",
  staff_role: "Ruolo staff",
  staff_roles: "Ruoli staff",
  staff_details: "Dettagli staff",
  staff_profile: "Profilo staff",
  staff_documents: "Documenti staff",
  staff_contract: "Contratto staff",
  staff_salary: "Stipendio staff",
  staff_bonuses: "Bonus staff",
  staff_notes: "Note staff",
  staff_status: "Stato staff",
  staff_availability: "Disponibilità staff",
  staff_achievements: "Risultati staff",
  staff_experience: "Esperienza staff",
  staff_qualifications: "Qualifiche staff",
  staff_certifications: "Certificazioni staff",
  staff_specializations: "Specializzazioni staff",
  staff_responsibilities: "Responsabilità staff",
  staff_schedule: "Programma staff",
  staff_attendance: "Presenza staff",
  staff_performance: "Prestazioni staff",
  staff_evaluation: "Valutazione staff",
  staff_feedback: "Feedback staff",
  staff_development: "Sviluppo staff",
  staff_training: "Formazione staff",

  // Facility related
  facility_type: "Tipo di struttura",
  facility_types: "Tipi di struttura",
  facility_name: "Nome struttura",
  facility_location: "Posizione struttura",
  facility_address: "Indirizzo struttura",
  facility_contact: "Contatto struttura",
  facility_capacity: "Capacità struttura",
  facility_availability: "Disponibilità struttura",
  facility_schedule: "Programma struttura",
  facility_status: "Stato struttura",
  facility_notes: "Note struttura",
  facility_documents: "Documenti struttura",
  facility_images: "Immagini struttura",
  facility_videos: "Video struttura",
  facility_booking: "Prenotazione struttura",
  facility_rental: "Affitto struttura",
  facility_costs: "Costi struttura",
  facility_rules: "Regolamento struttura",
  facility_access: "Accesso struttura",
  facility_emergency: "Emergenza struttura",

  // Equipment related
  equipment_types: "Tipi di attrezzatura",
  equipment_description: "Descrizione attrezzatura",
  equipment_condition: "Condizione attrezzatura",
  equipment_maintenance: "Manutenzione attrezzatura",
  equipment_documents: "Documenti attrezzatura",
  equipment_images: "Immagini attrezzatura",
  equipment_cost: "Costo attrezzatura",
  equipment_supplier: "Fornitore attrezzatura",
  equipment_purchase_date: "Data acquisto attrezzatura",
  equipment_warranty: "Garanzia attrezzatura",
  equipment_checkout: "Prestito attrezzatura",
  equipment_return: "Restituzione attrezzatura",
  equipment_history: "Storico attrezzatura",

  // Finance related
  finance_type: "Tipo di finanza",
  finance_types: "Tipi di finanza",
  finance_category: "Categoria finanza",
  finance_categories: "Categorie finanza",
  finance_status: "Stato finanza",
  finance_statuses: "Stati finanza",
  finance_transaction: "Transazione",
  finance_transactions: "Transazioni",
  finance_income: "Entrate",
  finance_expense: "Uscite",
  finance_budget: "Budget",
  finance_forecast: "Previsione",
  finance_report: "Report finanziario",
  finance_balance: "Bilancio",
  finance_statement: "Estratto conto",
  finance_invoice: "Fattura",
  finance_receipt: "Ricevuta",
  finance_payment: "Pagamento",
  finance_refund: "Rimborso",
  finance_tax: "Tasse",
  finance_salary: "Stipendio",
  finance_bonus: "Bonus",
  finance_deduction: "Deduzione",
  finance_sponsorship: "Sponsorizzazione",
  finance_donation: "Donazione",
  finance_grant: "Sovvenzione",
  finance_loan: "Prestito",
  finance_debt: "Debito",
  finance_asset: "Bene",
  finance_liability: "Passività",
  finance_equity: "Patrimonio netto",

  // Medical related
  medical_type: "Tipo di visita medica",
  medical_types: "Tipi di visite mediche",
  medical_category: "Categoria medica",
  medical_categories: "Categorie mediche",
  medical_status: "Stato medico",
  medical_statuses: "Stati medici",
  medical_condition: "Condizione medica",
  medical_conditions: "Condizioni mediche",
  medical_treatment: "Trattamento medico",
  medical_treatments: "Trattamenti medici",
  medical_test: "Test medico",
  medical_tests: "Test medici",
  medical_result: "Risultato medico",
  medical_results: "Risultati medici",
  medical_report: "Report medico",
  medical_reports: "Report medici",
  medical_history: "Storia medica",
  medical_prescription: "Prescrizione medica",
  medical_prescriptions: "Prescrizioni mediche",
  medical_medication: "Medicazione",
  medical_medications: "Medicazioni",
  medical_diagnosis: "Diagnosi",
  medical_diagnoses: "Diagnosi",
  medical_procedure: "Procedura medica",
  medical_procedures: "Procedure mediche",
  medical_examination: "Esame medico",
  medical_examinations: "Esami medici",
  medical_insurance: "Assicurazione medica",
  medical_emergency: "Emergenza medica",
  medical_staff: "Staff medico",
  medical_facility: "Struttura medica",
  medical_equipment: "Attrezzatura medica",
  medical_supplies: "Forniture mediche",
  medical_notes: "Note mediche",
  medical_documents: "Documenti medici",
  medical_images: "Immagini mediche",
  medical_records: "Cartelle cliniche",

  // Document related
  document_type: "Tipo di documento",
  document_category: "Categoria documento",
  document_categories: "Categorie documento",
  document_status: "Stato documento",
  document_statuses: "Stati documento",
  document_title: "Titolo documento",
  document_description: "Descrizione documento",
  document_content: "Contenuto documento",
  document_author: "Autore documento",
  document_date: "Data documento",
  document_version: "Versione documento",
  document_format: "Formato documento",
  document_size: "Dimensione documento",
  document_location: "Posizione documento",
  document_tags: "Tag documento",
  document_notes: "Note documento",
  document_history: "Storico documento",
  document_permissions: "Permessi documento",
  document_sharing: "Condivisione documento",
  document_template: "Template documento",
  document_signature: "Firma documento",
  document_expiry: "Scadenza documento",
  document_verification: "Verifica documento",
  document_archive: "Archivio documenti",

  // Event related
  event_types: "Tipi di evento",
  event_categories: "Categorie evento",
  event_statuses: "Stati evento",
  event_title: "Titolo evento",
  event_duration: "Durata evento",
  event_organizer: "Organizzatore evento",
  event_notes: "Note evento",
  event_documents: "Documenti evento",
  event_images: "Immagini evento",
  event_videos: "Video evento",
  event_schedule: "Programma evento",
  event_registration: "Registrazione evento",
  event_attendance: "Presenza evento",
  event_feedback: "Feedback evento",
  event_cost: "Costo evento",
  event_budget: "Budget evento",
  event_sponsors: "Sponsor evento",
  event_marketing: "Marketing evento",
  event_logistics: "Logistica evento",
  event_security: "Sicurezza evento",
  event_emergency: "Emergenza evento",
  event_insurance: "Assicurazione evento",

  // Communication related
  communication_type: "Tipo di comunicazione",
  communication_types: "Tipi di comunicazione",
  communication_category: "Categoria comunicazione",
  communication_categories: "Categorie comunicazione",
  communication_status: "Stato comunicazione",
  communication_statuses: "Stati comunicazione",
  communication_subject: "Oggetto comunicazione",
  communication_message: "Messaggio comunicazione",
  communication_sender: "Mittente comunicazione",
  communication_recipient: "Destinatario comunicazione",
  communication_date: "Data comunicazione",
  communication_time: "Ora comunicazione",
  communication_channel: "Canale comunicazione",
  communication_priority: "Priorità comunicazione",
  communication_direction: "Direzione comunicazione",
  communication_response: "Risposta comunicazione",
  communication_notes: "Note comunicazione",
  communication_documents: "Documenti comunicazione",
  communication_history: "Storico comunicazione",
  communication_template: "Template comunicazione",
  communication_preferences: "Preferenze comunicazione",
  communication_settings: "Impostazioni comunicazione",
  communication_notifications: "Notifiche comunicazione",
  communication_alerts: "Avvisi comunicazione",
  communication_reminders: "Promemoria comunicazione",
  communication_subscriptions: "Iscrizioni comunicazione",
  communication_broadcasts: "Trasmissioni comunicazione",
  communication_campaigns: "Campagne comunicazione",
  communication_analytics: "Analisi comunicazione",

  // Report related
  report_types: "Tipi di report",
  report_category: "Categoria report",
  report_categories: "Categorie report",
  report_status: "Stato report",
  report_statuses: "Stati report",
  report_title: "Titolo report",
  report_description: "Descrizione report",
  report_date: "Data report",
  report_author: "Autore report",
  report_recipient: "Destinatario report",
  report_content: "Contenuto report",
  report_summary: "Riepilogo report",
  report_details: "Dettagli report",
  report_analysis: "Analisi report",
  report_recommendations: "Raccomandazioni report",
  report_conclusions: "Conclusioni report",
  report_attachments: "Allegati report",
  report_distribution: "Distribuzione report",
  report_access: "Accesso report",
  report_permissions: "Permessi report",
  report_history: "Storico report",
  report_archive: "Archivio report",
  report_analytics: "Analisi report",
  report_metrics: "Metriche report",
  report_charts: "Grafici report",
  report_tables: "Tabelle report",
  report_export: "Esportazione report",
  report_import: "Importazione report",
  report_schedule: "Programmazione report",
  report_notification: "Notifica report",
  report_reminder: "Promemoria report",
  report_feedback: "Feedback report",
  report_comments: "Commenti report",
  report_review: "Revisione report",
  report_approval: "Approvazione report",
  report_rejection: "Rifiuto report",
  report_revision: "Revisione report",
  report_version: "Versione report",
  report_notes: "Note report",

  // Settings related
  settings_general: "Impostazioni generali",
  settings_profile: "Impostazioni profilo",
  settings_account: "Impostazioni account",
  settings_security: "Impostazioni sicurezza",
  settings_privacy: "Impostazioni privacy",
  settings_notifications: "Impostazioni notifiche",
  settings_communication: "Impostazioni comunicazione",
  settings_language: "Impostazioni lingua",
  settings_region: "Impostazioni regione",
  settings_timezone: "Impostazioni fuso orario",
  settings_currency: "Impostazioni valuta",
  settings_units: "Impostazioni unità",
  settings_display: "Impostazioni display",
  settings_theme: "Impostazioni tema",
  settings_accessibility: "Impostazioni accessibilità",
  settings_preferences: "Impostazioni preferenze",
  settings_integrations: "Impostazioni integrazioni",
  settings_api: "Impostazioni API",
  settings_backup: "Impostazioni backup",
  settings_restore: "Impostazioni ripristino",
  settings_import: "Impostazioni importazione",
  settings_export: "Impostazioni esportazione",
  settings_sync: "Impostazioni sincronizzazione",
  settings_storage: "Impostazioni archiviazione",
  settings_cache: "Impostazioni cache",
  settings_updates: "Impostazioni aggiornamenti",
  settings_maintenance: "Impostazioni manutenzione",
  settings_diagnostics: "Impostazioni diagnostica",
  settings_logs: "Impostazioni log",
  settings_analytics: "Impostazioni analisi",
  settings_permissions: "Impostazioni permessi",
  settings_roles: "Impostazioni ruoli",
  settings_users: "Impostazioni utenti",
  settings_groups: "Impostazioni gruppi",
  settings_organizations: "Impostazioni organizzazioni",
  settings_billing: "Impostazioni fatturazione",
  settings_subscription: "Impostazioni abbonamento",
  settings_payment: "Impostazioni pagamento",
  settings_taxes: "Impostazioni tasse",

  // Error messages
  error_general: "Si è verificato un errore. Riprova più tardi.",
  error_network: "Errore di rete. Controlla la tua connessione.",
  error_server: "Errore del server. Riprova più tardi.",
  error_validation: "Errore di validazione. Controlla i dati inseriti.",
  error_authentication: "Errore di autenticazione. Accedi di nuovo.",
  error_authorization:
    "Errore di autorizzazione. Non hai i permessi necessari.",
  error_not_found: "Risorsa non trovata.",
  error_already_exists: "La risorsa esiste già.",
  error_invalid_input: "Input non valido.",
  error_required_field: "Campo obbligatorio.",
  error_invalid_format: "Formato non valido.",
  error_file_size: "Dimensione file troppo grande.",
  error_file_type: "Tipo di file non supportato.",
  error_upload: "Errore durante il caricamento del file.",
  error_download: "Errore durante il download del file.",
  error_delete: "Errore durante l'eliminazione.",
  error_update: "Errore durante l'aggiornamento.",
  error_create: "Errore durante la creazione.",
  error_save: "Errore durante il salvataggio.",
  error_load: "Errore durante il caricamento.",
  error_process: "Errore durante l'elaborazione.",
  error_timeout: "Timeout della richiesta.",
  error_conflict: "Conflitto di risorse.",
  error_permission: "Permesso negato.",
  error_quota: "Quota superata.",
  error_rate_limit: "Limite di richieste superato.",
  error_maintenance: "Sistema in manutenzione.",
  error_deprecated: "Funzionalità deprecata.",
  error_unsupported: "Funzionalità non supportata.",
  error_unavailable: "Servizio non disponibile.",

  // Success messages
  success_general: "Operazione completata con successo.",
  success_save: "Salvataggio completato con successo.",
  success_update: "Aggiornamento completato con successo.",
  success_delete: "Eliminazione completata con successo.",
  success_create: "Creazione completata con successo.",
  success_upload: "Caricamento completato con successo.",
  success_download: "Download completato con successo.",
  success_import: "Importazione completata con successo.",
  success_export: "Esportazione completata con successo.",
  success_submit: "Invio completato con successo.",
  success_process: "Elaborazione completata con successo.",
  success_verify: "Verifica completata con successo.",
  success_register: "Registrazione completata con successo.",
  success_login: "Accesso completato con successo.",
  success_logout: "Disconnessione completata con successo.",
  success_reset: "Reset completato con successo.",
  success_restore: "Ripristino completato con successo.",
  success_backup: "Backup completato con successo.",
  success_sync: "Sincronizzazione completata con successo.",
  success_share: "Condivisione completata con successo.",

  //Aside menu list

  financial_aside_title: "Finanziario",
  reports_aside_title: "Rapporti",

  account_suspension: "Account Sospeso",
  suspended_account_text:
    "L'account del tuo club è stato sospeso. Tutte le informazioni sono al sicuro e saranno disponibili non appena il problema sarà risolto",
  back_to_login: "Torna al login",

  suspension_status: "Stato sospensione",
  goals_stats: "Statistiche gol",
  press: "Stampa",
  ab_list: "Lista A/B",
  bonuses_monitoring: "Monitoraggio bonus",

  costs: "Costi",
  edit_costs: "Modifica spese",
  online_payment_list: "Lista pagamenti online",
  no_online_payments: "Nessun pagamento online trovato.",
  necessity_reports: "Rapporti di necessità",
  upload_bank_account_statements: "Carica estratti conto bancari",
  upload_bank_account_statements_amount_column:
    "Seleziona la colonna da associare agli importi dei pagamenti:",
  upload_bank_account_statements_date_column:
    "Seleziona la colonna da associare alla data della transazione:",
  upload_bank_account_statements_payer_column:
    "Seleziona la colonna contenente il nome del pagante:",
  select_column: "Seleziona colonna",

  // AI Chat
  aiChat: "AI Chat",
  feedbackMessageSuccess: "Il feedback è stato inviato con successo!",
  feedbackMessageError: "Il feedback non è stato inviato con successo!",
  history: "Cronologia",
  feedback_placeholder: "Spiega cosa non va con questa risposta...",
};
