import React from "react";
import styles from "./AIChatPage.module.scss";

export default function AiChatTitle({ title, description }) {
  return (
    <div className={styles.aiPrompt}>
      <div className={styles.aiPromptTitle}>{title}</div>

      <div className={styles.aiPromptDescription}>{description}</div>
    </div>
  );
}
