import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styles from "./AIChat.module.scss";
import { aiChatGridItems } from "../../../../app/utils/utilFunctions";
import TFBIconButton from "../../../../app/components/design-system/IconButton/TFBIconButton";
import { sendMessage } from "../../../../redux/aiChat/aiChat.actions";

const AIChat = ({
  wrapperStyle,
  gridPosition = "bottom",
  textAreaContainerStyle,
  onClickSend,
  sendMessage,
  isLoading,
  token,
}) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [query, setQuery] = useState("");
  const [randomItems, setRandomItems] = useState([]);

  // Function to get 4 random items
  const getRandomItems = () => {
    const shuffled = [...aiChatGridItems].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 4);
  };

  // Update random items when suggestions are shown
  useEffect(() => {
    if (showSuggestions) {
      setRandomItems(getRandomItems());
    }
  }, [showSuggestions]);

  useEffect(() => {
    const handleClick = (e) => {
      if (!e.target.closest(".ai-chat-container")) {
        setShowSuggestions(false);
      }
    };

    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);

  const handleSend = () => {
    if (query.trim() === "") return;
    sendMessage(query, token);
    onClickSend();
    setQuery("");
  };

  const handleChangeQuery = (query) => {
    setQuery(query);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (e.shiftKey) {
        return;
      } else {
        e.preventDefault();
        if (query.trim() !== "") {
          handleSend();
        }
      }
    }
  };

  const handleTextareaInput = (e) => {
    const textarea = e.target;
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
    setQuery(e.target.value);
  };

  return (
    <div className={styles.wrapper} style={wrapperStyle}>
      {showSuggestions && gridPosition === "top" && (
        <div className={styles.aiChatGrid}>
          {randomItems.map((item) => (
            <div
              key={item}
              onClick={() => handleChangeQuery(item.toString())}
              className={styles.aiChatGridItem}
            >
              {item}
            </div>
          ))}
        </div>
      )}
      <div
        className={styles.aiChatContainer}
        onMouseEnter={() => setShowSuggestions(true)}
        style={textAreaContainerStyle}
      >
        <img
          className={styles.aiChatIcon}
          src={"/media/logos/logo-dark.png"}
          alt="aiChatIcon"
        ></img>
        <textarea
          type="text"
          value={query}
          onKeyDown={handleKeyDown}
          onChange={handleTextareaInput}
          placeholder="Întreabă creierul..."
          className={styles.aiChatInput}
          rows="1"
        />

        {query && (
          <TFBIconButton
            style={{ borderRadius: "50%" }}
            name="arrow-up"
            alt="send"
            color="green"
            loading={isLoading}
            onClick={handleSend}
          />
        )}
      </div>
      {showSuggestions && gridPosition === "bottom" && (
        <div className={styles.aiChatGrid}>
          {randomItems.map((item) => (
            <div
              key={item}
              onClick={() => handleChangeQuery(item.toString())}
              className={styles.aiChatGridItem}
            >
              {item}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.auth?.user?.token,
  conversation: state.aiChat.conversation,
  isLoading: state.aiChat.isLoading,
});

const mapDispatchToProps = {
  sendMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(AIChat);
