import React, { useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { useSelector, connect } from "react-redux";

import { checkIsActive } from "../../../../_helpers";
import * as utilFunctions from "./../../../../../app/utils/utilFunctions";
import { rootPages } from "../../../../../app/utils/constants";
import permissions from "./../../../../../app/utils/permissions";
import asideMenuListSVG from "../../../../../app/utils/asideMenuListSVG";

import TFBIcon from "../../../../../app/components/design-system/Icon/TFBIcon";
import TFBDialog from "../../../../../app/components/design-system/Dialog/TFBDialog";

import VideoList from "../../../../../app/DemoVideos/VideoList";

function AsideMenuList(props) {
  const { isTFB } = utilFunctions;

  const [isVideoTutorialOpen, setIsVideoTutorialOpen] = useState(false);
  const [videoTutorialSrc, setVideoTutorialSrc] = useState("");
  const [videoTutorialName, setVideoTutorialName] = useState("");

  function handleClick(title, src) {
    setIsVideoTutorialOpen(true);
    setVideoTutorialName(() => title);
    setVideoTutorialSrc(() => src);
  }

  const layoutProps = props.layoutProps;
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };
  const { languagePack, user_permissions } = useSelector(
    ({ auth, layoutService: { languagePack } }) => ({
      languagePack,
      user_permissions: auth?.user?.user_permissions || {},
    })
  );

  const currentSectionPage = utilFunctions.getCurrentRootPage(
    window.location.pathname
  );

  const handleCloseAsideMenu = () => {
    if (document.body.classList.contains("aside-minimize")) {
      document.body.classList.remove("aside-minimize");
      document.body.classList.add("aside-minimize-cancel");
    }
  };

  const disabledStyle = {
    filter: "opacity(0.2)",
    pointerEvents: "none",
  };

  const AsideMenuListItem = ({ src, text }) => (
    <>
      <span className="svg-icon menu-icon">
        <img src={src} style={{ maxHeight: "23px" }} />
      </span>
      <span className="menu-text">{text}</span>
      <TFBIcon name="angle-right" color="black" style={{ height: 13 }} />
    </>
  );

  const AsideMenuListTitle = ({ text, style = {} }) => (
    <li
      className="menu-section "
      style={{
        height:
          document.getElementById("tfb-logo-brand")?.clientHeight ?? "initial",
        ...style,
      }}
    >
      <h4 className="menu-text">{text}</h4>
    </li>
  );

  const disableAccess = (e) => {
    e.preventDefault();
  };

  return (
    <>
      {(location.pathname === "/dashboard" ||
        location.pathname === "/notifications" ||
        location.pathname === "/dashboard/ai-chat" ||
        currentSectionPage === rootPages.CLUB) && (
        <>
          <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            <AsideMenuListTitle text={languagePack.club_management} />

            {location.pathname.includes("/dashboard") &&
              window.innerWidth > 1023 && (
                <li
                  className={`menu-item ai-chat-menu-item menu-item-submenu ${new RegExp(
                    `\/dashboard/ai-chat$`
                  ).test(window.location.pathname) &&
                    " menu-item-active"} ${getMenuItemActive(
                    "/react-bootstrap",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  style={
                    user_permissions?.[permissions.CLUB]?.[permissions.TEAM]
                      ? {}
                      : disabledStyle
                  }
                >
                  <NavLink
                    className="menu-link menu-toggle"
                    to="/dashboard/ai-chat"
                    onClick={handleCloseAsideMenu}
                    {...(!user_permissions?.[permissions.CLUB]?.[
                      permissions.TEAM
                    ] && { onClick: disableAccess })}
                  >
                    <AsideMenuListItem
                      src={"/media/logos/logo-dark.png"}
                      text={"TFB AI"}
                    />
                  </NavLink>
                </li>
              )}
            <li
              className={`menu-item menu-item-submenu ${new RegExp(
                `\/club$`
              ).test(window.location.pathname) &&
                " menu-item-active"} ${getMenuItemActive(
                "/react-bootstrap",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
              style={
                user_permissions?.[permissions.CLUB]?.[permissions.TEAM]
                  ? {}
                  : disabledStyle
              }
            >
              <NavLink
                className="menu-link menu-toggle"
                to="/club"
                onClick={handleCloseAsideMenu}
                {...(!user_permissions?.[permissions.CLUB]?.[
                  permissions.TEAM
                ] && { onClick: disableAccess })}
              >
                <AsideMenuListItem
                  src={asideMenuListSVG.TEAM}
                  text={languagePack.teams}
                />
              </NavLink>
            </li>

            <li
              className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
                window.location.pathname,
                "/club/calendar"
              ) && " menu-item-active"} ${getMenuItemActive(
                "/react-bootstrap",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
              style={
                user_permissions?.[permissions.CLUB]?.[permissions.CALENDAR]
                  ? {}
                  : disabledStyle
              }
            >
              <NavLink
                className="menu-link menu-toggle"
                to="/club/calendar"
                onClick={handleCloseAsideMenu}
                {...(!user_permissions?.[permissions.CLUB]?.[
                  permissions.CALENDAR
                ] && { onClick: disableAccess })}
              >
                <AsideMenuListItem
                  src={asideMenuListSVG.CALENDAR}
                  text={languagePack.calendar}
                />
              </NavLink>
            </li>

            <li
              className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
                window.location.pathname,
                "/club/matches"
              ) && " menu-item-active"} ${getMenuItemActive(
                "/react-bootstrap",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
              style={
                user_permissions?.[permissions.CLUB]?.[permissions.MATCH]
                  ? {}
                  : disabledStyle
              }
            >
              <NavLink
                className="menu-link menu-toggle"
                to="/club/matches?type=past"
                onClick={handleCloseAsideMenu}
                {...(!user_permissions?.[permissions.CLUB]?.[
                  permissions.MATCH
                ] && { onClick: disableAccess })}
              >
                <AsideMenuListItem
                  src={asideMenuListSVG.MATCH}
                  text={languagePack.matches_schedule}
                />
              </NavLink>
            </li>

            <li
              className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
                window.location.pathname,
                "/club/competitions"
              ) && " menu-item-active"} ${getMenuItemActive(
                "/react-bootstrap",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
              style={
                user_permissions?.[permissions.CLUB]?.[permissions.COMPETITION]
                  ? {}
                  : disabledStyle
              }
            >
              <NavLink
                className="menu-link menu-toggle"
                to="/club/competitions"
                onClick={handleCloseAsideMenu}
                {...(!user_permissions?.[permissions.CLUB]?.[
                  permissions.COMPETITION
                ] && { onClick: disableAccess })}
              >
                <AsideMenuListItem
                  src={asideMenuListSVG.COMPETITION}
                  text={languagePack.competitions}
                />
              </NavLink>
            </li>

            {isTFB() && (
              <li
                className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
                  window.location.pathname,
                  "/club/venue-management"
                ) && " menu-item-active"} ${getMenuItemActive(
                  "/react-bootstrap",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
                style={
                  user_permissions?.[permissions.CLUB]?.[permissions.VENUE]
                    ? {}
                    : disabledStyle
                }
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/club/venue-management"
                  onClick={handleCloseAsideMenu}
                  {...(!user_permissions?.[permissions.CLUB]?.[
                    permissions.VENUE
                  ] && { onClick: disableAccess })}
                >
                  <AsideMenuListItem
                    src={asideMenuListSVG.VENUE}
                    text={languagePack.venue_management}
                  />
                </NavLink>
              </li>
            )}

            {isTFB() && (
              <li
                className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
                  window.location.pathname,
                  "/club/cards-management"
                ) && " menu-item-active"} ${getMenuItemActive(
                  "/react-bootstrap",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
                style={
                  user_permissions?.[permissions.CLUB]?.[
                    permissions.CARDS_MANAGEMENT
                  ]
                    ? {}
                    : disabledStyle
                }
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/club/cards-management"
                  onClick={handleCloseAsideMenu}
                  {...(!user_permissions?.[permissions.CLUB]?.[
                    permissions.CARDS_MANAGEMENT
                  ] && { onClick: disableAccess })}
                >
                  <AsideMenuListItem
                    src={asideMenuListSVG.CARDS_MANAGEMENT}
                    text={languagePack.suspension_status}
                  />
                </NavLink>
              </li>
            )}

            {isTFB() && (
              <li
                className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
                  window.location.pathname,
                  "/club/stats/goals"
                ) && " menu-item-active"} ${getMenuItemActive(
                  "/react-bootstrap",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
                style={
                  user_permissions?.[permissions.CLUB]?.[
                    permissions.GOALS_STATS
                  ]
                    ? {}
                    : disabledStyle
                }
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/club/stats/goals"
                  onClick={handleCloseAsideMenu}
                  {...(!user_permissions?.[permissions.CLUB]?.[
                    permissions.GOALS_STATS
                  ] && { onClick: disableAccess })}
                >
                  <AsideMenuListItem
                    src={asideMenuListSVG.GOALS_STATS}
                    text={languagePack.goals_stats}
                  />
                </NavLink>
              </li>
            )}
            {isTFB() && (
              <li
                className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
                  window.location.pathname,
                  "/club/press"
                ) && " menu-item-active"} ${getMenuItemActive(
                  "/react-bootstrap",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
                style={
                  user_permissions?.[permissions.CLUB]?.[permissions.PRESS]
                    ? {}
                    : disabledStyle
                }
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/club/press"
                  onClick={handleCloseAsideMenu}
                  {...(!user_permissions?.[permissions.CLUB]?.[
                    permissions.PRESS
                  ] && { onClick: disableAccess })}
                >
                  <AsideMenuListItem
                    src={asideMenuListSVG.NEWS}
                    text={languagePack.press}
                  />
                </NavLink>
              </li>
            )}

            {isTFB() && (
              <li
                className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
                  window.location.pathname,
                  "/club/frf-lists"
                ) && " menu-item-active"} ${getMenuItemActive(
                  "/react-bootstrap",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
                style={
                  user_permissions?.[permissions.CLUB]?.[permissions.FRF_LIST]
                    ? {}
                    : disabledStyle
                }
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/club/frf-lists"
                  onClick={handleCloseAsideMenu}
                  {...(!user_permissions?.[permissions.CLUB]?.[
                    permissions.FRF_LIST
                  ] && { onClick: disableAccess })}
                >
                  <AsideMenuListItem
                    src={asideMenuListSVG.FRF_LIST}
                    text={languagePack.ab_list}
                  />
                </NavLink>
              </li>
            )}

            {isTFB() && (
              <li
                className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
                  window.location.pathname,
                  "/club/enrolment-requests"
                ) && " menu-item-active"} ${getMenuItemActive(
                  "/react-bootstrap",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
                style={
                  user_permissions?.[permissions.CLUB]?.[
                    permissions.ENROLMENT_REQUEST
                  ]
                    ? {}
                    : disabledStyle
                }
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/club/enrolment-requests"
                  onClick={handleCloseAsideMenu}
                  {...(!user_permissions?.[permissions.CLUB]?.[
                    permissions.ENROLMENT_REQUEST
                  ] && { onClick: disableAccess })}
                >
                  <AsideMenuListItem
                    src={asideMenuListSVG.ENROLMENT_REQUEST}
                    text={languagePack.enrolment_request}
                  />
                </NavLink>
              </li>
            )}
          </ul>
        </>
      )}

      {isTFB() && currentSectionPage === rootPages.TRAINING && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <AsideMenuListTitle text={languagePack.trainings} />

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/training/plan"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.TRAINING]?.[
                permissions.TRAINING_PLANING
              ]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/training/plan"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.TRAINING]?.[
                permissions.TRAINING_PLANING
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.TRAINING_PLANING}
                text={languagePack.plan_training}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/training/history"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.TRAINING]?.[
                permissions.TRAINING_HISTORY
              ]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/training/history"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.TRAINING]?.[
                permissions.TRAINING_HISTORY
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.TRAINING_HISTORY}
                text={languagePack.trainings_history}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/training/weight"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.TRAINING]?.[
                permissions.WEIGHT_CONTROL
              ]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/training/weight"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.TRAINING]?.[
                permissions.WEIGHT_CONTROL
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.WEIGHT_CONTROL}
                text={languagePack.weight_control}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/training/sections"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.TRAINING]?.[
                permissions.TRAINING_SECTION
              ]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/training/sections"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.TRAINING]?.[
                permissions.TRAINING_SECTION
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.TRAINING_SECTION}
                text={languagePack.training_sections}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/training/report-presence"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.TRAINING]?.[
                permissions.TRAINING_PRESENCE_REPORT
              ]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/training/report-presence"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.TRAINING]?.[
                permissions.TRAINING_PRESENCE_REPORT
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.TRAINING_PRESENCE_REPORT}
                text={languagePack.attendance_report}
              />
            </NavLink>
          </li>
        </ul>
      )}

      {isTFB() && currentSectionPage === rootPages.REPORTS && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <AsideMenuListTitle text={languagePack.reports_aside_title} />

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/reports/report"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.REPORT]?.[
                permissions.PHYSICAL_TEST
              ]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/reports/report"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.REPORT]?.[
                permissions.PHYSICAL_TEST
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.PHYSICAL_TEST}
                text={languagePack.physical_tests}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/reports/evaluation"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.REPORT]?.[permissions.EVALUATION]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/reports/evaluation"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.REPORT]?.[
                permissions.EVALUATION
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.EVALUATION}
                text={languagePack.evaluation_reports}
              />
            </NavLink>
          </li>
        </ul>
      )}

      {isTFB() && currentSectionPage === rootPages.FINANCIAL && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <AsideMenuListTitle text={languagePack.financial_aside_title} />
          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/financial/all-players-payments"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.FINANCIAL]?.[
                permissions.PAYMENT_MANAGEMENT
              ]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/financial/all-players-payments"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.FINANCIAL]?.[
                permissions.PAYMENT_MANAGEMENT
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.PAYMENT_MANAGEMENT}
                text={languagePack.payment_management}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/financial/financial-report"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.FINANCIAL]?.[
                permissions.FINANCIAL_REPORT
              ]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/financial/financial-report"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.FINANCIAL]?.[
                permissions.FINANCIAL_REPORT
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.FINANCIAL_REPORT}
                text={languagePack.financial_report}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/financial/payment-request"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.FINANCIAL]?.[
                permissions.PAYMENT_REQUEST
              ]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/financial/payment-request"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.FINANCIAL]?.[
                permissions.PAYMENT_REQUEST
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.PAYMENT_REQUEST}
                text={languagePack.payment_requests}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/financial/costs"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.FINANCIAL]?.[permissions.COST]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/financial/costs"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.FINANCIAL]?.[
                permissions.COST
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.COST}
                text={languagePack.costs}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/financial/online-payments"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.FINANCIAL]?.[
                permissions.ONLINE_PAYMENT
              ]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/financial/online-payments"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.FINANCIAL]?.[
                permissions.ONLINE_PAYMENT
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.ONLINE_PAYMENT}
                text={languagePack.online_payment_list}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/financial/necessity-reports"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.FINANCIAL]?.[
                permissions.NECCESSITY_REPORT
              ]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/financial/necessity-reports"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.FINANCIAL]?.[
                permissions.NECCESSITY_REPORT
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.NECCESSITY_REPORT}
                text={languagePack.necessity_reports}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/financial/justifying-document"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.FINANCIAL]?.[
                permissions.JUSTIFYING_DOCUMENT
              ]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/financial/justifying-document"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.FINANCIAL]?.[
                permissions.JUSTIFYING_DOCUMENT
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.JUSTIFYING_DOCUMENT}
                text={languagePack.justifying_documents}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/financial/cont-extras"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.FINANCIAL]?.[
                permissions.IMPORT_FROM_STATEMENT
              ]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/financial/cont-extras"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.FINANCIAL]?.[
                permissions.IMPORT_FROM_STATEMENT
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.STATEMENT}
                text={languagePack.upload_bank_account_statements}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/financial/receipt"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.FINANCIAL]?.[permissions.RECEIPT]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/financial/receipt"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.FINANCIAL]?.[
                permissions.RECEIPT
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.RECEIPT}
                text={languagePack.receipts}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/financial/bonuses-monitoring"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.FINANCIAL]?.[
                permissions.BONUS_MONITORING
              ]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/financial/bonuses-monitoring"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.FINANCIAL]?.[
                permissions.BONUS_MONITORING
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.BONUS_MONITORING}
                text={languagePack.bonuses_monitoring}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/financial/cashflow"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.FINANCIAL]?.[permissions.BUDGET]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/financial/cashflow"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.FINANCIAL]?.[
                permissions.BUDGET
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.BUDGET}
                text={languagePack.budget}
              />
            </NavLink>
          </li>
        </ul>
      )}

      {isTFB() && currentSectionPage === rootPages.MEDICAL && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <AsideMenuListTitle text={languagePack.medical} />

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/medical/injuries"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.MEDICAL]?.[permissions.INJURY]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/medical/injuries"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.MEDICAL]?.[
                permissions.INJURY
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.INJURY}
                text={languagePack.injuries}
              />
            </NavLink>
          </li>
        </ul>
      )}

      {isTFB() && currentSectionPage === rootPages.DOCUMENTS && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <AsideMenuListTitle text={languagePack.contracts} />

          <li
            className={`menu-item menu-item-submenu ${window.location
              .pathname === "/documents" &&
              " menu-item-active"}  ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.DOCUMENT]?.[permissions.DOCUMENT]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/documents"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.DOCUMENT]?.[
                permissions.DOCUMENT
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.DOCUMENT}
                text={languagePack.documents}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/documents/player-contracts"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.DOCUMENT]?.[
                permissions.CONTRACT_PLAYER
              ]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/documents/player-contracts"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.DOCUMENT]?.[
                permissions.CONTRACT_PLAYER
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.CONTRACT_PLAYER}
                text={languagePack.player_contracts}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/documents/staff-contracts"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.DOCUMENT]?.[
                permissions.CONTRACT_STAFF
              ]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/documents/staff-contracts"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.DOCUMENT]?.[
                permissions.CONTRACT_STAFF
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.CONTRACT_STAFF}
                text={languagePack.staff_contracts}
              />
            </NavLink>
          </li>
        </ul>
      )}

      {isTFB() && currentSectionPage === rootPages.FANS && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/* <AsideMenuListTitle text={languagePack.fans_relation} />

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/fans/list"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={user_permissions?.[permissions.FAN]?.[permissions.FANS_LIST] ? {} : disabledStyle}
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/fans/list"
              onClick={handleCloseAsideMenu}
              {...!user_permissions?.[permissions.FAN]?.[permissions.FANS_LIST] && { onClick: disableAccess }}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.FANS_LIST}
                text={languagePack.fans_list}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/fans/import"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={user_permissions?.[permissions.FAN]?.[permissions.FANS_IMPORT] ? {} : disabledStyle}
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/fans/import"
              onClick={handleCloseAsideMenu}
              {...!user_permissions?.[permissions.FAN]?.[permissions.FANS_IMPORT] && { onClick: disableAccess }}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.FANS_IMPORT}
                text={languagePack.import_data}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/fans/campaigns"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={user_permissions?.[permissions.FAN]?.[permissions.FANS_CAMPAIGN] ? {} : disabledStyle}
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/fans/campaigns"
              onClick={handleCloseAsideMenu}
              {...!user_permissions?.[permissions.FAN]?.[permissions.FANS_CAMPAIGN] && { onClick: disableAccess }}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.FANS_CAMPAIGN}
                text={languagePack.campaigns}
              />
            </NavLink>
          </li> */}

          <AsideMenuListTitle
            text={languagePack.categories}
            // style={{ height: 55, marginTop: 27 }}
          />

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/fans/social_dashboard"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.FAN]?.[
                permissions.FANS_SOCIAL_MEDIA
              ]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/fans/social_dashboard"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.FAN]?.[
                permissions.FANS_SOCIAL_MEDIA
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.FANS_SOCIAL_MEDIA}
                text={languagePack.social_media_activity}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/fans/tax-redirectors"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.FAN]?.[permissions.TAX_REDIRECTION]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/fans/tax-redirectors"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.FAN]?.[
                permissions.TAX_REDIRECTION
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.TAX_REDIRECTION}
                text={languagePack.tax_redirectors}
              />
            </NavLink>
          </li>
        </ul>
      )}

      {currentSectionPage === rootPages.VIDEOGALLERY && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <AsideMenuListTitle text={languagePack.gallery_videos_title} />

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/video-gallery"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.VIDEOGALLERY]?.[permissions.VIDEO]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/video-gallery"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.VIDEOGALLERY]?.[
                permissions.VIDEO
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.VIDEO}
                text={languagePack.videos}
              />
            </NavLink>
          </li>
        </ul>
      )}

      {isTFB() && currentSectionPage === rootPages.SITE && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <AsideMenuListTitle text={languagePack.site_management} />

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/site/news"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.SITE]?.[permissions.NEWS]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/site/news"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.SITE]?.[
                permissions.NEWS
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.NEWS}
                text={languagePack.news}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/site/about_us"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.SITE]?.[permissions.ABOUT_US]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/site/about_us"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.SITE]?.[
                permissions.ABOUT_US
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.ABOUT_US}
                text={languagePack.about_us_title}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/site/sponsors"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.SITE]?.[permissions.SPONSOR]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/site/sponsors"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.SITE]?.[
                permissions.SPONSOR
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.SPONSOR}
                text={languagePack.sponsors}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/site/photos"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.SITE]?.[
                permissions.PHOTO_VIDEO_GALLERY
              ]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/site/photos"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.SITE]?.[
                permissions.PHOTO_VIDEO_GALLERY
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.PHOTO_VIDEO_GALLERY}
                text={languagePack.gallery_notification_title}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${window.location
              .pathname === "/site/contact" &&
              " menu-item-active"}  ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.SITE]?.[permissions.CONTACT]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/site/contact"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.SITE]?.[
                permissions.CONTACT
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.CONTACT}
                text={languagePack.contact_data}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${window.location
              .pathname === "/site/social-media" &&
              " menu-item-active"}  ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.SITE]?.[
                permissions.SOCIAL_MEDIA_CONNECT
              ]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/site/social-media"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.SITE]?.[
                permissions.SOCIAL_MEDIA_CONNECT
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.SOCIAL_MEDIA_CONNECT}
                text={languagePack.social_media_connect}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${window.location
              .pathname === "/site/website-settings" &&
              " menu-item-active"}  ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.SITE]?.[
                permissions.WEBSITE_SETTINGS
              ]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/site/website-settings"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.SITE]?.[
                permissions.WEBSITE_SETTINGS
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.WEBSITE_SETTINGS}
                text={languagePack.website_settings}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${window.location
              .pathname === "/site/teams-settings" &&
              " menu-item-active"}  ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.SITE]?.[permissions.TEAMS_SETTINGS]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/site/teams-settings"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.SITE]?.[
                permissions.TEAMS_SETTINGS
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.TEAMS_SETTINGS}
                text={languagePack.teams_settings}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${window.location
              .pathname === "/site/staff-settings" &&
              " menu-item-active"}  ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.SITE]?.[permissions.STAFF_SETTINGS]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/site/staff-settings"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.SITE]?.[
                permissions.STAFF_SETTINGS
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.STAFF_SETTINGS}
                text={languagePack.staff_settings}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${window.location
              .pathname === "/site/privacy-policy-site" &&
              " menu-item-active"}  ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.SITE]?.[permissions.PRIVACY_POLICY]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/site/privacy-policy-site"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.SITE]?.[
                permissions.PRIVACY_POLICY
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.PRIVACY_POLICY}
                text={languagePack.privacy_policy}
              />
            </NavLink>
          </li>
        </ul>
      )}

      {isTFB() && currentSectionPage === rootPages.APPLICATION && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <AsideMenuListTitle text={languagePack.admin_parent_app} />

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/application/users"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.PARENTS_APP]?.[
                permissions.PARENT_USER
              ]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/application/users"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.PARENTS_APP]?.[
                permissions.PARENT_USER
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.PARENT_USER}
                text={languagePack.users_list}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/application/users-notifications"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.PARENTS_APP]?.[
                permissions.NOTIFICATE_USER
              ]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/application/users-notifications"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.PARENTS_APP]?.[
                permissions.NOTIFICATE_USER
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.NOTIFICATE_USER}
                text={languagePack.users_notifications}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/application/news"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.PARENTS_APP]?.[
                permissions.MOBILE_APP_NEWS
              ]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/application/news"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.PARENTS_APP]?.[
                permissions.MOBILE_APP_NEWS
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.MOBILE_APP_NEWS}
                text={languagePack.news}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/application/doc-to-sign"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.PARENTS_APP]?.[
                permissions.DOC_TO_SIGN
              ]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/application/doc-to-sign"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.PARENTS_APP]?.[
                permissions.DOC_TO_SIGN
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.DOC_TO_SIGN}
                text={languagePack.documents_to_sign}
              />
            </NavLink>
          </li>
        </ul>
      )}

      {isTFB() && currentSectionPage === rootPages.SHOP && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <AsideMenuListTitle text={languagePack.shop} />

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/shop/products"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.SHOP]?.[permissions.PRODUCT]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/shop/products"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.SHOP]?.[
                permissions.PRODUCT
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.PRODUCT}
                text={languagePack.products}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/shop/orders"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.SHOP]?.[permissions.ORDER]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/shop/orders"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.SHOP]?.[
                permissions.ORDER
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.ORDER}
                text={languagePack.orders}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/shop/categories"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.SHOP]?.[permissions.CATEGORY]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/shop/categories"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.SHOP]?.[
                permissions.CATEGORY
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.CATEGORY}
                text={languagePack.categories}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/shop/sizes"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.SHOP]?.[permissions.SIZE]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/shop/sizes"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.SHOP]?.[
                permissions.SIZE
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.SIZE}
                text={languagePack.sizes}
              />
            </NavLink>
          </li>
        </ul>
      )}

      {currentSectionPage === rootPages.USERS_MANAGEMENT && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <AsideMenuListTitle text={languagePack.users_management_menu} />

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/users-management"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              user_permissions?.[permissions.CLUB_ADMIN]?.[
                permissions.USER_LIST
              ]
                ? {}
                : disabledStyle
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/users-management"
              onClick={handleCloseAsideMenu}
              {...(!user_permissions?.[permissions.CLUB_ADMIN]?.[
                permissions.USER_LIST
              ] && { onClick: disableAccess })}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.PLATFORM_ADMINISTRATION}
                text={languagePack.users_management_menu}
              />
            </NavLink>
          </li>
        </ul>
      )}

      {isTFB() && currentSectionPage === rootPages.SCOUTING && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <AsideMenuListTitle text={languagePack.scouting} />

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/scouting/shortlists"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            // style={user_permissions?.[permissions.CLUB_ADMIN]?.[permissions.USER_LIST] ? {} : disabledStyle}
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/scouting/shortlists"
              onClick={handleCloseAsideMenu}
              // {...!user_permissions?.[permissions.CLUB_ADMIN]?.[permissions.USER_LIST] && { onClick: disableAccess }}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.PLATFORM_ADMINISTRATION}
                text={languagePack.shortlist_url}
              />
            </NavLink>
          </li>
        </ul>
      )}

      {isTFB() && (
        <>
          <VideoList
            menuModule={utilFunctions.getCurrentRootPage(
              window.location.pathname
            )}
            onClick={handleClick}
          />
          <TFBDialog
            open={isVideoTutorialOpen}
            fullWidth={true}
            closePopup={() => setIsVideoTutorialOpen(false)}
            maxWidth="lg"
            title={videoTutorialName}
          >
            <video
              style={{ width: "100%" }}
              controls
              src={videoTutorialSrc}
            ></video>
          </TFBDialog>
        </>
      )}
    </>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  user: auth.user || {},
  token: auth?.user?.token,
}))(AsideMenuList);
